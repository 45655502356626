import React, { HTMLAttributes, useRef } from "react";
import { MenuGroupPacket } from "../../../../models/application/menu/MenuGroupPacket";
import { CSSTransition } from "react-transition-group";
import { MenuEntryPacket } from "../../../../models/application/menu/MenuEntryPacket";
import "./MobileMenuSharedAnimation.scss";
import sharedStyles from "./MobileMenuShared.module.scss";
import { Icon } from "../../../../parts/icons/Icon";
import { IconType } from "../../../../models/application/icon/IconType";
import { MobileMenuItem } from "./MobileMenuItem";
import { useLocation } from "react-router-dom";

interface MobileSubMenuViewProps extends HTMLAttributes<any> {
  selectedMenu: string;
  setSelectedMenu: Function;

  parent: MenuEntryPacket;
  groups: MenuGroupPacket[];
}

export const MobileSubMenu: React.FunctionComponent<MobileSubMenuViewProps> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <CSSTransition
        nodeRef={menuRef}
        classNames={"mobile-menu-internal-animation"}
        in={`ENTRY${props.parent.id}` === props.selectedMenu}
        unmountOnExit
        timeout={300}
      >
        <div ref={menuRef}>
          <div className={sharedStyles.menuItem} onClick={() => props.setSelectedMenu("MAIN")}>
            <Icon
              className={sharedStyles.menuIcon}
              iconType={props.parent.iconType}
              iconName={props.parent.icon}
            />
            <label>{props.parent.label}</label>

            <div className={sharedStyles.end}>
              <Icon
                className={sharedStyles.navigationIcon}
                iconType={IconType.MaterialUI}
                iconName={"less-than"}
              />
            </div>
          </div>

          {props.groups.map((group) => (
            <SubGroupItem key={group.id} group={group} setSelectedMenu={props.setSelectedMenu} />
          ))}
        </div>
      </CSSTransition>

      {props.groups.map((group) => (
        <MobileMenuItem
          key={group.id}
          selectedMenu={props.selectedMenu}
          setSelectedMenu={props.setSelectedMenu}
          parent={group}
          grandParent={props.parent}
          entries={group.entries}
        />
      ))}
    </>
  );
};

type SubGroupItemParams = {
  group: MenuGroupPacket;

  setSelectedMenu: Function;
};
const SubGroupItem = (params: SubGroupItemParams) => {
  const location = useLocation();

  const isActive =
    params.group.entries.find((x) => x.link?.link === location.pathname) !== undefined;

  return (
    <div
      className={`${sharedStyles.subMenuItem} ${isActive ? sharedStyles.active : ""}`}
      onClick={() => params.setSelectedMenu(`GROUP${params.group.id}`)}
    >
      <label>{params.group.name}</label>

      <div className={sharedStyles.end}>
        <Icon
          className={sharedStyles.navigationIcon}
          iconType={IconType.MaterialUI}
          iconName={"greater-than"}
        />
      </div>
    </div>
  );
};