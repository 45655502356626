import { AxiosError } from "axios";

export enum HttpConstants {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Unknown = 999,
}

export class HttpHelper {
  public static handleError(e: any, setState: Function) {
    if (e instanceof AxiosError) {
      switch (e.response?.status) {
        case 400:
          setState(HttpConstants.BadRequest);
          break;
        case 401:
          setState(HttpConstants.Unauthorized);
          break;
        case 404:
          setState(HttpConstants.NotFound);
          break;
        default:
          setState(HttpConstants.Unknown);
          break;
      }
    } else {
      setState(HttpConstants.Unknown);
    }
  }
}