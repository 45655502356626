import { MenuGroupAnchor } from "../../../models/application/menu/MenuGroupAnchor";
import { MenuGroupPacket } from "../../../models/application/menu/MenuGroupPacket";
import { ParentGroupedMenuGroupPacket } from "../../../models/application/menu/ParentGroupedMenuGroupPacket";

export class MenuService {
  public static sortMenuGroups(menuGroups: MenuGroupPacket[]): MenuGroupPacket[] {
    menuGroups = menuGroups.sort((a, b) => {
      if (a.anchor === b.anchor && a.anchor === MenuGroupAnchor.Top) {
        return a.order - b.order;
      }

      if (a.anchor === b.anchor && a.anchor === MenuGroupAnchor.Bottom) {
        return b.order - a.order;
      }

      if (a.anchor === MenuGroupAnchor.Top) {
        return 1;
      } else {
        return -1;
      }
    });

    menuGroups.forEach((group) => {
      group.entries = group.entries.sort((a, b) => a.order - b.order);
    });

    return menuGroups;
  }

  public static splitMenuGroups(menuGroups: MenuGroupPacket[]): {
    topMenuGroups: MenuGroupPacket[];
    bottomMenuGroups: MenuGroupPacket[];
  } {
    const topMenuGroups = menuGroups.filter((x) => x.anchor === MenuGroupAnchor.Top);
    const bottomMenuGroups = menuGroups.filter((x) => x.anchor === MenuGroupAnchor.Bottom);

    return { topMenuGroups, bottomMenuGroups };
  }

  public static flattenMenuGroups(menuGroups: MenuGroupPacket[]): MenuGroupPacket[] {
    return menuGroups.reduce((acc: MenuGroupPacket[], menuGroup: MenuGroupPacket) => {
      acc.push(menuGroup);
      if (menuGroup.entries && menuGroup.entries.length > 0) {
        const groups = menuGroup.entries.map((x) => x.groups ?? []).flat();
        acc.push(...MenuService.flattenMenuGroups(groups));
      }
      return acc;
    }, []);
  }

  public static parentGroupMenuGroups(
    menuGroups: MenuGroupPacket[],
  ): ParentGroupedMenuGroupPacket[] {
    const parentGroupedMenuGroups: ParentGroupedMenuGroupPacket[] = [];
    const menuEntries = menuGroups.map((x) => x.entries).flat();

    menuGroups.forEach((menuGroup) => {
      const parentGroup = parentGroupedMenuGroups.find((x) => x.parent.id === menuGroup.parentId);

      if (parentGroup) {
        parentGroup.menuGroups.push(menuGroup);
      } else {
        if (!menuGroup.parentId) {
          return;
        }

        const menuEntry = menuEntries.find((x) => x.id === menuGroup.parentId);

        if (!menuEntry) {
          return;
        }

        const parentGroup: ParentGroupedMenuGroupPacket = {
          parent: menuEntry,
          menuGroups: [menuGroup],
        };

        parentGroupedMenuGroups.push(parentGroup);
      }
    });

    return parentGroupedMenuGroups;
  }
}