import React from "react";
import ReactDomClient from "react-dom/client";
import "./index.css";
import { App } from "./App";

const container: HTMLElement = document.getElementById("root") as HTMLElement;
const root: ReactDomClient.Root = ReactDomClient.createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);