export function compareObjects<T extends Record<string, any>>(obj1: T, obj2: T): boolean {
  // Function to recursively compare two values
  function deepCompare(value1: any, value2: any): boolean {
    if (value1 === value2) {
      return true;
    }

    // Special Date Compare
    function isValidDate(stringDate: string) {
      return !isNaN(Date.parse(stringDate));
    }

    if (isValidDate(value1) && isValidDate(value2)) {
      const date1 = new Date(value1);
      const date2 = new Date(value2);

      const offset1 = Math.abs(date1.getTimezoneOffset());
      date1.setTime(date1.getTime() + offset1 * 60 * 1000);

      const offset2 = Math.abs(date2.getTimezoneOffset());
      date2.setTime(date2.getTime() + offset2 * 60 * 1000);

      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDay() === date2.getDay()
      );
    }

    if (
      typeof value1 !== "object" ||
      typeof value2 !== "object" ||
      value1 === null ||
      value2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!deepCompare(value1[key], value2[key])) {
        return false;
      }
    }

    return true;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare the values
  for (const key of keys1) {
    if (!deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }

  // If all properties are equal, return true
  return true;
}