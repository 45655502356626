import * as React from "react";
const SvgServer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 116 120"
    {...props}
  >
    <defs>
      <clipPath id="server_svg__a">
        <path d="M1094 1435h116v120h-116z" />
      </clipPath>
    </defs>
    <g
      fill="#FFF"
      fillRule="evenodd"
      clipPath="url(#server_svg__a)"
      transform="translate(-1094 -1435)"
    >
      <path d="M1147 1526h10v13h-10ZM1147 1545h10v10h-10ZM1163 1545h47v10h-47ZM1094 1545h47v10h-47ZM1198.18 1500h-92.36c-3.22 0-5.82 2.6-5.82 5.82v14.36c0 3.22 2.6 5.82 5.82 5.82h92.36c3.22 0 5.82-2.6 5.82-5.82v-14.36c0-3.22-2.6-5.82-5.82-5.82Zm-81.93 16.25a3.25 3.25 0 0 1-3.25-3.25c0-1.79 1.46-3.25 3.25-3.25s3.25 1.46 3.25 3.25c0 1.8-1.46 3.25-3.25 3.25Zm16.25 0c-1.8 0-3.25-1.45-3.25-3.25a3.25 3.25 0 1 1 6.5 0c0 1.8-1.45 3.25-3.25 3.25Zm16.25 0a3.25 3.25 0 0 1-3.25-3.25c0-1.79 1.46-3.25 3.25-3.25 1.8 0 3.25 1.46 3.25 3.25 0 1.8-1.45 3.25-3.25 3.25ZM1198.18 1467h-92.36c-3.22 0-5.82 2.6-5.82 5.82v14.36c0 3.22 2.6 5.82 5.82 5.82h92.36c3.22 0 5.82-2.6 5.82-5.82v-14.36c0-3.22-2.6-5.82-5.82-5.82Zm-81.93 16.25a3.25 3.25 0 0 1-3.25-3.25c0-1.79 1.46-3.25 3.25-3.25s3.25 1.46 3.25 3.25c0 1.8-1.46 3.25-3.25 3.25Zm16.25 0c-1.8 0-3.25-1.45-3.25-3.25a3.25 3.25 0 1 1 6.5 0c0 1.8-1.45 3.25-3.25 3.25Zm16.25 0a3.25 3.25 0 0 1-3.25-3.25c0-1.79 1.46-3.25 3.25-3.25 1.8 0 3.25 1.46 3.25 3.25 0 1.8-1.45 3.25-3.25 3.25ZM1198.18 1435h-92.36c-3.22 0-5.82 2.6-5.82 5.82v14.36c0 3.22 2.6 5.82 5.82 5.82h92.36c3.22 0 5.82-2.6 5.82-5.82v-14.36c0-3.22-2.6-5.82-5.82-5.82Zm-81.93 16.25a3.25 3.25 0 0 1-3.25-3.25c0-1.79 1.46-3.25 3.25-3.25s3.25 1.46 3.25 3.25c0 1.8-1.46 3.25-3.25 3.25Zm16.25 0c-1.8 0-3.25-1.45-3.25-3.25a3.25 3.25 0 1 1 6.5 0c0 1.8-1.45 3.25-3.25 3.25Zm16.25 0a3.25 3.25 0 0 1-3.25-3.25c0-1.79 1.46-3.25 3.25-3.25 1.8 0 3.25 1.46 3.25 3.25 0 1.8-1.45 3.25-3.25 3.25Z" />
    </g>
  </svg>
);
export default SvgServer;
