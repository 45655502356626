import { ReportPacket } from "../../../models/report data/report/ReportPacket";
import { TriggerType } from "../../../models/report data/trigger/TriggerType";
import { TriggerFilterPacket } from "../../../models/report data/trigger/TriggerFilterPacket";
import { TriggerActionPacket } from "../../../models/report data/trigger/TriggerActionPacket";
import { SetURLSearchParams } from "react-router-dom";
import { FilterService } from "../filter/FilterService";
import { models } from "powerbi-client";
import { ReportContextType } from "../../../contexts/report data/report/ReportContext";
import { TriggerActionType } from "../../../models/report data/trigger/TriggerActionType";
import { FilterContextType } from "../../../contexts/report data/filter/FilterContext";

export class TriggerService {
  private readonly reportContext: ReportContextType;
  private readonly filterContext: FilterContextType;

  private readonly searchParams: URLSearchParams;
  private readonly setSearchParams: SetURLSearchParams;

  constructor(
    reportContext: ReportContextType,
    filterContext: FilterContextType,
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams,
  ) {
    this.reportContext = reportContext;
    this.filterContext = filterContext;

    this.searchParams = searchParams;
    this.setSearchParams = setSearchParams;
  }

  public processOnLoadTriggers(report: ReportPacket) {
    const onLoadTriggers = report.triggers?.filter((x) => x.triggerType === TriggerType.onLoad);

    onLoadTriggers?.forEach((trigger) => {
      // Special rule: Dont process page change for onLoad triggers, since they are solved in the report embed params
      const actions = trigger.actions.filter((x) => x.actionType !== TriggerActionType.changePage);

      this.processFilters(trigger.filters);
      this.processActions(actions);
    });
  }

  public processFilterChangeTriggers(report: ReportPacket, values: string[]) {
    const onFilterChangeTriggers = report.triggers?.filter(
      (x) => x.triggerType === TriggerType.onFilterChange,
    );

    onFilterChangeTriggers?.forEach((trigger) => {
      const fulfilled = values.find((x) => x === trigger.triggerValue);

      if (fulfilled !== undefined) {
        this.processFilters(trigger.filters);
        this.processActions(trigger.actions);
      }
    });
  }

  public processOnPageChangeTriggers(report: ReportPacket, page: models.IPage) {
    const onPageChangeTriggers = report.triggers?.filter(
      (x) => x.triggerType === TriggerType.onPageChange,
    );

    onPageChangeTriggers?.forEach((trigger) => {
      if (trigger.triggerValue === page.name || trigger.triggerValue === page.displayName) {
        this.processFilters(trigger.filters);
        this.processActions(trigger.actions);
      }
    });
  }

  public processYearChangeTriggers(report: ReportPacket, values: string[]) {
    const onYearChangeTriggers = report.triggers?.filter(
      (x) => x.triggerType === TriggerType.onYearChange,
    );

    onYearChangeTriggers?.forEach((trigger) => {
      const fulfilled = values.find((x) => x === trigger.triggerValue);

      if (fulfilled !== undefined) {
        this.processFilters(trigger.filters);
        this.processActions(trigger.actions);
      }
    });
  }

  private processFilters(triggerFilters: TriggerFilterPacket[]) {
    triggerFilters.forEach((triggerFilter) => {
      const filter = this.filterContext.filters.find(
        (x) => x.originCode === triggerFilter.filterCode,
      );

      if (filter === undefined) {
        return;
      }

      if (filter.filterLink === undefined) {
        return;
      }

      const category = FilterService.getCategory(this.filterContext.filters, filter);

      if (triggerFilter.reduce) {
        this.filterContext.setFilterConfig({
          originId: category.originId,
          locked: false,
          reduce: triggerFilter.filterCode,
        });

        return;
      }

      if (triggerFilter.enforce) {
        this.filterContext.setFilterConfig({
          originId: category.originId,
          locked: true,
          reduce: undefined,
        });
      }

      this.searchParams.set(`filter${category.originId}`, filter.filterLink.link);
      this.setSearchParams(this.searchParams);
    });
  }

  private processActions(triggerActions: TriggerActionPacket[]) {
    triggerActions.forEach((triggerAction) => {
      this.processPageChangeActions(triggerActions);
    });
  }

  private processPageChangeActions(triggerActions: TriggerActionPacket[]) {
    const pageChangeActions = triggerActions.filter(
      (x) => x.actionType === TriggerActionType.changePage,
    );

    pageChangeActions.forEach((triggerAction) => {
      this.reportContext.reportService.setPage(triggerAction.actionValue);
    });
  }
}