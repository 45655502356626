import { IFilterProvider } from "./IFilterProvider";
import { FilterItemPacket } from "../../../models/report data/filter/FilterItemPacket";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";

export class FilterProvider extends BaseApiDataProvider implements IFilterProvider {
  private static cache: Map<string, FilterItemPacket[]> = new Map();

  private static GET_FILTER_KEY = (key: string) => `filters${key}`;

  public async getReportFilters(reportId: string, year: number): Promise<FilterItemPacket[]> {
    if (!FilterProvider.cache.has(FilterProvider.GET_FILTER_KEY(reportId + year))) {
      const filters = await this.get(`/filter?link=${reportId}&year=${year}`);
      FilterProvider.cache.set(FilterProvider.GET_FILTER_KEY(reportId + year), filters);
    }

    return FilterProvider.cache.get(FilterProvider.GET_FILTER_KEY(reportId + year)) ?? [];
  }
}

export class FilterProviderFactory {
  private static filterProvider: IFilterProvider | null = null;

  public static getFilterProvider(): IFilterProvider {
    if (this.filterProvider === null) {
      this.filterProvider = new FilterProvider(new BaseApiRequestHandler());
    }

    return this.filterProvider;
  }
}