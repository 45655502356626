import React from "react";
import { Link } from "react-router-dom";

import { useFavourites } from "../../../hooks/application/favourites/useFavourites";
import { Icon } from "../../../parts/icons/Icon";
import styles from "./FavouritesView.module.scss";

export const FavouritesView: React.FunctionComponent = () => {
  const { favourites } = useFavourites();

  if (favourites.length === 0) {
    return (
      <div className={styles.center}>
        <h1>You have not selected any favourites yet.</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {favourites.map((item, index) => (
        <div key={index} className={styles.item}>
          <div className={styles.headLine}>{item.name}</div>

          <div className={styles.link}>
            <Icon iconName={item.icon} iconType={item.iconType} />
            <Link to={`${item.link}${item.filter}`}>Open Report "{item.name}"</Link>
          </div>
        </div>
      ))}
    </div>
  );
};