import React, { PropsWithChildren, useEffect } from "react";
import { AppConfigurationModel } from "./models/configuration/AppConfigurationModel";
import { AppConfigManager } from "./models/configuration/AppConfigManager";
import log from "loglevel";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { AuthorizationBoundary } from "./components/identity/authorization/AuthorizationBoundary";
import { ReportContextProvider } from "./contexts/report data/report/ReportContextProvider";
import { ThemeContextProvider } from "./contexts/application/theme/ThemeContextProvider";
import { MenuContextProvider } from "./contexts/application/menu/MenuContextProvider";
import { toast, ToastContainer } from "react-toastify";
import { TriggerContextProvider } from "./contexts/report data/trigger/TriggerContextProvider";
import { LayoutFrame } from "./components/layout/frame/LayoutFrame";
import { SettingsContextProvider } from "./contexts/application/settings/SettingsContextProvider";
import { FilterContextProvider } from "./contexts/report data/filter/FilterContextProvider";

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    function setupApp(): void {
      const appConfig: AppConfigurationModel = AppConfigManager.getAppConfiguration();
      log.setDefaultLevel(appConfig.defaultLogLevel);
    }

    setupApp();
  }, []);

  return (
    <AuthorizationBoundary>
      <BrowserRouter>
        <Providers>
          <LayoutFrame />
        </Providers>
      </BrowserRouter>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        closeButton={false}
        className={"toastStyle"}
      />
    </AuthorizationBoundary>
  );
};

const Providers = (props: PropsWithChildren) => {
  return (
    <SettingsContextProvider>
      <MenuContextProvider>
        <ReportContextProvider>
          <FilterContextProvider>
            <TriggerContextProvider>
              <ThemeContextProvider>{props.children}</ThemeContextProvider>
            </TriggerContextProvider>
          </FilterContextProvider>
        </ReportContextProvider>
      </MenuContextProvider>
    </SettingsContextProvider>
  );
};