import { useEffect, useState } from "react";
import { IAuthorizationProvider } from "../../../providers/identity/authorization/IAuthorizationProvider";
import { AuthorizationProviderFactory } from "../../../providers/identity/authorization/AuthorizationProvider";

export function useApplicationAuthorization(username: string) {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isLoadingAuthorized, setIsLoadingAuthorized] = useState<boolean>(true);

  useEffect(() => {
    const authorizationProvider: IAuthorizationProvider =
      AuthorizationProviderFactory.getAuthorizationProvider();

    async function isAuthorized() {
      setIsLoadingAuthorized(true);

      try {
        const isAuthorized = await authorizationProvider.isApplicationAuthorized();

        setIsLoadingAuthorized(false);
        setIsAuthorized(isAuthorized);
      } catch {
        setIsLoadingAuthorized(false);
        setIsAuthorized(false);
      }
    }

    isAuthorized();
  }, [username]);

  return { isAuthorized, isLoadingAuthorized };
}