import { MenuEntryPacket } from "../../../../models/application/menu/MenuEntryPacket";
import React, { HTMLAttributes, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { MenuGroupPacket } from "../../../../models/application/menu/MenuGroupPacket";
import sharedStyles from "./MobileMenuShared.module.scss";
import { Icon } from "../../../../parts/icons/Icon";
import { IconType } from "../../../../models/application/icon/IconType";
import "./MobileMenuSharedAnimation.scss";
import { Link, useLocation } from "react-router-dom";

interface MobileMenuItemProps extends HTMLAttributes<any> {
  selectedMenu: string;
  setSelectedMenu: Function;

  parent: MenuGroupPacket;
  grandParent: MenuEntryPacket;
  entries: MenuEntryPacket[];
}

export const MobileMenuItem: React.FunctionComponent<MobileMenuItemProps> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      nodeRef={menuRef}
      classNames={"mobile-menu-internal-animation"}
      in={`GROUP${props.parent.id}` === props.selectedMenu}
      unmountOnExit
      timeout={300}
    >
      <div ref={menuRef}>
        <div
          className={sharedStyles.menuItem}
          onClick={() => props.setSelectedMenu(`ENTRY${props.grandParent.id}`)}
        >
          <Icon
            className={sharedStyles.menuIcon}
            iconType={IconType.MaterialUI}
            iconName={"format-list-group"}
          />
          <label>{props.parent.name}</label>

          <div className={sharedStyles.end}>
            <Icon
              className={sharedStyles.navigationIcon}
              iconType={IconType.MaterialUI}
              iconName={"less-than"}
            />
          </div>
        </div>

        {props.entries.map((entry) => (
          <LinkEntry key={entry.id} entry={entry} />
        ))}
      </div>
    </CSSTransition>
  );
};

type LinkEntryParams = {
  entry: MenuEntryPacket;
};
const LinkEntry = (params: LinkEntryParams) => {
  const location = useLocation();

  if (!params.entry.link) {
    return <></>;
  }

  const isActive = location.pathname === params.entry.link.link;

  return (
    <Link
      className={`${sharedStyles.subMenuItem} ${isActive ? sharedStyles.active : ""}`}
      to={{ pathname: params.entry.link.link, search: location.search }}
    >
      <label>{params.entry.label}</label>
    </Link>
  );
};