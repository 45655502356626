import * as React from "react";
const SvgRocket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 108 108"
    {...props}
  >
    <defs>
      <clipPath id="rocket_svg__a">
        <path d="M1092 1676h108v108h-108z" />
      </clipPath>
    </defs>
    <g
      fill="#FFF"
      fillRule="evenodd"
      clipPath="url(#rocket_svg__a)"
      transform="translate(-1092 -1676)"
    >
      <path d="M1199.42 1676.72c-1.83-1.91-13.61.27-23.42 3 3.53 2.17 7.2 5.17 10.73 8.84 3.66 3.82 6.54 7.63 8.63 11.44 2.62-10.48 6.02-21.37 4.06-23.28ZM1125 1715.04l-4.6-1.72a5.35 5.35 0 0 0-5.41 1.06l-21.51 21.12c-3.52 3.46-.27 9.44 4.6 8.38l17.99-3.99c1.49-6.64 3.92-15.55 8.93-24.85ZM1160.42 1750a92.89 92.89 0 0 1-25.05 8.66l-4.24 19.2c-1.09 4.87 4.93 8.25 8.62 4.6l21.77-21.5c1.37-1.36 1.92-3.52 1.09-5.41l-2.19-5.55ZM1168.51 1682c-7.9 3.22-16.86 8.72-25.56 17.43-15.93 15.96-20.61 35.26-21.95 46.26l8.3 8.31c10.97-1.34 30.38-5.9 46.3-21.85 8.7-8.72 14.19-17.57 17.4-25.48-1.74-4.42-5.22-9.65-10.17-14.75-4.82-4.69-9.9-8.18-14.32-9.92Zm6.82 28.96c-3.07 3.09-8.16 3.09-11.37 0-3.08-3.08-3.08-8.18 0-11.4 3.08-3.08 8.16-3.08 11.37 0 3.08 3.22 3.08 8.32 0 11.4ZM1120.58 1754.41c-2.14-2.13-1.87-5.72-6.27-1.33-4.4 4.4-10.94 14.38-8.94 16.51 2.14 2.13 12.14-4.53 16.54-8.92 4.41-4.53.8-4.26-1.33-6.26Z" />
    </g>
  </svg>
);
export default SvgRocket;
