import React, { useContext, useEffect, useState } from "react";
import styles from "./DateFilter.module.scss";
import { ReportContext } from "../../../../contexts/report data/report/ReportContext";
import { useSearchParams } from "react-router-dom";
import { SelectedYearService } from "../../../../services/report data/year/SelectedYearService";

export const DateFilter: React.FunctionComponent = () => {
  const PARAMETER_NAME = "year";

  const [searchParams, setSearchParams] = useSearchParams();
  const reportContext = useContext(ReportContext);

  const [isActive, setIsActive] = useState<boolean>(true);

  const availableYears = SelectedYearService.getAvailableYears();
  const selectedYear = SelectedYearService.getSelectedYear(searchParams);

  const updateSelectedYear = () => {
    if (!isActive) {
      reportContext.reportService.removeYearFilter();
    } else {
      const year = SelectedYearService.getSelectedYear(searchParams);
      reportContext.reportService.setYearFilter(year);
    }
  };

  const setSelectedYear = (year: string) => {
    if (!isActive) {
      return;
    }

    searchParams.set(PARAMETER_NAME, year);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    updateSelectedYear();
  }, [searchParams]);

  useEffect(() => {
    updateSelectedYear();
  }, [reportContext.reportLoaded]);

  useEffect(() => {
    const active =
      reportContext.reportPacket?.reportConfigurations.find((x) => x.configuration === "dateFilter")
        ?.value !== "hidden" ?? true;

    setIsActive(active);
  }, [reportContext.reportPacket]);

  useEffect(() => {
    updateSelectedYear();
  }, [isActive]);

  return (
    <>
      {isActive && (
        <div className={styles.dateFilter}>
          <select
            value={selectedYear}
            className={styles.datepicker}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {availableYears.map((year) => {
              return (
                <option key={year.toString()} value={year.getFullYear()}>
                  {year.getFullYear()}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </>
  );
};