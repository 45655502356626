import { ColumnDef } from "@tanstack/react-table";
import React from "react";

import { useFeedbacks } from "../../../hooks/master data/feedback/useFeedbacks";
import { HttpConstants } from "../../../models/http communication/HttpConstants";
import { Feedback } from "../../../models/master data/feedback/feedback";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";
import { FunctionNotAuthorized } from "../../identity/authorization/FunctionNotAuthorized";
import { GenericMasterDataTable } from "../generic master data/parts/GenericMasterDataTable";
import styles from "../generic master data/views/GenericMasterDataEditor.module.scss";
import { Link } from "react-router-dom";

export const FeedbackView: React.FunctionComponent = () => {
  const { feedbacks, feedbacksIsLoading, feedbacksState } = useFeedbacks();

  const columnDefinitions: ColumnDef<Feedback>[] = [
    {
      accessorKey: "comment",
      cell: (info) => <span style={{ whiteSpace: "pre-wrap" }}>{info.getValue() as any}</span>,
      header: "Comment",
    },
    {
      accessorKey: "url",
      cell: (info) => (
        <Link to={info.getValue() as any} title={info.getValue() as any}>
          Link
        </Link>
      ),
      header: "Link",
    },
    {
      accessorKey: "email",
      cell: (info) => <a href={`mailto:${info.getValue()}`}>{info.getValue() as any}</a>,
      header: "Email",
    },
    {
      accessorKey: "createdAt",
      cell: (info) =>
        `${new Date(info.getValue() as Date).getDate()}.${
          new Date(info.getValue() as Date).getMonth() + 1
        }.${new Date(info.getValue() as Date).getFullYear()} - ${new Date(
          info.getValue() as Date,
        ).getHours()}:${new Date(info.getValue() as Date).getMinutes()}`,
      header: "Created At",
      sortingFn: "datetime",
      invertSorting: true,
    },
  ];

  return (
    <div className={styles.container}>
      {feedbacksIsLoading ? (
        <FullscreenPropagateLoader />
      ) : feedbacksState === HttpConstants.Unauthorized ? (
        <FunctionNotAuthorized />
      ) : (
        <GenericMasterDataTable
          name={"Feedback"}
          columnDefinitions={columnDefinitions}
          dataSource={structuredClone(feedbacks)}
          selectedRecord={null}
          selectRecord={null}
          createRecord={null}
          deleteRecord={null}
        />
      )}
    </div>
  );
};