import { ThemeItem } from "../../../models/application/theme/ThemeItem";
import { useEffect, useState } from "react";
import { IThemeProvider } from "../../../providers/application data/theme/IThemeProvider";
import { ThemeProviderFactory } from "../../../providers/application data/theme/ThemeProvider";

export function useThemes() {
  const [themes, setThemes] = useState<ThemeItem[]>([]);

  useEffect(() => {
    const themeProvider: IThemeProvider = ThemeProviderFactory.getThemeProvider();

    async function getThemes() {
      try {
        const themes = await themeProvider.getThemes();
        setThemes(themes);
      } catch {
        setThemes([]);
      }
    }

    getThemes();
  }, []);

  return { themes };
}