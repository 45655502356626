import { IESAProjectPOMIProvider } from "./IESAProjectPOMIProvider";
import { ESAProjectPOMIPacket } from "../../../models/master data/esa project/ESAProjectPOMIPacket";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";

export class ESAProjectPOMIProvider extends BaseApiDataProvider implements IESAProjectPOMIProvider {
  public async getPOMI(id: string): Promise<ESAProjectPOMIPacket> {
    return await this.get(`/esaprojectpomi/${id}`);
  }

  public async createPOMI(pomi: ESAProjectPOMIPacket): Promise<void> {
    return await this.post(`/esaprojectpomi/${pomi.id}`, pomi);
  }

  public async updatePOMI(pomi: ESAProjectPOMIPacket): Promise<void> {
    return await this.put(`/esaprojectpomi/${pomi.id}`, pomi);
  }

  public async deletePOMI(id: string): Promise<void> {
    return await this.delete(`/esaprojectpomi/${id}`);
  }
}

export class ESAProjectPOMIProviderFactory {
  private static esaProjectPOMIProvider: IESAProjectPOMIProvider | null = null;

  public static getESAProjectPOMIProvider(): IESAProjectPOMIProvider {
    if (this.esaProjectPOMIProvider === null) {
      this.esaProjectPOMIProvider = new ESAProjectPOMIProvider(new BaseApiRequestHandler());
    }

    return this.esaProjectPOMIProvider;
  }
}