import React, { PropsWithChildren, useState } from "react";
import { ReportContext } from "./ReportContext";
import { Report } from "report";
import { ReportService } from "../../../services/report data/report/ReportService";
import { useReport } from "../../../hooks/report data/report/useReport";

export const ReportContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const [reportLink, setReportLink] = useState<string | undefined>(undefined);

  const { reportPacket, isDownloadingReportPacket } = useReport(reportLink);

  const [report, setReport] = useState<Report | null>(null);
  const [reportLoaded, setReportLoaded] = useState<number>(0);

  const reportService = new ReportService(report);

  return (
    <ReportContext.Provider
      value={{
        reportLink,
        setReportLink,
        reportPacket,
        isDownloadingReportPacket,
        report,
        setReport,
        reportLoaded,
        setReportLoaded,
        reportService,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};