import { useEffect, useState } from "react";

import { FavouritesItem } from "../../../models/application/favourites/Favouritestem";
import { FavouritesProviderFactory } from "../../../providers/application data/favourites/FavouritesProvider";
import { IFavouritesProvider } from "../../../providers/application data/favourites/IFavouritesProvider";

export function useFavourites() {
  const [favourites, setFavourites] = useState<FavouritesItem[]>([]);

  useEffect(() => {
    const favouritesProvider: IFavouritesProvider =
      FavouritesProviderFactory.getFavouritesProvider();

    async function getFavourites() {
      try {
        const results = await favouritesProvider.getFavourites();
        setFavourites(results);
      } catch {
        setFavourites([]);
      }
    }

    getFavourites();
  }, []);

  return { favourites };
}