import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { IMenuProvider } from "./IMenuProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";
import { MenuGroupPacket } from "../../../models/application/menu/MenuGroupPacket";

export class MenuProvider extends BaseApiDataProvider implements IMenuProvider {
  private static GET_MENU_ITEMS_KEY = "menu";

  private static cache: Map<string, MenuGroupPacket[]> = new Map();

  public async getMenu(): Promise<MenuGroupPacket[]> {
    if (!MenuProvider.cache.has(MenuProvider.GET_MENU_ITEMS_KEY)) {
      const menuItems = await this.get("/menu");
      MenuProvider.cache.set(MenuProvider.GET_MENU_ITEMS_KEY, menuItems);
    }

    return MenuProvider.cache.get(MenuProvider.GET_MENU_ITEMS_KEY) ?? [];
  }
}

export class MenuProviderFactory {
  private static menuProvider: IMenuProvider | null = null;

  public static getMenuProvider(): IMenuProvider {
    if (this.menuProvider === null) {
      this.menuProvider = new MenuProvider(new BaseApiRequestHandler());
    }

    return this.menuProvider;
  }
}