import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

import { IconType } from "../../../../models/application/icon/IconType";
import { EditorRecord } from "../../../../models/master data/generics/EditorRecord";
import { Icon } from "../../../../parts/icons/Icon";
import {
  CreateInternalRecordFunction,
  DeleteInternalRecordFunction,
  SelectInternalRecordFunction,
} from "../views/GenericMasterDataEditorView";
import styles from "./GenericMasterDataTable.module.scss";

interface GenericMasterDataTableProps<T extends EditorRecord> {
  name: string;
  columnDefinitions: ColumnDef<T>[];

  dataSource: Array<T>;

  selectedRecord: T | null;

  selectRecord: SelectInternalRecordFunction<T> | null;
  createRecord: CreateInternalRecordFunction | null;
  deleteRecord: DeleteInternalRecordFunction<T> | null;
}

export function GenericMasterDataTable<T extends EditorRecord>(
  props: GenericMasterDataTableProps<T>,
) {
  const columns: ColumnDef<T>[] = React.useMemo<ColumnDef<T>[]>(() => {
    const columns = [...props.columnDefinitions];

    if (props.selectRecord || props.deleteRecord) {
      columns.push({
        cell: (info: any) => (
          <TableActions
            record={info.row.original}
            selectRecord={props.selectRecord}
            deleteRecord={props.deleteRecord}
          />
        ),
        header: "Actions",
      });
    }

    return columns;
  }, [props.selectRecord, props.deleteRecord]);

  const sortedColumn = props.columnDefinitions.find((x) => x.sortingFn);

  const defaultSorting = sortedColumn
    ? [
        {
          // @ts-ignore, this is a bug in the library
          id: sortedColumn["accessorKey"],
          desc: false,
        },
      ]
    : [];
  const [sorting, setSorting] = React.useState<SortingState>(defaultSorting);

  const table = useReactTable({
    data: props.dataSource,
    columns: columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  return (
    <div className={styles.masterDataTable}>
      <Header name={props.name} createRecord={props.createRecord} />

      <RecordTable table={table} selectedRecord={props.selectedRecord} />

      <Footer table={table} />
    </div>
  );
}

type HeaderParams = {
  name: string;
  createRecord: CreateInternalRecordFunction | null;
};

function Header(params: HeaderParams) {
  return (
    <div className={styles.heading}>
      <h1>{params.name}</h1>

      {params.createRecord && (
        <div className={styles.end}>
          <button onClick={() => params.createRecord!()}>
            <Icon iconType={IconType.MaterialUI} iconName={"plus-circle-outline"} />
            <span className={styles.text}>Add</span>
          </button>
        </div>
      )}
    </div>
  );
}

type RecordTableParams<T extends EditorRecord> = {
  table: Table<T>;
  selectedRecord: T | null;
};

function RecordTable<T extends EditorRecord>(params: RecordTableParams<T>): React.JSX.Element {
  const getClassName = (record: T) => {
    if (!params.selectedRecord) {
      return "";
    }

    if (record.id !== params.selectedRecord.id) {
      return styles.unselected;
    }

    return styles.selected;
  };

  return (
    <div className={styles.table}>
      <table className={styles.table}>
        <thead>
          {params.table.getHeaderGroups().map((headerGroup) => (
            <React.Fragment key={headerGroup.id}>
              <tr key={`${headerGroup.id}1`}>
                {headerGroup.headers.map((header) => (
                  <th key={`${header.id}2`}>
                    <div className={styles.header}>
                      {header.column.getCanFilter() ? <TableFilter column={header.column} /> : null}
                    </div>
                  </th>
                ))}
              </tr>
              <tr key={`${headerGroup.id}2`}>
                {headerGroup.headers.map((header) => (
                  <th key={`${header.id}2`}>
                    <div className={styles.header}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  </th>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </thead>
        <tbody>
          {params.table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={getClassName(row.original)}>
              {row.getVisibleCells().map((cell) => (
                <td style={{ overflowWrap: "anywhere" }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

type TableFilterParams = {
  column: Column<any, any>;
};
const TableFilter = (params: TableFilterParams) => {
  const columnFilterValue = params.column.getFilterValue();

  return (
    <input
      autoComplete={"off"}
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => params.column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
    />
  );
};

type TableActionsParams<T extends EditorRecord> = {
  record: T;
  selectRecord: SelectInternalRecordFunction<T> | null;
  deleteRecord: DeleteInternalRecordFunction<T> | null;
};

function TableActions<T extends EditorRecord>(params: TableActionsParams<T>): React.JSX.Element {
  return (
    <div className={styles.tableActions}>
      {params.selectRecord && (
        <button onClick={() => params.selectRecord!(params.record)}>
          <Icon iconType={IconType.MaterialUI} iconName={"pencil-outline"} />
        </button>
      )}

      {params.deleteRecord && (
        <button className={styles.red} onClick={() => params.deleteRecord!(params.record)}>
          <Icon iconType={IconType.MaterialUI} iconName={"trash-can-outline"} />
        </button>
      )}
    </div>
  );
}

type FooterParams<T extends EditorRecord> = {
  table: Table<T>;
};

function Footer<T extends EditorRecord>(params: FooterParams<T>): React.JSX.Element {
  return (
    <div className={styles.footing}>
      <button
        onClick={() => params.table.setPageIndex(0)}
        disabled={!params.table.getCanPreviousPage()}
      >
        <Icon iconType={IconType.MaterialUI} iconName={"step-backward-2"} />
      </button>
      <button
        onClick={() => params.table.previousPage()}
        disabled={!params.table.getCanPreviousPage()}
      >
        <Icon iconType={IconType.MaterialUI} iconName={"step-backward"} />
      </button>

      <div className={styles.paging}>
        <span>Page: </span>
        <input
          style={{
            width:
              Math.min((params.table.getState().pagination.pageIndex + 1).toString().length, 50) +
              "ch",
          }}
          type={"number"}
          value={params.table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            params.table.setPageIndex(page);
          }}
        />
        <span> / {params.table.getPageCount()}</span>
      </div>

      <button onClick={() => params.table.nextPage()} disabled={!params.table.getCanNextPage()}>
        <Icon iconType={IconType.MaterialUI} iconName={"step-forward"} />
      </button>
      <button
        onClick={() => params.table.setPageIndex(params.table.getPageCount() - 1)}
        disabled={!params.table.getCanNextPage()}
      >
        <Icon iconType={IconType.MaterialUI} iconName={"step-forward-2"} />
      </button>
    </div>
  );
}