/** @jsxImportSource @emotion/react */
import { MenuCategory } from "../../../../models/application/menu/MenuCategory";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  TooltipDelayHide,
  TooltipDelayShow,
  TooltipStyle,
} from "../../../../shared/styles/TooltipStyle";
import { Menu, MenuContext } from "../../../../contexts/application/menu/MenuContext";
import { MenuEntryPacket } from "../../../../models/application/menu/MenuEntryPacket";
import { Icon } from "../../../../parts/icons/Icon";
import { Tooltip } from "react-tooltip";
import styles from "./DesktopMenuView.module.scss";
import { MenuService } from "../../../../services/application data/menu/MenuService";
import { Link, useLocation } from "react-router-dom";
import { IconType } from "../../../../models/application/icon/IconType";
import { CSSTransition } from "react-transition-group";
import "./DesktopMenuContainerViewAnimation.scss";
import { MenuGroupPacket } from "../../../../models/application/menu/MenuGroupPacket";
import { MenuBlurArea } from "../../../../models/application/menu/MenuBlurArea";
import { css } from "@emotion/react";

type MenuEntryParams = {
  entry: MenuEntryPacket;

  toggleMenu: Function;
  menuExpanded: boolean;
};
export const DesktopMenuContainerView = (params: MenuEntryParams) => {
  const MENU_CATEGORY = MenuCategory.TopMenu;
  const MENU_ID = `${params.entry.id}`;
  const BLUR_AREA = MenuBlurArea.Content;
  const MENU: Menu = {
    category: MENU_CATEGORY,
    menuId: MENU_ID,
    blur: BLUR_AREA,
  };

  const location = useLocation();

  const menuContext = useContext(MenuContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number | null>(null);

  const [gridStyle, setGridStyle] = useState<React.CSSProperties>({});
  const [childStyle, setChildStyle] = useState<number>(3);

  const sortedMenuGroups = MenuService.sortMenuGroups(params.entry.groups ?? []);

  const divRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  const isActive =
    sortedMenuGroups.find((x) => x.entries.find((y) => y.link?.link === location.pathname)) !==
    undefined;

  const isVisible = sortedMenuGroups.find((x) => x.entries.length > 0) !== undefined;

  function toggleMenu() {
    if (isOpen) {
      menuContext.removeMenu(MENU);
    } else {
      menuContext.setMenu(MENU);
    }
  }

  const handleResize = () => {
    const maxNumberPerRow: number = Math.floor((window.innerWidth - 300) / 300);
    const numberOfChildren: number = sortedMenuGroups.filter((x) => x.entries.length !== 0).length;
    const numberOfItems: number = Math.min(maxNumberPerRow, numberOfChildren);

    setGridStyle({
      gridTemplateColumns: `repeat(${numberOfItems}, 300px)`,
    });
    setChildStyle(numberOfItems);
  };

  useEffect(() => {
    setIsOpen(menuContext.isMenuOpen(MENU_CATEGORY, MENU_ID));
  }, [menuContext.menuUpdated]);

  useEffect(() => {
    if (itemRef.current) {
      setWidth(itemRef.current.offsetWidth + 5);
    }
  }, [itemRef]);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const tooltipId = crypto.randomUUID();

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <div
        className={styles.itemContainer + " " + (params.menuExpanded ? "" : styles.closed)}
        data-tooltip-id={tooltipId}
        data-tooltip-content={params.entry.label}
      >
        <div
          ref={itemRef}
          className={
            styles.item + " " + (isOpen ? styles.open : "") + " " + (isActive ? styles.active : "")
          }
          style={{ width: width !== null ? width : "" }}
          tabIndex={0}
          onClick={() => toggleMenu()}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              toggleMenu();
            }
          }}
        >
          <Icon iconType={params.entry.iconType} iconName={params.entry.icon} />
          <label>{params.entry.label}</label>

          {!params.menuExpanded && (
            <Tooltip
              id={tooltipId}
              place={"right"}
              style={TooltipStyle}
              delayShow={TooltipDelayShow}
              delayHide={TooltipDelayHide}
            />
          )}
        </div>
      </div>

      <CSSTransition
        nodeRef={divRef}
        in={isOpen}
        timeout={300}
        unmountOnExit
        classNames={"desktop-menu-animation"}
      >
        <div
          ref={divRef}
          className={styles.menu + " " + (params.menuExpanded ? "" : styles.closed)}
          style={gridStyle}
          onClick={() => toggleMenu()}
        >
          <Icon
            tabIndex={0}
            className={styles.close}
            iconName={"close"}
            iconType={IconType.MaterialUI}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                toggleMenu();
              }
            }}
          />

          {sortedMenuGroups.map((group) => (
            <MenuGroup key={`${group.id}`} group={group} childStyle={childStyle} />
          ))}
        </div>
      </CSSTransition>
    </>
  );
};

type MenuGroupParams = {
  group: MenuGroupPacket;
  childStyle: number;
};
const MenuGroup = (params: MenuGroupParams) => {
  if (params.group.entries.length === 0) {
    return <></>;
  }

  return (
    <div
      className={styles.group}
      css={css(`
        &:not(:last-child) {
          &:not(:nth-of-type(${params.childStyle}n + 1)) {
            border-right: 1px solid gray;
          }
        }
      `)}
    >
      {params.group.name && <h1>{params.group.name}</h1>}

      {params.group.entries.map((entry) => (
        <MenuItem key={entry.id} entry={entry} />
      ))}
    </div>
  );
};

type MenuItemParams = {
  entry: MenuEntryPacket;
};
const MenuItem = (params: MenuItemParams) => {
  const location = useLocation();

  if (!params.entry.link) {
    return <></>;
  }

  const isActive = location.pathname === params.entry.link.link;

  return (
    <Link
      to={{ pathname: params.entry.link.link, search: location.search }}
      className={styles.child + " " + (isActive ? styles.active : "")}
    >
      <label>{params.entry.label}</label>
    </Link>
  );
};