import React, { useEffect, useMemo, useState } from "react";
import styles from "./GenericMasterDataEditor.module.scss";
import Select, { createFilter, StylesConfig } from "react-select";
import { Icon } from "../../../../parts/icons/Icon";
import {
  CancelInternalRecordFunction,
  SaveInternalRecordFunction,
} from "../views/GenericMasterDataEditorView";
import { FieldType } from "../../../../models/master data/generics/FieldType";
import { EditorSectionPacket } from "../../../../models/master data/generics/EditorSection";
import { EditorProperty } from "../../../../models/master data/generics/EditorProperty";
import { EditorRecord } from "../../../../models/master data/generics/EditorRecord";
import { IconType } from "../../../../models/application/icon/IconType";

interface GenericMasterDataEditorProps<T extends EditorRecord> {
  sectionDefintions: EditorSectionPacket<T>[];

  record: T;

  cancelRecord: CancelInternalRecordFunction;
  saveRecord: SaveInternalRecordFunction<T> | null;
}

type LocalUpdateRecordFunction<T> = (record: T) => any;
type LocalSaveRecordFunction<T> = () => any;

export function GenericMasterDataEditor<T extends EditorRecord>(
  props: GenericMasterDataEditorProps<T>,
) {
  const [localRecord, setLocalRecord] = useState<T>(props.record);

  const saveRecord = props.saveRecord
    ? () => {
        props.saveRecord!(localRecord);
      }
    : null;

  useEffect(() => {
    setLocalRecord(props.record);
  }, [props.record]);

  return (
    <div className={styles.masterDataEditor}>
      <Header record={localRecord} />

      {props.sectionDefintions.map((section) => (
        <Section
          key={section.id}
          section={section}
          record={localRecord}
          setRecord={setLocalRecord}
          readonly={props.saveRecord === null}
        />
      ))}

      <Footer cancelRecord={props.cancelRecord} saveRecord={saveRecord} />
    </div>
  );
}

type HeaderParams<T extends EditorRecord> = {
  record: T;
};

function Header<T extends EditorRecord>(params: HeaderParams<T>): React.JSX.Element {
  return (
    <div className={styles.heading}>
      <h1>Edit {params.record.displayName}</h1>
    </div>
  );
}

type SectionParams<T extends EditorRecord> = {
  section: EditorSectionPacket<T>;
  record: T;
  setRecord: LocalUpdateRecordFunction<T>;
  readonly: boolean;
};

function Section<T extends EditorRecord>(params: SectionParams<T>) {
  return (
    <div className={styles.section}>
      {params.section.heading ? (
        <div className={styles.title}>
          <h1>{params.section.heading}</h1>
        </div>
      ) : null}

      <div className={styles.content}>
        {params.section.properties.map((property) => (
          <GenericInput
            key={property.propertyName.toString()}
            property={property}
            record={params.record}
            setRecord={params.setRecord}
            readonly={params.readonly}
          />
        ))}
      </div>
    </div>
  );
}

type FooterParams<T extends EditorRecord> = {
  cancelRecord: CancelInternalRecordFunction;
  saveRecord: LocalSaveRecordFunction<T> | null;
};

function Footer<T extends EditorRecord>(params: FooterParams<T>) {
  return (
    <div className={styles.footer}>
      <button onClick={() => params.cancelRecord()}>
        <Icon iconType={IconType.MaterialUI} iconName={"close"} />
        Cancel
      </button>
      {params.saveRecord && (
        <button onClick={() => params.saveRecord!()} className={styles.alternateDesign}>
          <Icon iconType={IconType.MaterialUI} iconName={"content-save-outline"} />
          Save
        </button>
      )}
    </div>
  );
}

type GenericInputParams<T extends EditorRecord> = {
  property: EditorProperty<T>;
  record: T;
  setRecord: LocalUpdateRecordFunction<T>;
  readonly: boolean;
};

function GenericInput<T extends EditorRecord>(params: GenericInputParams<T>) {
  if (params.property.type === FieldType.Text) {
    return <TextField {...params} />;
  } else if (params.property.type === FieldType.Date) {
    return <DateField {...params} />;
  } else if (params.property.type === FieldType.Number) {
    return <NumberField {...params} />;
  } else if (params.property.type === FieldType.Checkbox) {
    return <CheckboxField {...params} />;
  } else if (params.property.type === FieldType.Dropdown) {
    return <DropdownField {...params} />;
  } else {
    return null;
  }
}

function TextField<T extends EditorRecord>(params: GenericInputParams<T>): React.JSX.Element {
  return (
    <div className={styles.item}>
      <div className={styles.horizontal + " " + styles.text}>
        <input
          type="text"
          placeholder={params.property.displayName}
          value={
            (params.record[params.property.propertyName as keyof typeof params.record] as string) ??
            ""
          }
          onChange={(e) => {
            params.setRecord({
              ...params.record,
              [params.property.propertyName]: e.target.value,
            });
          }}
          readOnly={params.readonly}
          disabled={params.readonly}
        />
        <label>{params.property.displayName}</label>
      </div>
    </div>
  );
}

function DateField<T extends EditorRecord>(params: GenericInputParams<T>): React.JSX.Element {
  const sourceDate =
    new Date(params.record[params.property.propertyName as keyof typeof params.record] as Date) ??
    new Date();

  const offset = Math.abs(sourceDate.getTimezoneOffset());
  sourceDate.setTime(sourceDate.getTime() + offset * 60 * 1000);

  return (
    <div className={styles.item}>
      <div className={styles.horizontal + " " + styles.date}>
        <input
          type="date"
          placeholder={params.property.displayName}
          value={sourceDate.toISOString().split("T")[0]}
          onChange={(e) => {
            try {
              if (!e.target.value) {
                params.setRecord({
                  ...params.record,
                  [params.property.propertyName]: new Date("1970-01-01"),
                });
                return;
              }

              params.setRecord({
                ...params.record,
                [params.property.propertyName]: new Date(e.target.value),
              });
            } catch {}
          }}
          readOnly={params.readonly}
          disabled={params.readonly}
        />
        <label>{params.property.displayName}</label>
      </div>
    </div>
  );
}

function NumberField<T extends EditorRecord>(params: GenericInputParams<T>): React.JSX.Element {
  return (
    <div className={styles.item}>
      <div className={styles.horizontal + " " + styles.number}>
        <input
          type="number"
          placeholder={params.property.displayName}
          value={
            (params.record[params.property.propertyName as keyof typeof params.record] as number) ??
            0
          }
          onChange={(e) => {
            params.setRecord({
              ...params.record,
              [params.property.propertyName]: Number(e.target.value),
            });
          }}
          readOnly={params.readonly}
          disabled={params.readonly}
        />
        <label>{params.property.displayName}</label>
      </div>
    </div>
  );
}

function CheckboxField<T extends EditorRecord>(params: GenericInputParams<T>): React.JSX.Element {
  return (
    <div className={styles.item}>
      <div className={styles.horizontal + " " + styles.checkbox}>
        <div className={styles.vertical}>
          <input
            id={params.property.propertyName.toString()}
            type="checkbox"
            checked={
              (params.record[
                params.property.propertyName as keyof typeof params.record
              ] as boolean) ?? false
            }
            onChange={(e) => {
              params.setRecord({
                ...params.record,
                [params.property.propertyName]: !params.record[
                  params.property.propertyName as keyof typeof params.record
                ] as boolean,
              });
            }}
            readOnly={params.readonly}
            disabled={params.readonly}
          />
          <label htmlFor={params.property.propertyName.toString()}>
            {params.property.displayName}
          </label>
        </div>
      </div>
    </div>
  );
}

function DropdownField<T extends EditorRecord>(params: GenericInputParams<T>): React.JSX.Element {
  const colorStyles: StylesConfig<any> = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "var(--mdm_Select_BackgroundColor)",
      height: "48px",
      boxShadow: "0",
      border: "1px solid gray",
      "&:hover": {
        border: "1px solid var(--mdm_Select_TextColor)",
      },
    }),
    option: (styles) => ({
      ...styles,
      color: "var(--mdm_Select_TextColor)",
      fontSize: "14px",
      backgroundColor: "var(--mdm_Select_BackgroundColor)",
      "&:hover": {
        backgroundColor: "var(--mdm_Select_HoverColor)",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "var(--mdm_Select_TextColor)",
      fontSize: "14px",
    }),
    placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "var(--mdm_Select_BackgroundColor)",
      border: "1px solid gray",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "5px",
      "&::-webkit-scrollbar": {
        backgroundColor: "var(--mdm_Select_BackgroundColor)",
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "var(--mdm_Select_BackgroundColor)",
        border: "1px solid var(--mdm_Select_TextColor)",
        borderRadius: "0 5px 5px 0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "var(--mdm_Select_TextColor)",
        borderRadius: "0 5px 5px 0",
      },
    }),
    input: (styles) => ({ ...styles, color: "var(--fontColor)", fontSize: "14px" }),
  };

  const options = useMemo<T[]>(() => {
    const allOptions = params.property.additionalInformation!;
    const illegalOption = allOptions.find((x) => x.id === params.record.id);

    if (illegalOption) {
      const index = allOptions.indexOf(illegalOption);

      if (index !== -1) {
        allOptions.splice(index, 1);
      }
    }

    return allOptions;
  }, [params.property.additionalInformation]);

  const value =
    options.find((x) => x.id !== null && x.id === params.record[params.property.propertyName]) ??
    null;

  return (
    <div className={styles.item}>
      <div className={styles.horizontal + " " + styles.dropdown}>
        <Select
          onChange={(e) => {
            if (e !== null) {
              params.setRecord({
                ...params.record,
                [params.property.propertyName]: e.id,
              });
            } else {
              params.setRecord({
                ...params.record,
                [params.property.propertyName]: null,
              });
            }
          }}
          options={params.property.additionalInformation!}
          value={value}
          getOptionLabel={(option) => option.displayName}
          getOptionValue={(option) => option.id}
          styles={colorStyles}
          isSearchable={true}
          isClearable={true}
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: false,
          })}
          isDisabled={params.readonly}
        />
        <label>{params.property.displayName}</label>
      </div>
    </div>
  );
}