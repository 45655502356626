import { IThemeProvider } from "./IThemeProvider";
import { ThemeItem } from "../../../models/application/theme/ThemeItem";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";

export class ThemeProvider extends BaseApiDataProvider implements IThemeProvider {
  private static GET_THEMES_KEY = "themes";

  private static cache: Map<string, ThemeItem[]> = new Map();

  public async getThemes(): Promise<ThemeItem[]> {
    if (!ThemeProvider.cache.has(ThemeProvider.GET_THEMES_KEY)) {
      const themes = await this.get("/theme");
      ThemeProvider.cache.set(ThemeProvider.GET_THEMES_KEY, themes);
    }

    return ThemeProvider.cache.get(ThemeProvider.GET_THEMES_KEY) ?? [];
  }
}

export class ThemeProviderFactory {
  private static themeProvider: IThemeProvider | null = null;

  public static getThemeProvider(): IThemeProvider {
    if (this.themeProvider === null) {
      this.themeProvider = new ThemeProvider(new BaseApiRequestHandler());
    }

    return this.themeProvider;
  }
}