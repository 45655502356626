import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "../PlanAndSimulate.module.scss";
import "./RichTextEditor.scss";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    [
      {
        color: [
          false,
          "red",
          "pink",
          "brown",
          "orange",
          "yellow",
          "blue",
          "lightblue",
          "purple",
          "green",
          "lightgreen",
          "darkgrey",
          "silver",
          "lightgrey",
        ],
      },
    ],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

export interface RichTextEditorProps {
  value: string;
}

export const RichTextEditor = (props: RichTextEditorProps) => {
  const [value, setValue] = useState(props.value);
  return (
    <div className={styles.editorWrapper}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};