import { useEffect, useState } from "react";
import { SettingPacket } from "../../models/configuration/SettingPacket";
import { SettingsProviderFactory } from "../../providers/configuration/SettingsProvider";

export const useSettings = () => {
  const [settings, setSettings] = useState<SettingPacket[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const settingsProvider = SettingsProviderFactory.getSettingsProvider();

    async function getSettings() {
      try {
        const settings = await settingsProvider.getAllSettings();
        setSettings(settings);
        setLoading(false);
      } catch {
        setSettings([]);
        setLoading(false);
      }
    }

    getSettings();
  }, []);

  return { settings, loading };
};