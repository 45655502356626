import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import React from "react";
import styles from "./Logo.module.scss";

export const LogoContainer: React.FunctionComponent = () => {
  return (
    <Link to="/" className={styles["container"]}>
      <Logo />
    </Link>
  );
};