import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { FilterContext } from "./FilterContext";
import { useSelectedYear } from "../../../hooks/report data/year/useSelectedYear";
import { useFilters } from "../../../hooks/report data/filter/useFilters";
import { ReportContext } from "../report/ReportContext";
import { FilterConfig } from "../../../models/report data/filter/FilterConfig";

export const FilterContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const reportContext = useContext(ReportContext);

  const { selectedYear } = useSelectedYear();
  const { filters, isLoadingFilters } = useFilters(reportContext.reportLink, selectedYear);

  const [filterConfigs, setFilterConfigs] = useState<FilterConfig[]>([]);
  const [filterConfigChanged, setFilterConfigChanged] = useState<boolean>(false);

  const getFilterConfig = (originId: string) => {
    return filterConfigs.find((x) => x.originId === originId);
  };

  const setFilterConfig = (filterConfig: FilterConfig) => {
    const index = filterConfigs.findIndex((x) => x.originId === filterConfig.originId);

    if (index === -1) {
      setFilterConfigs([...filterConfigs, filterConfig]);
    } else {
      filterConfigs[index] = filterConfig;
      setFilterConfigs([...filterConfigs]);
    }

    setFilterConfigChanged(!filterConfigChanged);
  };

  const deleteFilterConfig = (originId: string) => {
    const index = filterConfigs.findIndex((x) => x.originId === originId);

    if (index !== -1) {
      filterConfigs.splice(index, 1);
      setFilterConfigs([...filterConfigs]);
      setFilterConfigChanged(!filterConfigChanged);
    }
  };

  const resetFilterConfig = () => {
    setFilterConfigs([]);
    setFilterConfigChanged(!filterConfigChanged);
  };

  useEffect(() => {
    resetFilterConfig();
  }, [reportContext.reportLink]);

  return (
    <FilterContext.Provider
      value={{
        filters,
        isLoadingFilters,
        getFilterConfig,
        setFilterConfig,
        deleteFilterConfig,
        filterConfigChanged,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};