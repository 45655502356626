import React, { useContext, useEffect, useState } from "react";

import { IconType } from "../../../models/application/icon/IconType";
import { Icon } from "../../../parts/icons/Icon";
import { FeedbackProviderFactory } from "../../../providers/master data/feedback/FeedbackProvider";
import { IFeedbackProvider } from "../../../providers/master data/feedback/IFeedbackProvider";
import styles from "./FeedbackDialog.module.scss";
import { useLocation } from "react-router-dom";
import { MenuCategory } from "../../../models/application/menu/MenuCategory";
import { MenuBlurArea } from "../../../models/application/menu/MenuBlurArea";
import { Menu, MenuContext } from "../../../contexts/application/menu/MenuContext";

export const FeedbackDialog: React.FunctionComponent<any> = (props) => {
  const MENU_CATEGORY = MenuCategory.TopMenu;
  const MENU_ID = `feedbackDialog`;
  const BLUR_AREA = MenuBlurArea.Content;
  const MENU: Menu = {
    category: MENU_CATEGORY,
    menuId: MENU_ID,
    blur: BLUR_AREA,
  };

  const location = useLocation();

  const menuContext = useContext(MenuContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const feedbackProvider: IFeedbackProvider = FeedbackProviderFactory.getFeedbackProvider();

  function toggleMenu() {
    if (isOpen) {
      menuContext.removeMenu(MENU);
      setComment("");
    } else {
      menuContext.setMenu(MENU);
    }
  }

  function handleChange(e: any) {
    setComment(e.target.value);
  }

  async function sendFeedback() {
    await feedbackProvider.createFeedback({
      comment: comment,
      url: location.pathname,
    });

    toggleMenu();
  }

  useEffect(() => {
    setIsOpen(menuContext.isMenuOpen(MENU_CATEGORY, MENU_ID));
  }, [menuContext.menuUpdated]);

  return (
    <>
      <div className={styles.feedbackMarker}>
        <Icon
          iconName="forum-outline"
          iconType={IconType.MaterialUI}
          className={styles.icon}
          onClick={() => toggleMenu()}
        />
      </div>

      {isOpen && (
        <div className={styles.feedbackDialog}>
          <Icon
            className={styles.close}
            iconName={"close"}
            iconType={IconType.MaterialUI}
            onClick={() => toggleMenu()}
          />

          <div className={styles.headline}>Feedback</div>

          <textarea name="comment" onChange={handleChange}></textarea>

          <div className={styles.textRight}>
            <button onClick={() => sendFeedback()} disabled={comment.length === 0}>
              <Icon iconType={IconType.MaterialUI} iconName={"email"} />
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};