import { useOrientation } from "@uidotdev/usehooks";
import { Embed } from "embed";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams, useSearchParams } from "react-router-dom";
import { Report } from "report";
import { v4 as uuidv4 } from "uuid";
import { ThemeContext } from "../../../contexts/application/theme/ThemeContext";
import { ReportContext } from "../../../contexts/report data/report/ReportContext";
import { TriggerContext } from "../../../contexts/report data/trigger/TriggerContext";
import { AppConfigManager } from "../../../models/configuration/AppConfigManager";
import { AppConfigurationModel } from "../../../models/configuration/AppConfigurationModel";
import { TriggerActionType } from "../../../models/report data/trigger/TriggerActionType";
import { TriggerType } from "../../../models/report data/trigger/TriggerType";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";
import { Layout_Mobile } from "../../../shared/layouts/Layouts";
import styles from "./ReportView.module.scss";
import { FilterContext } from "../../../contexts/report data/filter/FilterContext";

export const ReportView: React.FunctionComponent = () => {
  const [isReportLoading, setIsReportLoading] = useState<boolean>(true);
  const [isReportRendering, setIsReportRendering] = useState<boolean>(false);
  const [isReportReRendering, setIsReportReRendering] = useState<boolean>(false);
  const [loadedPage, setLoadedPage] = useState<models.IPage | undefined>(undefined);

  const [reportRenderId, setReportRenderId] = useState<string>(uuidv4());

  const [searchParams] = useSearchParams();

  const appConfiguration: AppConfigurationModel = AppConfigManager.getAppConfiguration();

  const reportContext = useContext(ReportContext);
  const themeContext = useContext(ThemeContext);
  const filterContext = useContext(FilterContext);
  const triggerContext = useContext(TriggerContext);

  const { reportLink } = useParams();

  const orientation = useOrientation();

  const isMobileLayout = useMediaQuery({ query: `(max-width: ${Layout_Mobile})` });
  const isPortrait = orientation.type.startsWith("portrait");

  const layoutType = isMobileLayout
    ? isPortrait
      ? models.LayoutType.MobilePortrait
      : models.LayoutType.MobileLandscape
    : models.LayoutType.Master;

  const initialPage = getInitialPage();

  const reportConfig: models.IReportEmbedConfiguration = {
    type: "report",
    id: reportContext.reportPacket?.embedParams?.embedReport.reportId,
    embedUrl: reportContext.reportPacket?.embedParams?.embedReport.embedUrl,
    accessToken: reportContext.reportPacket?.embedParams?.embedToken.token,
    tokenType: models.TokenType.Embed,
    pageName: initialPage,
    settings: {
      panes: {
        filters: {
          visible: appConfiguration.showFilters,
        },
        pageNavigation: {
          visible: appConfiguration.showPages,
        },
      },
      background: models.BackgroundType.Transparent,
      layoutType: layoutType,
    },
    theme: {
      themeJson: themeContext.themeService.getPowerBITheme(), // Load theme once on init
    },
  };

  function getInitialPage() {
    const pageName = reportContext.reportPacket?.triggers
      .find((x) => x.triggerType === TriggerType.onLoad)
      ?.actions.find((x) => x.actionType === TriggerActionType.changePage)?.actionValue;

    return pageName;
  }

  const embedReportCallback = (embedReport: Embed) => {
    reportContext.setReport(embedReport as Report);
  };

  const renderedPageCallback = () => {
    setReportRenderId(uuidv4());
  };

  const pageChangedCallback = (event: any) => {
    const page: models.IPage = event.detail.newPage;
    setLoadedPage(page);
  };

  // State Management

  useEffect(() => {
    setIsReportLoading(true);
    reportContext.setReportLink(reportLink);
  }, [reportLink]);

  useEffect(() => {
    if (!loadedPage) {
      return;
    }

    setIsReportRendering(true);
    reportContext.setReportLoaded(reportContext.reportLoaded + 1);
  }, [loadedPage]);

  useEffect(() => {
    if (isReportRendering) {
      reportContext.setReportLoaded(reportContext.reportLoaded + 1);
      setIsReportRendering(false);
      setIsReportReRendering(true);
    }
  }, [reportRenderId]);

  useEffect(() => {
    if (isReportReRendering) {
      setIsReportReRendering(false);

      setTimeout(() => {
        setIsReportLoading(false);
      }, 500);
    }
  }, [isReportReRendering]);

  // Trigger Management

  useEffect(() => {
    if (reportContext.reportPacket === null || reportContext.isDownloadingReportPacket) {
      return;
    }

    triggerContext.triggerService.processOnLoadTriggers(reportContext.reportPacket);
  }, [reportContext.reportLoaded, reportContext.reportPacket]);

  useEffect(() => {
    if (reportContext.reportPacket === null) {
      return;
    }

    triggerContext.triggerService.processFilterChangeTriggers(
      reportContext.reportPacket,
      Array.from(searchParams.values()),
    );

    triggerContext.triggerService.processYearChangeTriggers(
      reportContext.reportPacket,
      Array.from(searchParams.values()),
    );
  }, [searchParams]);

  useEffect(() => {
    if (!reportContext.reportPacket || !loadedPage) {
      return;
    }

    triggerContext.triggerService.processOnPageChangeTriggers(
      reportContext.reportPacket!,
      loadedPage,
    );
  }, [loadedPage]);

  // Dependency removal

  useEffect(() => {
    return () => {
      reportContext.setReport(null);
      reportContext.setReportLink(undefined);
    };
  }, []);

  const showLoader =
    reportContext.isDownloadingReportPacket ||
    isReportLoading ||
    isReportRendering ||
    filterContext.isLoadingFilters;
  const showError =
    !reportContext.isDownloadingReportPacket &&
    reportContext.reportPacket?.embedParams === undefined;

  return (
    <div className={styles.reportView}>
      <PowerBIEmbed
        cssClassName={styles.powerBi}
        embedConfig={reportConfig}
        getEmbeddedComponent={embedReportCallback}
        eventHandlers={
          new Map([
            ["pageChanged", pageChangedCallback],
            ["rendered", renderedPageCallback],
          ])
        }
      />

      {showLoader && !showError && (
        <div className={styles.overlay}>
          <FullscreenPropagateLoader />
        </div>
      )}

      {showError && (
        <div className={styles.overlay}>
          <h1>The report couldn't be found</h1>
        </div>
      )}
    </div>
  );
};