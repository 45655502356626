import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { FilterDropdownItem } from "./FilterDropdownItem";
import "./FilterDropdownMenu.scss";
import { FilterService } from "../../../../services/report data/filter/FilterService";
import { MenuChangeProps } from "./FilterDropdown";
import { FilterItemPacket } from "../../../../models/report data/filter/FilterItemPacket";

interface FilterDropdownMenuProps {
  parent: FilterItemPacket;
  children: FilterItemPacket[];
  primary: boolean;

  parameterName: string;

  menuName: string;
  activeMenu: string;
  setActiveMenu: Function;
  setMenuHeight: Function;
  calculateMenuWidth: Function;

  menuChange: MenuChangeProps | null;
  setMenuChange: Function;

  closeMenu: Function;
}

export const FilterDropdownMenu: React.FunctionComponent<FilterDropdownMenuProps> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [children, setChildren] = useState<FilterItemPacket[]>(props.children);
  const [sortedChildren, setSortedChildren] = useState<FilterItemPacket[]>(
    FilterService.sortFilters(children),
  );

  function setActiveMenu(from: string, to: string, isReturn: boolean) {
    const menuChange: MenuChangeProps = {
      from: isReturn ? to : from,
      to: isReturn ? from : to,
    };

    props.setMenuChange(menuChange);
    props.setActiveMenu(to);
  }

  const classNames =
    props.menuChange !== null
      ? props.menuChange.from === props.menuName
        ? "menu-primary"
        : props.menuChange.to === props.menuName
        ? "menu-secondary"
        : ""
      : "";

  useEffect(() => {
    setChildren(props.children);
  }, [props.children]);

  useEffect(() => {
    setSortedChildren(FilterService.sortFilters(children));
  }, [children]);

  useEffect(() => {
    props.setMenuHeight(divRef.current?.offsetHeight);
  }, [divRef.current?.offsetHeight]);

  return (
    <>
      <CSSTransition
        nodeRef={divRef}
        in={props.activeMenu === props.menuName}
        timeout={300}
        unmountOnExit
        classNames={classNames}
        onEnter={() => {
          props.calculateMenuWidth([...props.children, props.parent]);
        }}
      >
        <div ref={divRef} className={"menu"}>
          {!props.primary && (
            <FilterDropdownItem
              filter={props.parent}
              parent={props.parent}
              parameterName={props.parameterName}
              isReturnItem={true}
              setActiveMenu={setActiveMenu}
              closeMenu={props.closeMenu}
            />
          )}
          {sortedChildren.map((filter) => {
            if (!filter.visible) {
              return <></>;
            }

            return (
              <FilterDropdownItem
                key={filter.originId}
                filter={filter}
                parent={props.parent}
                parameterName={props.parameterName}
                isReturnItem={false}
                setActiveMenu={setActiveMenu}
                closeMenu={props.closeMenu}
              />
            );
          })}
        </div>
      </CSSTransition>

      {sortedChildren.map((filter) => {
        const children = filter.children ?? [];

        return (
          <FilterDropdownMenu
            key={filter.originId}
            parent={filter}
            children={children}
            primary={false}
            parameterName={props.parameterName}
            menuName={filter.originId}
            activeMenu={props.activeMenu}
            setActiveMenu={props.setActiveMenu}
            setMenuHeight={props.setMenuHeight}
            calculateMenuWidth={props.calculateMenuWidth}
            menuChange={props.menuChange}
            setMenuChange={props.setMenuChange}
            closeMenu={props.closeMenu}
          />
        );
      })}
    </>
  );
};