import React, { HTMLAttributes } from "react";
import styles from "./MobileBottomMenu.module.scss";
import { Icon } from "../../../../parts/icons/Icon";
import { MenuGroupPacket } from "../../../../models/application/menu/MenuGroupPacket";
import { MenuEntryPacket } from "../../../../models/application/menu/MenuEntryPacket";
import { MenuEntryType } from "../../../../models/application/menu/MenuEntryType";
import { Link, useLocation } from "react-router-dom";

interface MobileBottomMenuProps extends HTMLAttributes<any> {
  menuGroups: MenuGroupPacket[];
}

export const MobileBottomMenu: React.FunctionComponent<MobileBottomMenuProps> = (props) => {
  return (
    <div className={styles.bottom}>
      <div className={styles.menuGroup}>
        {props.menuGroups.map((group) => group.entries.map((entry) => <MenuItem entry={entry} />))}
      </div>
    </div>
  );
};

type MenuItemParams = {
  entry: MenuEntryPacket;
};
const MenuItem = (params: MenuItemParams) => {
  const location = useLocation();

  if (params.entry.type !== MenuEntryType.Link) {
    return <></>;
  }

  if (!params.entry.link) {
    return <></>;
  }

  return (
    <Link
      className={styles.menuItem}
      to={{ pathname: params.entry.link.link, search: location.search }}
    >
      <Icon iconType={params.entry.iconType} iconName={params.entry.icon} />
    </Link>
  );
};