import React, { HTMLAttributes, useContext } from 'react';

import { ReportContext } from '../../../contexts/report data/report/ReportContext';
import { FavouritesMarker } from '../../application/favourites/FavouritesMarker';
import { LogoContainer } from '../../application/logo/LogoContainer';
import { ThemeSwitchView } from '../../application/theme/ThemeSwitchView';
import { ProfilePicture } from '../../identity/profile picture/ProfilePicture';
import { FeedbackDialog } from '../../master data management/feedback/FeedbackDialog';
import { FilterView } from '../../report/filter/main-filter/FilterView';
import { DateFilter } from '../../report/filter/special-filter/DateFilter';
import styles from './DesktopTopBar.module.scss';

export const DesktopTopBar: React.FunctionComponent<HTMLAttributes<any>> = (props) => {
  const reportContext = useContext(ReportContext);

  return (
    <div className={props.className}>
      <div className={styles.topNav}>
        {reportContext.reportLink && <FilterView />}

        <div className={styles.end}>
          {reportContext.reportLink && <DateFilter />}
          <div className={styles.divider} />

          {reportContext.reportLink && <FavouritesMarker reportId={reportContext.reportLink} />}
          <FeedbackDialog />
          <ThemeSwitchView />
          <ProfilePicture />
          <div className={styles.divider} />
          <LogoContainer />
        </div >
      </div >
    </div >
  );
};