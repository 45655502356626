import * as React from "react";
const SvgLeaf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 115 110"
    {...props}
  >
    <defs>
      <clipPath id="leaf_svg__a">
        <path d="M1395 670h115v110h-115z" />
      </clipPath>
    </defs>
    <g clipPath="url(#leaf_svg__a)" transform="translate(-1395 -670)">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M1448.45 679.691c-25.11 0-45.35 20.192-45.35 45.228 0 7.915 2.1 15.345 5.67 21.806 3.4-3.553 7.12-7.107 11.17-10.983 10.21-9.531 22.19-19.222 32.24-26.329.64-.485 1.29-.808 2.1-.808 1.78 0 3.24 1.454 3.24 3.23 0 1.131-.48 1.939-1.29 2.585-14.42 10.338-33.53 26.329-46.49 40.866 0 0-1.78 1.939-2.59 2.908-7.29 8.561-12.15 16.314-12.15 21.806h9.72c0-3.554 5.67-11.307 14.25-20.514 7.94 6.784 18.14 10.822 29.48 10.822 8.1 0 15.87-2.099 22.52-5.976C1505.63 745.11 1510 709.736 1510 670c0 0-37.74 10.338-61.55 9.691Z"
      />
    </g>
  </svg>
);
export default SvgLeaf;
