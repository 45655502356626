import React from "react";
import { MsalService } from "../../../services/identity/MsalService";
import styles from "./ProfilePicture.module.scss";
import { GraphService } from "../../../services/identity/GraphService";

interface ProfilePictureState {
  imageUrl: string;
}

export class ProfilePicture extends React.Component<any, ProfilePictureState> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageUrl: "",
    };
  }

  async componentDidMount() {
    const msalInstance = MsalService.getAuthenticationInstance();
    const account = msalInstance.getActiveAccount()!;
    const graphInstance = GraphService.getGraphInstance(account);

    try {
      const profile = await graphInstance.api("/me/photo/$value").get();
      const imageUrl = URL.createObjectURL(profile);
      this.setState({ imageUrl: imageUrl });
    } catch {}
  }

  render() {
    return (
      <div className={styles.container}>
        {this.state.imageUrl && <img alt="my-user-avatar" src={this.state.imageUrl} />}
        {!this.state.imageUrl && (
          <div>
            <label>{this.retrieveInitials()}</label>
          </div>
        )}
      </div>
    );
  }

  private retrieveInitials(): string {
    const msalClient = MsalService.getAuthenticationInstance();
    const account = msalClient.getActiveAccount()!;

    try {
      const firstInitial = account.name?.split(" ")[0][0];
      const lastInitial = account.name?.split(" ")[1][0];

      return `${firstInitial}${lastInitial}`;
    } catch (e) {
      return "UU";
    }
  }
}