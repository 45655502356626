import React, { useContext, useEffect, useRef, useState } from "react";
import { FilterService } from "../../../../services/report data/filter/FilterService";
import styles from "./FilterView.module.scss";
import { Filter } from "./Filter";
import { FilterContext } from "../../../../contexts/report data/filter/FilterContext";
import { BeatLoader } from "react-spinners";

export const FilterView: React.FunctionComponent = () => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [menuSize, setMenuSize] = useState(0);
  const growerRef = useRef<HTMLDivElement | null>(null);
  const [growerSize, setGrowerSize] = useState(Number.MAX_SAFE_INTEGER);

  const [compactFilters, setCompactFilters] = useState(false);

  const filterContext = useContext(FilterContext);

  const [structuredFilters, setStructuredFilters] = useState(
    FilterService.structureFilters(filterContext.filters),
  );
  const [sortedFilters, setSortedFilters] = useState(FilterService.sortFilters(structuredFilters));
  const [mappedFilters, setMappedFilters] = useState(
    compactFilters ? FilterService.wrapFilters(sortedFilters) : sortedFilters,
  );

  const processResize = () => {
    setGrowerSize(growerRef.current?.offsetWidth ?? 0);
  };

  const processGrowerResize = () => {
    if (growerSize < 20 && !compactFilters) {
      setMenuSize(menuRef.current!.offsetWidth);
      setCompactFilters(true);
    } else if (compactFilters && growerSize > menuSize - 65) {
      setCompactFilters(false);
    }
  };

  // Filter loading events
  useEffect(() => {
    setStructuredFilters(FilterService.structureFilters(filterContext.filters));
  }, [filterContext.filters]);

  useEffect(() => {
    setSortedFilters(FilterService.sortFilters(structuredFilters));
  }, [structuredFilters]);

  useEffect(() => {
    setMappedFilters(compactFilters ? FilterService.wrapFilters(sortedFilters) : sortedFilters);
  }, [sortedFilters, compactFilters]);

  // Other events
  useEffect(() => {
    processGrowerResize();
  }, [growerSize]);

  useEffect(() => {
    processResize();
  }, [mappedFilters]);

  useEffect(() => {
    processResize();

    window.addEventListener("resize", processResize);
    return () => window.removeEventListener("resize", processResize);
  }, []);

  const color = getComputedStyle(document.documentElement).getPropertyValue("--fontColor");

  return (
    <>
      <nav ref={menuRef}>
        <ul className={styles.navbar}>
          {filterContext.isLoadingFilters ? (
            <>
              <BeatLoader color={color} size={12} className={styles.loader} />
            </>
          ) : (
            <>
              {mappedFilters.map((filter) => {
                return <Filter filter={filter} key={filter.originId} />;
              })}
            </>
          )}
        </ul>
      </nav>
      <div className={styles.grower} ref={growerRef} />
    </>
  );
};