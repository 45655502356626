import React, { PropsWithChildren } from "react";
import { MsalService } from "../../../services/identity/MsalService";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { Login } from "../login/Login";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";
import styles from "./AuthorizationBoundary.module.scss";
import { useApplicationAuthorization } from "../../../hooks/identity/authorization/useApplicationAuthorization";

export const AuthorizationBoundary: React.FunctionComponent<PropsWithChildren> = (props) => {
  const msalInstance = MsalService.getAuthenticationInstance();
  const account = msalInstance.getActiveAccount();

  const { isAuthorized, isLoadingAuthorized } = useApplicationAuthorization(
    account?.username ?? "",
  );

  const showLoading = isLoadingAuthorized || account?.username === undefined;

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        {showLoading && <FullscreenPropagateLoader />}

        {!showLoading && !isAuthorized && (
          <div className={styles.center}>
            <h1>You are not authorized to use this web app</h1>
          </div>
        )}

        {!showLoading && isAuthorized && <>{props.children}</>}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};