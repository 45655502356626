import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Menu, MenuContext } from "../../../contexts/application/menu/MenuContext";
import { SettingsContext } from "../../../contexts/application/settings/SettingsContext";
import { MenuCategory } from "../../../models/application/menu/MenuCategory";
import { SettingsConstants } from "../../../models/configuration/SettingsConstants";
import { Layout_Desktop, Layout_Mobile } from "../../../shared/layouts/Layouts";
import { FavouritesView } from "../../application/favourites/FavouritesView";
import { SearchView } from "../../application/search/SearchView";
import { Logout } from "../../identity/logout/Logout";
import { AbbreviationView } from "../../master data management/abbreviation/AbbreviationView";
import { ESAProjectRelationsView } from "../../master data management/esa projects/ESAProjectRelationsView";
import { ESAProjectsView } from "../../master data management/esa projects/ESAProjectsView";
import { FeedbackView } from "../../master data management/feedback/FeedbackView";
import { PageNotFound } from "../../navigation/page not found/PageNotFound";
import { ReportView } from "../../report/report/ReportView";
import { DesktopSideBar } from "../side bar/DesktopSideBar";
import { DesktopTopBar } from "../top bar/DesktopTopBar";
import { MobileTopBar } from "../top bar/MobileTopBar";
import styles from "./LayoutFrame.module.scss";
import { MenuBlurArea } from "../../../models/application/menu/MenuBlurArea";
import { PlanAndSimulate } from "../../plan and simulate/PlanAndSimulateView";

export const LayoutFrame: React.FunctionComponent = () => {
  const COLLAPSED_SIDE_MENU_SIZE = 50;
  const EXPANDED_SIDE_MENU_SIZE = 250;

  const MENU_CATEGORY = MenuCategory.SideMenu;
  const MENU_ID = "sideMenu";
  const BLUR_AREA = MenuBlurArea.Content;
  const MENU: Menu = {
    category: MENU_CATEGORY,
    menuId: MENU_ID,
    blur: null,
  };

  const menuContext = useContext(MenuContext);
  const settingsContext = useContext(SettingsContext);

  const location = useLocation();

  const defaultPage = settingsContext.settings.find((x) => x.key === SettingsConstants.defaultPage);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  const isTopMenuOpen = !menuContext.isNoMenuOpen(MenuCategory.TopMenu);

  const isMobileLayout = useMediaQuery({ query: `(max-width: ${Layout_Mobile})` });
  const isDesktopLayout = useMediaQuery({ query: `(min-width: ${Layout_Desktop})` });

  function getSideMenuSize(): number {
    if (isOpen) {
      return EXPANDED_SIDE_MENU_SIZE;
    } else {
      return COLLAPSED_SIDE_MENU_SIZE;
    }
  }

  function toggleSideMenu() {
    if (isOpen) {
      menuContext.removeMenu(MENU);
    } else {
      menuContext.setMenu(MENU);
    }
  }

  useEffect(() => {
    menuContext.setMenu(MENU);
  }, []);

  useEffect(() => {
    setIsOpen(menuContext.isMenuOpen(MENU_CATEGORY, MENU_ID));
    setIsBlurred(menuContext.isBlurred(BLUR_AREA));
  }, [menuContext.menuUpdated]);

  useEffect(() => {
    menuContext.removeMenu({ category: MenuCategory.TopMenu, menuId: null });
  }, [location]);

  const expansionStyle = {
    gridTemplateColumns: `${getSideMenuSize() + 1}px 1fr`, // Plus 1 for the border
  };

  return (
    <div style={isDesktopLayout ? expansionStyle : {}} className={styles.frame}>
      {isMobileLayout && <MobileTopBar />}
      {isDesktopLayout && (
        <>
          <DesktopTopBar className={styles.header} />
          <DesktopSideBar
            className={styles.navigation}
            toggleMenu={() => toggleSideMenu()}
            menuExpanded={isOpen}
          />
        </>
      )}

      <div
        className={
          styles.content +
          " " +
          (isBlurred ? styles.blurred : "") +
          " " +
          (isTopMenuOpen ? styles.clickable : "")
        }
        onClick={() => {
          menuContext.removeMenu({ category: MenuCategory.TopMenu, menuId: null });
        }}
      >
        <Routes>
          <Route path="/logout" element={<Logout />} />
          <Route path="/search" element={<SearchView />} />
          <Route path="/favourites" element={<FavouritesView />} />
          <Route path="/report/:reportLink" element={<ReportView />} />
          <Route path="/masterdata/esaprojects" element={<ESAProjectsView />} />
          <Route path="/masterdata/esaprojectrelations" element={<ESAProjectRelationsView />} />
          <Route path="/masterdata/abbreviation" element={<AbbreviationView />} />
          <Route path="/masterdata/feedback" element={<FeedbackView />} />
          <Route path="/plan" element={<PlanAndSimulate />} />
          <Route path="" element={<Navigate to={`/report/${defaultPage?.value}`} />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
};