import React, { HTMLAttributes } from "react";
import styles from "./Icon.module.scss";
import { InternalIcon } from "./InternalIcon";
import { IconType } from "../../models/application/icon/IconType";

interface IconProps extends HTMLAttributes<any> {
  iconType: IconType;
  iconName: string;
}

export const Icon: React.FunctionComponent<IconProps> = (props) => {
  let icon;

  if (props.iconType === IconType.MaterialUI) {
    // Destructure props to avoid DOM errors
    const { iconType, iconName, ...restProps } = props;

    icon = (
      <div {...restProps} className={props.className + " " + styles.materialUiWrapper}>
        <span className={`mdi mdi-${props.iconName} ${styles.materialUiIcon}`} />
      </div>
    );
  } else if (props.iconType === IconType.InternalUI) {
    // Destructure props to avoid DOM errors
    const { iconType, ...restProps } = props;

    icon = <InternalIcon {...restProps} className={props.className + " " + styles.internalIcon} />;
  }

  return <>{icon}</>;
};