import React from "react";
import styles from "./SelectedFilter.module.scss";
import { Icon } from "../../../../parts/icons/Icon";
import { useSearchParams } from "react-router-dom";
import { FilterItemPacket } from "../../../../models/report data/filter/FilterItemPacket";
import { IconType } from "../../../../models/application/icon/IconType";
import { FilterConfig } from "../../../../models/report data/filter/FilterConfig";

interface SelectedFilterProps {
  filter: FilterItemPacket;
  filterConfig: FilterConfig | undefined;

  parameterName: string;
}

export const SelectedFilter: React.FunctionComponent<SelectedFilterProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeFilter = () => {
    if (props.filterConfig?.locked) {
      return;
    }

    searchParams.delete(props.parameterName);
    setSearchParams(searchParams);
  };

  return (
    <li className={styles.navItem}>
      <div className={styles.textBox} onClick={removeFilter}>
        <label className={styles.text + " " + (props.filterConfig?.locked ? styles.locked : "")}>
          {props.filter.name}
        </label>

        {!props.filterConfig?.locked && (
          <Icon iconName={"close"} iconType={IconType.MaterialUI} className={styles.icon} />
        )}
      </div>
    </li>
  );
};