import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { v4 as uuid } from "uuid";

import { useAbbreviations } from "../../../hooks/master data/abbreviation/useAbbreviations";
import { HttpConstants } from "../../../models/http communication/HttpConstants";
import { Abbreviation } from "../../../models/master data/abbreviation/abbreviation";
import { EditorSectionPacket } from "../../../models/master data/generics/EditorSection";
import { FieldType } from "../../../models/master data/generics/FieldType";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";
import { AbbreviationProviderFactory } from "../../../providers/master data/abbreviation/AbbreviationProvider";
import { FunctionNotAuthorized } from "../../identity/authorization/FunctionNotAuthorized";
import styles from "../generic master data/views/GenericMasterDataEditor.module.scss";
import { GenericMasterDataEditorView } from "../generic master data/views/GenericMasterDataEditorView";
import { MsalService } from "../../../services/identity/MsalService";
import { useRights } from "../../../hooks/identity/authorization/useRights";
import { RightConstants } from "../../../models/identity/RightConstants";

export const AbbreviationView: React.FunctionComponent = () => {
  const msalInstance = MsalService.getAuthenticationInstance();
  const account = msalInstance.getActiveAccount();

  const { rights, isLoadingRights } = useRights(account?.username ?? "");

  const { abbreviations, abbreviationsIsLoading, abbreviationsState } = useAbbreviations();
  const abbreviationProvider = AbbreviationProviderFactory.getAbbreviationProvider();

  const columnDefinitions: ColumnDef<Abbreviation>[] = [
    {
      accessorKey: "code",
      cell: (info) => info.getValue(),
      header: "Code",
      sortingFn: "text",
    },
    {
      accessorKey: "definition",
      cell: (info) => info.getValue(),
      header: "Definition",
    },
    {
      accessorKey: "relatedReference",
      cell: (info) => info.getValue(),
      header: "Related Reference",
    },
    {
      accessorKey: "link",
      cell: (info) => info.getValue(),
      header: "Link",
    },
  ];

  const sectionDefinitions: EditorSectionPacket<Abbreviation>[] = [
    {
      id: 1,
      heading: null,
      properties: [
        {
          propertyName: "code",
          displayName: "Code",
          type: FieldType.Text,
        },
        {
          propertyName: "definition",
          displayName: "Definition",
          type: FieldType.Text,
        },
      ],
    },
    {
      id: 2,
      heading: null,
      properties: [
        {
          propertyName: "relatedReference",
          displayName: "Related Reference",
          type: FieldType.Text,
        },
        {
          propertyName: "link",
          displayName: "Link",
          type: FieldType.Text,
        },
      ],
    },
  ];

  const createAbbreviation = () => {
    const newAbbreviaton: Abbreviation = {
      id: uuid(),
      code: "",
      definition: "",
      relatedReference: "",
      link: "",
    };

    return newAbbreviaton;
  };

  const createRecord = rights.find((x) => x === RightConstants.EditMasterData)
    ? () => createAbbreviation()
    : null;
  const saveRecord = rights.find((x) => x === RightConstants.EditMasterData)
    ? (abbreviation: Abbreviation) => abbreviationProvider.createAbbreviation(abbreviation)
    : null;
  const updateRecord = rights.find((x) => x === RightConstants.EditMasterData)
    ? (abbreviation: Abbreviation) => abbreviationProvider.updateAbbreviation(abbreviation)
    : null;
  const deleteRecord = rights.find((x) => x === RightConstants.DeleteMasterData)
    ? (id: string) => abbreviationProvider.deleteAbbreviation(id)
    : null;

  return (
    <div className={styles.container}>
      {abbreviationsIsLoading ? (
        <FullscreenPropagateLoader />
      ) : abbreviationsState === HttpConstants.Unauthorized ? (
        <FunctionNotAuthorized />
      ) : (
        <GenericMasterDataEditorView
          name={"Abbreviation"}
          columnDefinitions={columnDefinitions}
          sectionDefinitions={sectionDefinitions}
          dataSource={structuredClone(abbreviations)}
          getRecord={(id: string) => abbreviationProvider.getAbbreviation(id)}
          createRecord={createRecord}
          saveRecord={saveRecord}
          updateRecord={updateRecord}
          deleteRecord={deleteRecord}
          getRecordDisplayName={(record) => record.code}
        />
      )}
    </div>
  );
};