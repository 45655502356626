import React, { createContext } from "react";
import { Report } from "report";
import { ReportService } from "../../../services/report data/report/ReportService";
import { ReportPacket } from "../../../models/report data/report/ReportPacket";

export interface ReportContextType {
  reportLink: string | undefined;
  setReportLink: React.Dispatch<React.SetStateAction<string | undefined>>;

  reportPacket: ReportPacket | null;
  isDownloadingReportPacket: boolean;

  report: Report | null;
  setReport: React.Dispatch<React.SetStateAction<Report | null>>;

  reportLoaded: number;
  setReportLoaded: React.Dispatch<React.SetStateAction<number>>;

  reportService: ReportService;
}

export const ReportContext = createContext<ReportContextType>({
  reportLink: undefined,
  setReportLink: () => {},

  reportPacket: null,
  isDownloadingReportPacket: false,

  report: null,
  setReport: () => {},

  reportLoaded: 0,
  setReportLoaded: () => {},

  reportService: null!, // will be initialized before providing. ReportService can never be null
});