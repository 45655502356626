import { PropagateLoader } from "react-spinners";
import React from "react";
import styles from "./Loader.module.scss";

export const FullscreenPropagateLoader: React.FunctionComponent = (props) => {
  const color = getComputedStyle(document.documentElement).getPropertyValue("--fontColor");

  return (
    <div className={styles.container}>
      <PropagateLoader color={color} />
    </div>
  );
};