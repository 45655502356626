import React, { useEffect } from "react";
import { MsalService } from "../../../services/identity/MsalService";

export const Login: React.FunctionComponent = () => {
  const msalInstance = MsalService.getAuthenticationInstance();

  const signIn = async () => {
    await msalInstance.loginRedirect();
  };

  useEffect(() => {
    signIn();
  }, []);

  return (
    <div>
      If you can see this message, there was an error with your login. Please refresh the page. If
      you continue to see this message please login with your account on another page. Otherwise
      contract your system administrator.
    </div>
  );
};