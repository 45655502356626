import { useEffect, useState } from "react";
import { ReportProviderFactory } from "../../../providers/report data/report/ReportProvider";
import { IReportProvider } from "../../../providers/report data/report/IReportProvider";
import { ReportPacket } from "../../../models/report data/report/ReportPacket";

export function useReport(reportId: string | undefined) {
  const [reportPacket, setReportPacket] = useState<ReportPacket | null>(null);
  const [isDownloadingReportPacket, setIsDownloadingReportPacket] = useState<boolean>(true);

  useEffect(() => {
    const reportProvider: IReportProvider = ReportProviderFactory.getReportProvider();

    async function getReport() {
      setIsDownloadingReportPacket(true);

      if (reportId === undefined) {
        setIsDownloadingReportPacket(false);
        setReportPacket(null);
        return;
      }

      try {
        const report = await reportProvider.getReport(reportId);
        setIsDownloadingReportPacket(false);
        setReportPacket(report);
      } catch {
        setIsDownloadingReportPacket(false);
        setReportPacket(null);
      }
    }

    getReport();
  }, [reportId]);

  return { reportPacket, isDownloadingReportPacket };
}