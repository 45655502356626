import { ThemeItem } from "../../../models/application/theme/ThemeItem";
import PowerBIDefaultTheme from "../../../shared/powerbi/themes/ESA.json";
import PowerBILightTheme from "../../../shared/powerbi/themes/Light.json";
import PowerBIDarkTheme from "../../../shared/powerbi/themes/Dark.json";
import { ReportContextType } from "../../../contexts/report data/report/ReportContext";
import { models } from "powerbi-client";

export class ThemeService {
  private readonly THEME_STORAGE_NAME = "theme";

  private readonly themes: ThemeItem[];
  private currentTheme: ThemeItem | null = null;

  private readonly reportContext: ReportContextType;

  constructor(themes: ThemeItem[], reportContext: ReportContextType) {
    this.themes = themes;
    this.reportContext = reportContext;
  }

  public setTheme(theme: ThemeItem) {
    this.currentTheme = theme;
    this.setPowerBITheme();

    const style = document.documentElement.style;

    this.setRGBColor(theme.primaryColor, "primaryColor");
    this.setRGBColor(theme.secondaryColor, "secondaryColor");
    this.setRGBColor(theme.trimColor, "trimColor");

    this.setRGBColor(theme.fontColor, "fontColor");
    style.setProperty("--logoColor", theme.logoColor);

    this.setRGBColor(theme.mdmBackgroundColor, "mdm_BackgroundColor");
    this.setRGBColor(theme.mdmCardColor, "mdm_CardColor");

    this.setRGBColor(theme.mdmTextColor, "mdm_TextColor");
    this.setRGBColor(theme.mdmTextColorHighlighted, "mdm_TextColor_Highlighted");
    this.setRGBColor(theme.mdmTextColorDisabled, "mdm_TextColor_Disabled");

    this.setRGBColor(theme.mdmHeadingTextColor, "mdm_Heading_TextColor");

    this.setRGBColor(theme.mdmInputTextColor, "mdm_Input_TextColor");
    this.setRGBColor(theme.mdmInputBackgroundColor, "mdm_Input_BackgroundColor");
    this.setRGBColor(theme.mdmInputSublabelColor, "mdm_Input_SublabelColor");

    this.setRGBColor(theme.mdmSelectTextColor, "mdm_Select_TextColor");
    this.setRGBColor(theme.mdmSelectBackgroundColor, "mdm_Select_BackgroundColor");
    this.setRGBColor(theme.mdmSelectHoverColor, "mdm_Select_HoverColor");

    this.setRGBColor(theme.mdmOptionBorderColor, "mdm_Option_BorderColor");
    this.setRGBColor(theme.mdmOptionBackgroundColor, "mdm_Option_BackgroundColor");

    this.saveTheme();
  }

  public saveTheme() {
    if (this.currentTheme == null) {
      return;
    }

    localStorage.setItem(this.THEME_STORAGE_NAME, this.currentTheme.id);
  }

  public loadTheme() {
    const themeId = localStorage.getItem(this.THEME_STORAGE_NAME);

    if (themeId === null) {
      return;
    }

    const theme = this.themes.filter((x) => x.id.toString() === themeId)[0];

    if (theme == null) {
      return;
    }

    this.setTheme(theme);
  }

  public getThemes() {
    return this.themes;
  }

  public getPowerBITheme() {
    // TODO: Implement better way of handling theme loads

    let theme: models.IReportTheme = PowerBIDefaultTheme;

    if (this.currentTheme?.name.toLowerCase().includes("dark")) {
      theme = PowerBIDarkTheme;
    } else if (this.currentTheme?.name.toLowerCase().includes("light")) {
      theme = PowerBILightTheme;
    }

    return theme;
  }

  private setRGBColor(color: string, name: string) {
    const style = document.documentElement.style;

    let colorRGB = this.hexToRgb(color);
    style.setProperty(`--${name}`, color);
    style.setProperty(`--${name}_red`, colorRGB?.r.toString()!);
    style.setProperty(`--${name}_green`, colorRGB?.g.toString()!);
    style.setProperty(`--${name}_blue`, colorRGB?.b.toString()!);
  }

  private setPowerBITheme() {
    const theme = this.getPowerBITheme();
    this.reportContext.report?.applyTheme({ themeJson: theme }).then(
      () => {},
      () => {},
    );
  }

  private hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}