import { useEffect, useState } from "react";
import { ESAProjectPacket } from "../../../models/master data/esa project/ESAProjectPacket";
import { IESAProjectProvider } from "../../../providers/master data/esa project/IESAProjectProvider";
import { ESAProjectProviderFactory } from "../../../providers/master data/esa project/ESAProjectProvider";
import { HttpConstants, HttpHelper } from "../../../models/http communication/HttpConstants";

export const useESAProjects = (wbs: boolean = false, pomi: boolean = false) => {
  const [esaProjects, setEsaProjects] = useState<ESAProjectPacket[]>([]);
  const [esaProjectsIsLoading, setEsaProjectsIsLoading] = useState<boolean>(false);
  const [esaProjectsState, setEsaProjectsState] = useState<HttpConstants>(HttpConstants.Ok);

  useEffect(() => {
    setEsaProjectsIsLoading(true);

    const spaceProjectProvider: IESAProjectProvider =
      ESAProjectProviderFactory.getESAProjectProvider();

    async function getSpaceProjects() {
      try {
        const spaceProjects = await spaceProjectProvider.getAllESAProjects(wbs, pomi);
        setEsaProjectsState(HttpConstants.Ok);
        setEsaProjects(spaceProjects);
      } catch (e: any) {
        HttpHelper.handleError(e, setEsaProjectsState);
        setEsaProjects([]);
      }

      setEsaProjectsIsLoading(false);
    }

    getSpaceProjects();
  }, []);

  return { esaProjects, esaProjectsIsLoading, esaProjectsState };
};