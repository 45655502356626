import * as React from "react";
const SvgBuilding = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 90 136"
    {...props}
  >
    <defs>
      <clipPath id="building_svg__a">
        <path d="M1408 921h90v136h-90z" />
      </clipPath>
    </defs>
    <g clipPath="url(#building_svg__a)" transform="translate(-1408 -921)">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M1480.32 958.238h-9.64v-9.714h9.64v9.714Zm0 25.905h-9.64v-9.714h9.64v9.714Zm0 25.907h-9.64v-9.72h9.64v9.72Zm0 25.9h-9.64v-9.71h9.64v9.71Zm-22.5-77.712h-9.64v-9.714h9.64v9.714Zm0 25.905h-9.64v-9.714h9.64v9.714Zm0 25.907h-9.64v-9.72h9.64v9.72Zm0 35.62h-9.64v-19.43h9.64v19.43Zm-22.5-87.432h-9.64v-9.714h9.64v9.714Zm0 25.905h-9.64v-9.714h9.64v9.714Zm0 25.907h-9.64v-9.72h9.64v9.72Zm0 25.9h-9.64v-9.71h9.64v9.71Zm56.25 9.72V937.19h-4.82v-9.714h-4.82V921h-57.86v6.476h-4.82v9.714h-4.82v108.48H1408V1057h90v-11.33h-6.43Z"
      />
    </g>
  </svg>
);
export default SvgBuilding;
