import { useEffect, useState } from "react";

import { SearchResultItem } from "../../../models/application/search/SearchResultItem";
import { ISearchProvider } from "../../../providers/application data/search/ISearchProvider";
import { SearchProviderFactory } from "../../../providers/application data/search/SearchProvider";

export function useSearchResults(searchQuery: string) {
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);

  useEffect(() => {
    const searchProvider: ISearchProvider = SearchProviderFactory.getSearchProvider();

    async function getSearchResults() {
      try {
        if (searchQuery.length > 2) {
          const results = await searchProvider.getSearchResult(searchQuery);
          setSearchResults(results);
        } else {
          setSearchResults([]);  
        }
      } catch {
        setSearchResults([]);
      }
    }

    getSearchResults();
  }, [searchQuery]);

  return { searchResults };
}