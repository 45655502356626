import React, { PropsWithChildren, useContext } from "react";
import { TriggerContext } from "./TriggerContext";
import { TriggerService } from "../../../services/report data/trigger/TriggerService";
import { useSearchParams } from "react-router-dom";
import { ReportContext } from "../report/ReportContext";
import { FilterContext } from "../filter/FilterContext";

export const TriggerContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const reportContext = useContext(ReportContext);
  const filterContext = useContext(FilterContext);

  const triggerService = new TriggerService(
    reportContext,
    filterContext,
    searchParams,
    setSearchParams,
  );

  return (
    <TriggerContext.Provider value={{ triggerService }}>{props.children}</TriggerContext.Provider>
  );
};