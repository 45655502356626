import { useEffect, useState } from "react";
import { IFilterProvider } from "../../../providers/report data/filter/IFilterProvider";
import { FilterProviderFactory } from "../../../providers/report data/filter/FilterProvider";
import { FilterItemPacket } from "../../../models/report data/filter/FilterItemPacket";

export const useFilters = (reportId: string | undefined, year: number) => {
  const [filters, setFilters] = useState<FilterItemPacket[]>([]);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false);

  useEffect(() => {
    const filterProvider: IFilterProvider = FilterProviderFactory.getFilterProvider();

    async function getFilters() {
      setIsLoadingFilters(true);

      if (reportId === undefined) {
        setFilters([]);
        setIsLoadingFilters(false);
        return;
      }

      try {
        const filters = await filterProvider.getReportFilters(reportId, year);
        setFilters(filters);
        setIsLoadingFilters(false);
      } catch {
        setFilters([]);
        setIsLoadingFilters(false);
      }
    }

    getFilters();
  }, [reportId, year]);

  return { filters, isLoadingFilters };
};