import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.css";

import { ColumnDef } from "@tanstack/react-table";
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

import { ToastMessages } from "../../../../models/internationalization/ToastMessages";
import { EditorRecord } from "../../../../models/master data/generics/EditorRecord";
import { EditorSectionPacket } from "../../../../models/master data/generics/EditorSection";
import { compareObjects } from "../../../../services/other/CompareService";
import { GenericMasterDataEditor } from "../parts/GenericMasterDataEditor";
import { GenericMasterDataTable } from "../parts/GenericMasterDataTable";
import styles from "./GenericMasterDataEditor.module.scss";

export type GetRecordFunction<T> = (id: string) => Promise<T>;
export type CreateRecordFunction<T> = () => T;
export type SaveRecordFunction<T> = (record: T) => Promise<void>;
export type UpdateRecordFunction<T> = (record: T) => Promise<void>;
export type DeleteRecordFunction = (id: string) => Promise<void>;
export type GetRecordDisplayNameFunction<T> = (record: T) => string;

export type SelectInternalRecordFunction<T> = (record: T) => void;
export type CancelInternalRecordFunction = () => void;
export type CreateInternalRecordFunction = () => void;
export type SaveInternalRecordFunction<T> = (record: T) => void;
export type DeleteInternalRecordFunction<T> = (record: T) => void;

export interface GenericMasterDataViewProps<T extends EditorRecord> {
  name: string;
  columnDefinitions: ColumnDef<T>[];
  sectionDefinitions: EditorSectionPacket<T>[];

  dataSource: Array<T>;

  getRecord: GetRecordFunction<T>;
  createRecord: CreateRecordFunction<T> | null;
  saveRecord: SaveRecordFunction<T> | null;
  updateRecord: UpdateRecordFunction<T> | null;
  deleteRecord: DeleteRecordFunction | null;
  getRecordDisplayName: GetRecordDisplayNameFunction<T> | null;
}

export function GenericMasterDataEditorView<T extends EditorRecord>(
  props: GenericMasterDataViewProps<T>,
) {
  const [dataSource, setDataSource] = useState<T[]>(props.dataSource);
  const [selectedRecord, setSelectedRecord] = useState<T | null>(null);

  const selectRecord = (record: T) => {
    if (!record) {
      return;
    }

    setSelectedRecord(structuredClone(record));

    const yesCallback = (updatedRecord: T) => {
      const copyDataSource = structuredClone(dataSource);
      const index = copyDataSource.findIndex((x) => x.id === record.id);

      if (index > -1) {
        copyDataSource[index] = updatedRecord;
      }

      setDataSource(structuredClone(copyDataSource));
      setSelectedRecord(structuredClone(updatedRecord));
    };

    try {
      props.getRecord(record.id).then(
        (updatedRecord) => {
          if (!compareObjects(record, updatedRecord)) {
            toast(<UpdatedToast yesCallback={yesCallback} updatedRecord={updatedRecord} />, {
              autoClose: false,
            });
          }
        },
        () => {
          toast(ToastMessages.UnknownError);
        },
      );
    } catch {
      toast(ToastMessages.UnknownError);
    }
  };

  const cancelRecord = () => {
    setSelectedRecord(null);
  };

  const createRecord = props.createRecord
    ? () => {
        const newRecord = props.createRecord!();

        setSelectedRecord(newRecord);
      }
    : null;

  const saveRecord =
    props.saveRecord && props.createRecord
      ? async (record: T) => {
          if (!record) {
            return;
          }

          const copyDataSource = structuredClone(dataSource);
          const index = copyDataSource.findIndex((x) => x.id === record.id);

          try {
            if (index !== -1) {
              await props.updateRecord!(record);
              copyDataSource[index] = record;
            } else {
              await props.saveRecord!(record);
              copyDataSource.push(record);
            }

            setDataSource(structuredClone(copyDataSource));
            setSelectedRecord(null);

            toast(ToastMessages.Saved(`${props.name} ${record.displayName ?? ""}`));
          } catch {
            setSelectedRecord(null);

            toast(ToastMessages.UnknownError);
          }
        }
      : null;

  const confirmDeleteRecord =
    props.deleteRecord && props.getRecordDisplayName
      ? (record: T) => {
          confirmAlert({
            title: "Confirm to delete",
            message: `Do you really want to delete ${props.getRecordDisplayName!(record)}?`,
            buttons: [
              {
                label: "Yes",
                onClick: () => deleteRecord(record),
              },
              {
                label: "No",
              },
            ],
            overlayClassName: "confirmDeleteOverlay",
          });
        }
      : null;

  const deleteRecord = (record: T) => {
    if (!record) {
      return;
    }

    const copyDataSource = structuredClone(dataSource);
    const index = copyDataSource.findIndex((x) => x.id === record.id);

    try {
      if (index !== -1) {
        copyDataSource.splice(index, 1);
        setDataSource(structuredClone(copyDataSource));

        props.deleteRecord!(record.id).then(
          () => {
            toast(ToastMessages.Deleted(`${props.name} ${record.displayName ?? ""}`));
          },
          () => {
            copyDataSource.push(record);
            setDataSource(structuredClone(copyDataSource));

            setSelectedRecord(null);

            toast(ToastMessages.UnknownError);
          },
        );

        setSelectedRecord(null);
      }
    } catch {
      copyDataSource.push(record);
      setDataSource(structuredClone(copyDataSource));

      setSelectedRecord(null);

      toast(ToastMessages.UnknownError);
    }
  };

  return (
    <div className={styles.masterDataView}>
      <div>
        <GenericMasterDataTable
          name={props.name}
          columnDefinitions={props.columnDefinitions}
          dataSource={structuredClone(dataSource)}
          selectedRecord={structuredClone(selectedRecord)}
          selectRecord={selectRecord}
          createRecord={createRecord}
          deleteRecord={confirmDeleteRecord}
        />
      </div>

      {selectedRecord && (
        <GenericMasterDataEditor
          sectionDefintions={props.sectionDefinitions}
          record={structuredClone(selectedRecord)}
          cancelRecord={cancelRecord}
          saveRecord={saveRecord}
        />
      )}
    </div>
  );
}

type UpdateToastParams<T> = {
  yesCallback: Function;

  updatedRecord: T;
};

function UpdatedToast<T>(params: UpdateToastParams<T>) {
  return (
    <div className={styles.message}>
      <span>There are new information available. Do you want to load them?</span>
      <div className={styles.buttonContainer}>
        <button onClick={() => params.yesCallback(params.updatedRecord)}>Yes</button>
        <button>No</button>
      </div>
    </div>
  );
}