import axios from "axios";
import { IApiRequestHandler } from "./IApiRequestHandler";
import { EnvironmentVariablesModel } from "../../models/configuration/EnvironmentVariablesModel";
import { AppConfigManager } from "../../models/configuration/AppConfigManager";
import { MsalService } from "../../services/identity/MsalService";
import { AuthError } from "@azure/msal-browser";

export class BaseApiRequestHandler implements IApiRequestHandler {
  private readonly backOffTime: number = 250;
  private readonly maxRetries: number = 5;
  private readonly environmentVariables: EnvironmentVariablesModel;

  public constructor() {
    this.environmentVariables = AppConfigManager.getEnvironmentVariables();

    axios.defaults.baseURL = this.environmentVariables.apiUrl;
    axios.interceptors.request.use(
      async (config) => {
        config.headers.Authorization = await this.getBearer();
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  public async get(url: string, retries: number = this.maxRetries): Promise<any> {
    if (retries < 0) {
      throw new Error("Max retries exceeded");
    }

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error instanceof AuthError) {
        if (error.errorCode === "interaction_in_progress") {
          await this.sleep(this.backOffTime);
          return await this.get(url, retries - 1);
        }
      } else {
        throw error;
      }
    }
  }

  public async post(url: string, data: any, retries: number = this.maxRetries): Promise<any> {
    if (retries < 0) {
      throw new Error("Max retries exceeded");
    }

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });

      return response.data;
    } catch (error: any) {
      if (error instanceof AuthError) {
        if (error.errorCode === "interaction_in_progress") {
          await this.sleep(this.backOffTime);
          return await this.get(url, retries - 1);
        }
      } else {
        throw error;
      }
    }
  }

  public async put(url: string, data: any, retries: number = this.maxRetries): Promise<any> {
    if (retries < 0) {
      throw new Error("Max retries exceeded");
    }

    try {
      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });

      return response.data;
    } catch (error: any) {
      if (error instanceof AuthError) {
        if (error.errorCode === "interaction_in_progress") {
          await this.sleep(this.backOffTime);
          return await this.get(url, retries - 1);
        }
      } else {
        throw error;
      }
    }
  }

  public async delete(url: string, retries: number = this.maxRetries): Promise<any> {
    if (retries < 0) {
      throw new Error("Max retries exceeded");
    }

    try {
      const response = await axios.delete(url);

      return response.data;
    } catch (error: any) {
      if (error instanceof AuthError) {
        if (error.errorCode === "interaction_in_progress") {
          await this.sleep(this.backOffTime);
          return await this.get(url, retries - 1);
        }
      } else {
        throw error;
      }
    }
  }

  private readonly sleep = (sleepTime: number) =>
    new Promise((resolve) => setTimeout(resolve, sleepTime));

  private async getBearer(): Promise<string> {
    const msalInstance = MsalService.getAuthenticationInstance();
    const account = msalInstance.getActiveAccount();

    if (!account) {
      throw Error("No active account!");
    }

    const tokenResponse = await MsalService.getAccessToken();
    return `Bearer ${tokenResponse.accessToken}`;
  }
}