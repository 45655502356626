import { TextService } from "../../other/TextService";
import { FilterItemPacket } from "../../../models/report data/filter/FilterItemPacket";
import { FilterDataOperator } from "../../../models/report data/filter/FilterDataOperator";
import { FilterDataConditionType } from "../../../models/report data/filter/FilterDataConditionType";
import { FilterDataPacket } from "../../../models/report data/filter/FilterDataPacket";
import { FilterDataConditionDataType } from "../../../models/report data/filter/FilterDataConditionDataType";

export class FilterService {
  public static structureFilters(filters: FilterItemPacket[]): FilterItemPacket[] {
    const structuredFilters: FilterItemPacket[] = [];
    const filterMap: { [key: string]: FilterItemPacket } = {};

    for (const filter of filters) {
      filter.children = [];
      filterMap[filter.originId] = filter;
    }

    for (const filter of filters) {
      if (!filter.parentOriginId) {
        structuredFilters.push(filter);
        continue;
      }

      const parentFilter = filterMap[filter.parentOriginId];

      if (parentFilter) {
        filter.parent = parentFilter;
        parentFilter.children?.push(filter);
      }
    }

    return structuredFilters;
  }

  public static sortFilters(filters: FilterItemPacket[]): FilterItemPacket[] {
    return filters.sort((a, b) => a.order - b.order);
  }

  public static flattenFilters(filters: FilterItemPacket[]): FilterItemPacket[] {
    return filters.reduce((acc: FilterItemPacket[], filter: FilterItemPacket) => {
      acc.push(filter);
      if (filter.children && filter.children.length > 0) {
        acc.push(...FilterService.flattenFilters(filter.children));
      }
      return acc;
    }, []);
  }

  public static reduceFilters(filters: FilterItemPacket[], filterCode: string | undefined) {
    if (!filterCode) {
      return filters;
    }

    const flattenedFilters = FilterService.flattenFilters(filters);
    const reducedFilter = flattenedFilters.find((x) => x.originCode === filterCode);

    return reducedFilter ? [reducedFilter] : [];
  }

  public static wrapFilters(filters: FilterItemPacket[]) {
    let clonedFilters = filters.map((x) => Object.assign({}, x));

    const masterFilter: FilterItemPacket[] = [
      {
        originId: "-1",
        originCode: "-1",
        name: "Filters",
        children: clonedFilters,
        order: 1,
        visible: true,
      },
    ];

    clonedFilters.forEach((x) => {
      x.parentOriginId = masterFilter[0].originId;
      x.parent = masterFilter[0];
    });

    return masterFilter;
  }

  public static getTableSortedFilterData(filter: FilterItemPacket) {
    const filterData: FilterDataPacket[] = [];

    const flattedFilterData = FilterService.flattenFilters([filter])
      .map((x) => x.filterData)
      .flat();

    flattedFilterData.forEach((filter) => {
      if (filter === undefined) {
        return;
      }

      const foundFilter = filterData.find(
        (x) =>
          x.table === filter.table &&
          x.column === filter.column &&
          x.type === filter.type &&
          x.operator === filter.operator,
      );

      if (foundFilter === undefined) {
        filterData.push(filter);
      } else {
        foundFilter.conditions.push(...filter.conditions);
      }
    });

    return filterData;
  }

  public static getRemovableFilters(filters: FilterItemPacket[]) {
    const removableFilters: FilterItemPacket[] = [];

    filters.forEach((filter) => {
      filter.filterData?.forEach((filterData) => {
        if (
          removableFilters.find(
            (x) =>
              x.filterData![0].table === filterData.table &&
              x.filterData![0].column === filterData.column,
          ) === undefined
        ) {
          removableFilters.push(FilterService.createFilter(filterData));
        }
      });
    });

    return removableFilters;
  }

  public static getWidestFilterWidth(filters: FilterItemPacket[]) {
    let maxWidth = 0;

    filters.forEach((filter) => {
      const width = TextService.getTextWidth(
        filter.name,
        TextService.getCanvasFont(document.body, null, "16px"), // Get this value from current font size
      );

      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    return maxWidth;
  }

  public static getCategory(filters: FilterItemPacket[], filter: FilterItemPacket) {
    if (filter.parentOriginId === undefined) {
      return filter;
    }

    let current = filter;

    while (current.parentOriginId !== undefined) {
      const foundFilter = filters.find((x) => x.originId === current.parentOriginId);

      if (foundFilter === undefined) {
        break;
      }

      current = foundFilter;
    }

    return current;
  }

  private static createFilter(filterData: FilterDataPacket) {
    const createdFilter: FilterItemPacket = {
      name: "",
      order: 0,
      originCode: "",
      originId: "",
      visible: true,
      filterData: [
        {
          table: filterData.table,
          column: filterData.column,
          type: filterData.type,
          operator: FilterDataOperator.And,
          conditions: [
            {
              type: FilterDataConditionType.in,
              dataType: FilterDataConditionDataType.text,
              value: "",
            },
          ],
        },
      ],
    };

    return createdFilter;
  }
}