import React, { HTMLAttributes, useRef } from "react";
import styles from "./MobileMainMenu.module.scss";
import sharedStyles from "./MobileMenuShared.module.scss";
import { MenuGroupPacket } from "../../../../models/application/menu/MenuGroupPacket";
import { MenuEntryPacket } from "../../../../models/application/menu/MenuEntryPacket";
import { MenuEntryType } from "../../../../models/application/menu/MenuEntryType";
import { Icon } from "../../../../parts/icons/Icon";
import { IconType } from "../../../../models/application/icon/IconType";
import { Link, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./MobileMenuSharedAnimation.scss";

interface MobileMainMenuViewProps extends HTMLAttributes<any> {
  selectedMenu: string;
  setSelectedMenu: Function;

  groups: MenuGroupPacket[];
}

export const MobileMainMenu: React.FunctionComponent<MobileMainMenuViewProps> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const menu = "MAIN";

  return (
    <CSSTransition
      nodeRef={menuRef}
      classNames={"mobile-menu-internal-animation"}
      in={menu === props.selectedMenu}
      unmountOnExit
      timeout={300}
    >
      <div ref={menuRef}>
        {props.groups.map((group) => (
          <div key={group.id} className={styles.menuGroup}>
            {group.entries.map((entry) => (
              <MenuEntry key={entry.id} entry={entry} setSelectedMenu={props.setSelectedMenu} />
            ))}
          </div>
        ))}
      </div>
    </CSSTransition>
  );
};

type MenuEntryParams = {
  entry: MenuEntryPacket;
  setSelectedMenu: Function;
};
const MenuEntry = (params: MenuEntryParams) => {
  switch (params.entry.type) {
    case MenuEntryType.Container:
      return <ContainerMenuEntry {...params} />;
    case MenuEntryType.OpenMenu:
      return <></>;
    case MenuEntryType.ToggleMenu:
      return <></>;
    case MenuEntryType.Link:
      return <LinkEntry {...params} />;

    default:
      return <></>;
  }
};

const ContainerMenuEntry = (params: MenuEntryParams) => {
  const location = useLocation();

  const groups = params.entry.groups ?? [];

  const isActive =
    groups.find((x) => x.entries.find((y) => y.link?.link === location.pathname)) !== undefined;

  return (
    <div className={styles.menuItemContainer}>
      <div
        className={`${sharedStyles.menuItem} ${styles.menuItem} ${
          isActive ? sharedStyles.active : ""
        }`}
        onClick={() => params.setSelectedMenu(`ENTRY${params.entry.id}`)}
      >
        <Icon
          className={sharedStyles.menuIcon}
          iconType={params.entry.iconType}
          iconName={params.entry.icon}
        />
        <label>{params.entry.label}</label>

        <div className={sharedStyles.end}>
          <Icon
            className={sharedStyles.navigationIcon}
            iconType={IconType.MaterialUI}
            iconName={"greater-than"}
          />
        </div>
      </div>
    </div>
  );
};

const LinkEntry = (params: MenuEntryParams) => {
  const location = useLocation();

  if (!params.entry.link) {
    return <></>;
  }

  const isActive = location.pathname === params.entry.link.link;

  return (
    <div className={styles.menuItemContainer}>
      <Link
        to={{ pathname: params.entry.link.link, search: location.search }}
        className={`${sharedStyles.menuItem} ${styles.menuItem} ${
          isActive ? sharedStyles.active : ""
        }`}
      >
        <Icon
          className={sharedStyles.menuIcon}
          iconType={params.entry.iconType}
          iconName={params.entry.icon}
        />
        <label>{params.entry.label}</label>
      </Link>
    </div>
  );
};