import { IApiRequestHandler } from "./IApiRequestHandler";

export abstract class BaseApiDataProvider {
  private requestHandler: IApiRequestHandler;

  public constructor(requestHandler: IApiRequestHandler) {
    this.requestHandler = requestHandler;
  }

  protected async get(url: string): Promise<any> {
    return this.requestHandler.get(url);
  }

  protected async post(url: string, data: any): Promise<any> {
    return this.requestHandler.post(url, data);
  }

  protected async put(url: string, data: any): Promise<any> {
    return this.requestHandler.put(url, data);
  }

  protected async delete(url: string): Promise<any> {
    return this.requestHandler.delete(url);
  }
}