import * as React from "react";
const SvgFactory = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 110 110"
    {...props}
  >
    <defs>
      <clipPath id="factory_svg__a">
        <path d="M1088 1162h110v110h-110z" />
      </clipPath>
    </defs>
    <g fill="#FFF" clipPath="url(#factory_svg__a)" transform="translate(-1088 -1162)">
      <path d="M1185.06 1260.68h-19.41v-14.56h19.41v14.56Zm-32.35 0h-19.42v-14.56h19.42v14.56Zm-32.36 0h-19.41v-14.56h19.41v14.56Zm33.97-32.36v-22.64l-43.67 22.64-4.86-66.32h-12.94l-4.85 66.32V1272h110v-66.32l-43.68 22.64Z" />
    </g>
  </svg>
);
export default SvgFactory;
