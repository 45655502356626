import { EditorRecord } from "../../../../models/master data/generics/EditorRecord";
import styles from "./GenericMasterDataEditor.module.scss";
import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  DeleteRecordFunction,
  GenericMasterDataEditorView,
  GetRecordDisplayNameFunction,
  GetRecordFunction,
  SaveRecordFunction,
  UpdateRecordFunction,
} from "./GenericMasterDataEditorView";
import { toast } from "react-toastify";
import { GenericMasterDataTable } from "../parts/GenericMasterDataTable";
import { EditorSectionPacket } from "../../../../models/master data/generics/EditorSection";
import { compareObjects } from "../../../../services/other/CompareService";
import { ToastMessages } from "../../../../models/internationalization/ToastMessages";

interface GenericMasterDataSubinformationViewProps<
  T extends EditorRecord,
  TSub extends EditorRecord,
> {
  name: string;
  columnDefinitions: ColumnDef<T>[];

  dataSource: Array<T>;

  subTables: SubTableProps<T, TSub>[];

  getRecord: GetRecordFunction<T>;
}

type TypeKeys<T> = keyof T;

export type DependendCreateRecordFunction<T, TSub> = (record: T) => TSub;

export interface SubTableProps<T extends EditorRecord, TSub extends EditorRecord> {
  name: string;
  columnDefinitions: ColumnDef<TSub>[];
  sectionDefinitions: EditorSectionPacket<TSub>[];

  dataSourceName: TypeKeys<T>;

  getRecord: GetRecordFunction<TSub>;
  createRecord: DependendCreateRecordFunction<T, TSub> | null;
  saveRecord: SaveRecordFunction<TSub> | null;
  updateRecord: UpdateRecordFunction<TSub> | null;
  deleteRecord: DeleteRecordFunction | null;
  getRecordDisplayName: GetRecordDisplayNameFunction<TSub> | null;
}

export function GenericMasterDataSubinformationEditorView<
  T extends EditorRecord,
  TSub extends EditorRecord,
>(props: GenericMasterDataSubinformationViewProps<T, TSub>) {
  const [dataSource, setDataSource] = useState<T[]>(props.dataSource);
  const [selectedRecord, setSelectedRecord] = useState<T | null>(null);

  const selectRecord = (record: T) => {
    if (!record) {
      return;
    }

    setSelectedRecord(structuredClone(record));

    const yesCallback = (updatedRecord: T) => {
      const copyDataSource = structuredClone(dataSource);
      const index = copyDataSource.findIndex((x) => x.id === record.id);

      if (index > -1) {
        copyDataSource[index] = updatedRecord;
      }

      setDataSource(structuredClone(copyDataSource));
      setSelectedRecord(structuredClone(updatedRecord));
    };

    try {
      props.getRecord(record.id).then(
        (updatedRecord) => {
          if (!compareObjects(record, updatedRecord)) {
            toast(<UpdatedToast yesCallback={yesCallback} updatedRecord={updatedRecord} />, {
              autoClose: false,
            });
          }
        },
        () => {
          toast(ToastMessages.UnknownError);
        },
      );
    } catch {
      toast(ToastMessages.UnknownError);
    }
  };

  return (
    <div className={styles.masterDataView}>
      <div>
        <GenericMasterDataTable
          name={props.name}
          columnDefinitions={props.columnDefinitions}
          dataSource={structuredClone(dataSource)}
          selectedRecord={structuredClone(selectedRecord)}
          selectRecord={selectRecord}
          createRecord={null}
          deleteRecord={null}
        />
      </div>

      {selectedRecord && (
        <>
          {props.subTables.map((subtable) => {
            const createRecord = subtable.createRecord
              ? () => subtable.createRecord!(selectedRecord)
              : null;

            return (
              <GenericMasterDataEditorView
                key={JSON.stringify(selectedRecord) + subtable.dataSourceName.toString()}
                name={subtable.name}
                columnDefinitions={subtable.columnDefinitions}
                sectionDefinitions={subtable.sectionDefinitions}
                dataSource={structuredClone(selectedRecord[subtable.dataSourceName] as Array<TSub>)}
                getRecord={subtable.getRecord}
                createRecord={createRecord}
                saveRecord={subtable.saveRecord}
                updateRecord={subtable.updateRecord}
                deleteRecord={subtable.deleteRecord}
                getRecordDisplayName={subtable.getRecordDisplayName}
              />
            );
          })}
        </>
      )}
    </div>
  );
}

type UpdateToastParams<T> = {
  yesCallback: Function;

  updatedRecord: T;
};

function UpdatedToast<T>(params: UpdateToastParams<T>) {
  return (
    <div className={styles.message}>
      <span>There are new information available. Do you want to load them?</span>
      <div className={styles.buttonContainer}>
        <button onClick={() => params.yesCallback(params.updatedRecord)}>Yes</button>
        <button>No</button>
      </div>
    </div>
  );
}