import { IESAProjectProvider } from "./IESAProjectProvider";
import { ESAProjectPacket } from "../../../models/master data/esa project/ESAProjectPacket";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";

export class ESAProjectProvider extends BaseApiDataProvider implements IESAProjectProvider {
  public async getAllESAProjects(
    wbs: boolean = false,
    pomi: boolean = false,
  ): Promise<ESAProjectPacket[]> {
    return await this.get(`/esaproject?wbs=${wbs}&pomi=${pomi}`);
  }

  public async getESAProject(
    id: string,
    wbs: boolean = false,
    pomi: boolean = false,
  ): Promise<ESAProjectPacket> {
    return await this.get(`/esaproject/${id}?wbs=${wbs}&pomi=${pomi}`);
  }

  public async createESAProject(esaProject: ESAProjectPacket): Promise<void> {
    return await this.post(`/esaproject/${esaProject.id}`, esaProject);
  }

  public async updateESAProject(esaProject: ESAProjectPacket): Promise<void> {
    return await this.put(`/esaproject/${esaProject.id}`, esaProject);
  }

  public async deleteESAProject(id: string): Promise<void> {
    return await this.delete(`/esaproject/${id}`);
  }
}

export class ESAProjectProviderFactory {
  private static esaProjectProvider: IESAProjectProvider | null = null;

  public static getESAProjectProvider(): IESAProjectProvider {
    if (this.esaProjectProvider === null) {
      this.esaProjectProvider = new ESAProjectProvider(new BaseApiRequestHandler());
    }

    return this.esaProjectProvider;
  }
}