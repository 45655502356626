import React, { PropsWithChildren } from "react";
import { SettingsContext } from "./SettingsContext";
import { useSettings } from "../../../hooks/configuration/useSettings";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";

export const SettingsContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const { settings, loading } = useSettings();

  return (
    <SettingsContext.Provider
      value={{
        settings,
      }}
    >
      {loading && <FullscreenPropagateLoader />}

      {!loading && <>{props.children}</>}
    </SettingsContext.Provider>
  );
};