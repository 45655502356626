import React, { HTMLAttributes, useEffect, useRef } from "react";
import styles from "./MobileMenuView.module.scss";
import { CSSTransition } from "react-transition-group";
import "./MobileMenuSharedAnimation.scss";
import { useMenu } from "../../../../hooks/application/menu/useMenu";
import { MenuService } from "../../../../services/application data/menu/MenuService";
import { MobileMainMenu } from "./MobileMainMenu";
import { MobileSubMenu } from "./MobileSubMenu";
import { MobileBottomMenu } from "./MobileBottomMenu";

interface MobileTopBarMenuProps extends HTMLAttributes<any> {
  isOpen: boolean;
  closeMenu: Function;
}

export const MobileMenuView: React.FunctionComponent<MobileTopBarMenuProps> = (props) => {
  const { menuGroups } = useMenu();
  const sortedMenuGroups = MenuService.sortMenuGroups(menuGroups);
  const { topMenuGroups, bottomMenuGroups } = MenuService.splitMenuGroups(sortedMenuGroups);
  const flattedMenuGroups = MenuService.flattenMenuGroups(topMenuGroups);
  const parentGroupedMenuGroups = MenuService.parentGroupMenuGroups(flattedMenuGroups);

  const menuRef = useRef(null);

  const [selectedMenu, setSelectedMenu] = React.useState<string>("MAIN");

  useEffect(() => {
    if (!props.isOpen) {
      setSelectedMenu("MAIN");
    }
  }, [props.isOpen]);

  return (
    <CSSTransition
      nodeRef={menuRef}
      in={props.isOpen}
      timeout={300}
      unmountOnExit
      classNames={"mobile-menu-animation"}
    >
      <div ref={menuRef} className={styles.menuContainer}>
        <div className={styles.top}>
          <MobileMainMenu
            groups={topMenuGroups}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />

          {parentGroupedMenuGroups.map((groups) => (
            <MobileSubMenu
              key={groups.parent.id}
              parent={groups.parent}
              groups={groups.menuGroups}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
            />
          ))}
        </div>

        <MobileBottomMenu menuGroups={bottomMenuGroups} />
      </div>
    </CSSTransition>
  );
};