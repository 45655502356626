import React, { HTMLAttributes } from "react";
import styles from "./DesktopSideBar.module.scss";
import { DesktopMenuView } from "../../application/menu/desktop/DesktopMenuView";

interface DesktopMenuProps extends HTMLAttributes<any> {
  toggleMenu: Function;
  menuExpanded: boolean;
}

export const DesktopSideBar: React.FunctionComponent<DesktopMenuProps> = (props) => {
  return (
    <div className={props.className}>
      <div className={styles.wrapper}>
        <div className={styles.navigation}>
          <DesktopMenuView toggleMenu={props.toggleMenu} menuExpanded={props.menuExpanded} />
        </div>
      </div>
    </div>
  );
};