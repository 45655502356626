import React from "react";
import { useMenu } from "../../../../hooks/application/menu/useMenu";
import { MenuService } from "../../../../services/application data/menu/MenuService";
import { Icon } from "../../../../parts/icons/Icon";
import { MenuEntryPacket } from "../../../../models/application/menu/MenuEntryPacket";
import styles from "./DesktopMenuView.module.scss";
import { MenuEntryType } from "../../../../models/application/menu/MenuEntryType";
import { Link, useLocation } from "react-router-dom";
import {
  TooltipDelayHide,
  TooltipDelayShow,
  TooltipStyle,
} from "../../../../shared/styles/TooltipStyle";
import { Tooltip } from "react-tooltip";
import { DesktopMenuContainerView } from "./DesktopMenuContainerView";

interface MenuProps {
  toggleMenu: Function;
  menuExpanded: boolean;
}

export const DesktopMenuView: React.FunctionComponent<MenuProps> = (props) => {
  const { menuGroups } = useMenu();
  const sortedMenuGroups = MenuService.sortMenuGroups(menuGroups);
  const { topMenuGroups, bottomMenuGroups } = MenuService.splitMenuGroups(sortedMenuGroups);

  return (
    <>
      {topMenuGroups.map((group) => (
        <div key={`${group.id}`} className={styles.container}>
          {group.entries.map((entry) => (
            <MenuEntry
              key={entry.id}
              entry={entry}
              toggleMenu={props.toggleMenu}
              menuExpanded={props.menuExpanded}
            />
          ))}
        </div>
      ))}

      {bottomMenuGroups.length > 0 && (
        <div className={styles.container + " " + styles.bottom}>
          {bottomMenuGroups.map((group) => (
            <div key={`${group.id}`} className={styles.container}>
              {group.entries.map((entry) => (
                <MenuEntry
                  key={entry.id}
                  entry={entry}
                  toggleMenu={props.toggleMenu}
                  menuExpanded={props.menuExpanded}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

type MenuEntryParams = {
  entry: MenuEntryPacket;

  toggleMenu: Function;
  menuExpanded: boolean;
};
const MenuEntry = (params: MenuEntryParams) => {
  switch (params.entry.type) {
    case MenuEntryType.Container:
      return <DesktopMenuContainerView {...params} />;
    case MenuEntryType.OpenMenu:
      return <OpenMenuEntry {...params} />;
    case MenuEntryType.ToggleMenu:
      return <ToggleMenuEntry {...params} />;
    case MenuEntryType.Link:
      return <LinkMenuEntry {...params} />;

    default:
      return <LinkMenuEntry {...params} />;
  }
};

const OpenMenuEntry = (params: MenuEntryParams) => {
  const tooltipId = crypto.randomUUID();

  return (
    <div
      className={styles.itemContainer + " " + (params.menuExpanded ? "" : styles.closed)}
      data-tooltip-id={tooltipId}
      data-tooltip-content={params.entry.label}
    >
      <div
        className={styles.item}
        onClick={() => {
          if (params.toggleMenu) {
            params.toggleMenu();
          }
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (params.toggleMenu) {
              params.toggleMenu();
            }
          }
        }}
      >
        <Icon iconType={params.entry.iconType} iconName={params.entry.icon} />
        <label>{params.entry.label}</label>

        {!params.menuExpanded && (
          <Tooltip
            id={tooltipId}
            place={"right"}
            style={TooltipStyle}
            delayShow={TooltipDelayShow}
            delayHide={TooltipDelayHide}
          />
        )}
      </div>
    </div>
  );
};

const ToggleMenuEntry = (params: MenuEntryParams) => {
  const tooltipId = crypto.randomUUID();

  return (
    <div
      className={styles.itemContainer + " " + (params.menuExpanded ? "" : styles.closed)}
      data-tooltip-id={tooltipId}
      data-tooltip-content={params.entry.label}
    >
      <div
        className={styles.item}
        onClick={() => {
          if (params.toggleMenu) {
            params.toggleMenu();
          }
        }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (params.toggleMenu) {
              params.toggleMenu();
            }
          }
        }}
      >
        <Icon
          className={styles.icon + " " + (params.menuExpanded ? "" : styles.rotated)}
          iconType={params.entry.iconType}
          iconName={params.entry.icon}
        />
        <label>{params.entry.label}</label>

        {!params.menuExpanded && (
          <Tooltip
            id={tooltipId}
            place={"right"}
            style={TooltipStyle}
            delayShow={TooltipDelayShow}
            delayHide={TooltipDelayHide}
          />
        )}
      </div>
    </div>
  );
};

const LinkMenuEntry = (params: MenuEntryParams) => {
  const location = useLocation();

  if (!params.entry.link) {
    return <></>;
  }

  const isActive = location.pathname === params.entry.link.link;

  const tooltipId = crypto.randomUUID();

  return (
    <div
      className={styles.itemContainer + " " + (params.menuExpanded ? "" : styles.closed)}
      data-tooltip-id={tooltipId}
      data-tooltip-content={params.entry.label}
    >
      <Link
        to={{ pathname: params.entry.link.link, search: location.search }}
        className={styles.item + " " + (isActive ? styles.active : "")}
      >
        <Icon iconType={params.entry.iconType} iconName={params.entry.icon} />
        <label>{params.entry.label}</label>

        {!params.menuExpanded && (
          <Tooltip
            id={tooltipId}
            place={"right"}
            style={TooltipStyle}
            delayShow={TooltipDelayShow}
            delayHide={TooltipDelayHide}
          />
        )}
      </Link>
    </div>
  );
};