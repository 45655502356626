import React, { HTMLAttributes } from "react";
import styles from "./PageNotFound.module.scss";

export const PageNotFound: React.FunctionComponent<HTMLAttributes<any>> = (props) => {
  return (
    <div className={props.className}>
      <div className={styles.container}>
        <h1 className={styles.text}>Please select a navigation point on the left side</h1>
      </div>
    </div>
  );
};