import { Client } from "@microsoft/microsoft-graph-client";
import { AccountInfo } from "@azure/msal-browser";
import { MsalService } from "./MsalService";

export class GraphService {
  public static getGraphInstance(account: AccountInfo): Client {
    return Client.init({
      authProvider: async (done) => {
        const tokenResponse = await MsalService.getAccessToken();

        done(null, tokenResponse.accessToken);
      },
    });
  }
}