import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";
import { ISearchProvider } from "./ISearchProvider";

export class SearchProvider extends BaseApiDataProvider implements ISearchProvider {
  public async getSearchResult(query: string): Promise<any[]> {
    // TODO: Replace with correct
    return await this.get(`/search?query=${query}`);
  }
}

export class SearchProviderFactory {
  private static searchProvider: ISearchProvider | null = null;

  public static getSearchProvider(): ISearchProvider {
    if (this.searchProvider === null) {
      this.searchProvider = new SearchProvider(new BaseApiRequestHandler());
    }

    return this.searchProvider;
  }
}