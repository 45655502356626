import { useEffect, useState } from 'react';

import { HttpConstants, HttpHelper } from '../../../models/http communication/HttpConstants';
import { Feedback } from '../../../models/master data/feedback/feedback';
import { FeedbackProviderFactory } from '../../../providers/master data/feedback/FeedbackProvider';
import { IFeedbackProvider } from '../../../providers/master data/feedback/IFeedbackProvider';

export const useFeedbacks = () => {

  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [feedbacksIsLoading, setFeedbacksIsLoading] = useState<boolean>(false);
  const [feedbacksState, setFeedbacksState] = useState<HttpConstants>(HttpConstants.Ok);

  useEffect(() => {
    setFeedbacksIsLoading(true);

    const feedbackProvider: IFeedbackProvider = FeedbackProviderFactory.getFeedbackProvider();

    async function getFeedbacks() {
      try {
        const result = await feedbackProvider.getFeedbacks();
        setFeedbacksState(HttpConstants.Ok);
        setFeedbacks(result);
      } catch (e: any) {
        HttpHelper.handleError(e, setFeedbacksState);
        setFeedbacks([]);
      }

      setFeedbacksIsLoading(false);
    }

    getFeedbacks();
  }, []);

  return { feedbacks, feedbacksIsLoading, feedbacksState };
};