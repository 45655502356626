export class SelectedYearService {
  private static readonly PARAMETER_NAME = "year";

  private static availableYears: Date[] = [];

  public static getSelectedYear(searchParams: URLSearchParams) {
    const yearParam = searchParams.get(this.PARAMETER_NAME);

    if (yearParam === null) {
      return this.getDefaultYear();
    }

    if (isNaN(parseInt(yearParam))) {
      return this.getDefaultYear();
    }

    const year = +yearParam;

    if (this.availableYears.length === 0) {
      this.availableYears = this.getAvailableYears();
    }

    if (!this.availableYears.find((x) => x.getFullYear() === year)) {
      return this.getDefaultYear();
    }

    return year;
  }

  public static getAvailableYears() {
    const earliestYear = new Date();
    const finalYear = new Date();

    const availableYears: Date[] = [];
    earliestYear.setFullYear(earliestYear.getFullYear() - 10);

    for (
      let currentYear = finalYear;
      currentYear >= earliestYear;
      currentYear.setFullYear(currentYear.getFullYear() - 1)
    ) {
      availableYears.push(new Date(currentYear));
    }

    return availableYears;
  }

  private static getDefaultYear() {
    const currentDate = new Date();

    if (currentDate.getMonth() < 1) {
      return currentDate.getFullYear() - 1;
    } else {
      return currentDate.getFullYear();
    }
  }
}