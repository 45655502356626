import {
  AuthenticationResult,
  Configuration,
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { EnvironmentVariablesModel } from "../../models/configuration/EnvironmentVariablesModel";
import { AppConfigManager } from "../../models/configuration/AppConfigManager";

export class MsalService {
  private static authenticationInstance: IPublicClientApplication;

  public static getAuthenticationInstance(): IPublicClientApplication {
    const environmentVariables: EnvironmentVariablesModel =
      AppConfigManager.getEnvironmentVariables();

    if (!this.authenticationInstance) {
      const msalConfig: Configuration = {
        auth: {
          clientId: environmentVariables.clientId,
          authority: `https://login.microsoftonline.com/${environmentVariables.tenantId}`,
          redirectUri: window.location.origin,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      };

      this.authenticationInstance = new PublicClientApplication(msalConfig);

      this.authenticationInstance.addEventCallback((event: any) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          this.authenticationInstance.setActiveAccount(account);
        }
      });
    }

    return this.authenticationInstance;
  }

  public static async getAccessToken(): Promise<AuthenticationResult> {
    const msalInstance = this.getAuthenticationInstance();
    const environmentVariables = AppConfigManager.getEnvironmentVariables();

    try {
      return await msalInstance.acquireTokenSilent({
        scopes: [`${environmentVariables.apiScope}`],
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        return await msalInstance.acquireTokenPopup({
          scopes: [`${environmentVariables.apiScope}`],
        });
      } else {
        throw error;
      }
    }
  }
}