import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectedYearService } from "../../../services/report data/year/SelectedYearService";

export const useSelectedYear = () => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setSelectedYear(SelectedYearService.getSelectedYear(searchParams));
  }, [searchParams]);

  return { selectedYear };
};