export enum FilterDataConditionType {
  // Basic
  in = 0,
  notIn = 1,
  all = 2,

  // Advanced
  none = 3,
  lessThan = 4,
  lessThanOrEqual = 5,
  greaterThan = 6,
  greaterThanOrEqual = 7,
  contains = 8,
  doesNotContain = 9,
  startsWith = 10,
  doesNotStartWith = 11,
  is = 12,
  isNot = 13,
  isBlank = 14,
  isNotBlank = 15,
}