import { useEffect, useState } from 'react';

import { HttpConstants, HttpHelper } from '../../../models/http communication/HttpConstants';
import { Abbreviation } from '../../../models/master data/abbreviation/abbreviation';
import { AbbreviationProviderFactory } from '../../../providers/master data/abbreviation/AbbreviationProvider';
import { IAbbreviationProvider } from '../../../providers/master data/abbreviation/IAbbreviationProvider';

export const useAbbreviations = () => {

  const [abbreviations, setAbbreviations] = useState<Abbreviation[]>([]);
  const [abbreviationsIsLoading, setAbbreviationsIsLoading] = useState<boolean>(false);
  const [abbreviationsState, setAbbreviationsState] = useState<HttpConstants>(HttpConstants.Ok);

  useEffect(() => {
    setAbbreviationsIsLoading(true);

    const abbreviationProvider: IAbbreviationProvider = AbbreviationProviderFactory.getAbbreviationProvider();

    async function getAbbreviations() {
      try {
        const result = await abbreviationProvider.getAbbreviations();
        setAbbreviationsState(HttpConstants.Ok);
        setAbbreviations(result);
      } catch (e: any) {
        HttpHelper.handleError(e, setAbbreviationsState);
        setAbbreviations([]);
      }

      setAbbreviationsIsLoading(false);
    }

    getAbbreviations();
  }, []);

  return { abbreviations, abbreviationsIsLoading, abbreviationsState };
};