export class TextService {
  public static canvas: HTMLCanvasElement;

  public static getTextWidth(text: string, font: string) {
    const canvas = this.canvas || (this.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d")!;
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  public static getCssStyle(element: Element, prop: string) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }

  public static getCanvasFont(
    element: Element = document.body,
    inputFontWeight: string | null = null,
    inputFontSize: string | null = null,
  ) {
    const fontWeight = inputFontWeight || this.getCssStyle(element, "font-weight") || "normal";
    const fontSize = inputFontSize || this.getCssStyle(element, "font-size") || "16px";
    const fontFamily = this.getCssStyle(element, "font-family") || "Times New Roman";

    return `${fontWeight} ${fontSize} ${fontFamily}`;
  }
}