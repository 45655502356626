import { useEffect, useState } from "react";
import { AuthorizationProviderFactory } from "../../../providers/identity/authorization/AuthorizationProvider";

export function useRights(username: string) {
  const [rights, setRights] = useState<number[]>([]);
  const [isLoadingRights, setIsLoadingRights] = useState<boolean>(false);

  useEffect(() => {
    const authorizationProvider = AuthorizationProviderFactory.getAuthorizationProvider();

    async function getRights() {
      setIsLoadingRights(true);

      try {
        const rights = await authorizationProvider.getRights();

        setIsLoadingRights(false);
        setRights(rights);
      } catch {
        setIsLoadingRights(false);
        setRights([]);
      }
    }

    getRights();
  }, [username]);

  return { rights, isLoadingRights };
}