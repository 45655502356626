import { IReportProvider } from "./IReportProvider";
import { ReportPacket } from "../../../models/report data/report/ReportPacket";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";

export class ReportProvider extends BaseApiDataProvider implements IReportProvider {
  public async getReport(reportId: string): Promise<ReportPacket | null> {
    return await this.get(`/report/${reportId}`);
  }
}

export class ReportProviderFactory {
  private static reportProvider: IReportProvider | null = null;

  public static getReportProvider(): IReportProvider {
    if (this.reportProvider === null) {
      this.reportProvider = new ReportProvider(new BaseApiRequestHandler());
    }

    return this.reportProvider;
  }
}