import * as React from "react";
const SvgGlobe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 123 123"
    {...props}
  >
    <defs>
      <clipPath id="globe_svg__a">
        <path d="M1075 608h123v123h-123z" />
      </clipPath>
    </defs>
    <g clipPath="url(#globe_svg__a)" transform="translate(-1075 -608)">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M1145.4 720.481c10.52-12.948 22.17-29.294 23.31-47.744h19.42c-1.46 24.114-19.42 43.698-42.73 47.744Zm-60.53-47.744h19.42c1.3 18.45 12.79 34.796 23.31 47.744-23.31-4.046-41.27-23.63-42.73-47.744Zm42.73-54.217c-10.52 12.947-22.17 29.293-23.31 47.743h-19.42c1.46-24.114 19.42-43.697 42.73-47.743Zm12.14 54.217h22.49c-1.29 16.67-12.14 31.721-22.49 44.507v-44.507Zm-6.48 0v44.507c-10.35-12.786-21.2-27.837-22.49-44.507h22.49Zm6.48-50.98c10.35 12.785 21.2 27.675 22.49 44.506h-22.49v-44.506Zm-6.48 44.506h-22.49c1.29-16.669 12.14-31.721 22.49-44.506v44.506Zm54.87 0h-19.42c-1.14-18.45-12.79-34.796-23.31-47.743 23.31 4.046 41.27 23.629 42.73 47.743ZM1136.5 608c-33.99 0-61.5 27.513-61.5 61.5 0 33.987 27.51 61.5 61.5 61.5 33.99 0 61.5-27.513 61.5-61.5 0-33.987-27.51-61.5-61.5-61.5Z"
      />
    </g>
  </svg>
);
export default SvgGlobe;
