import React, { PropsWithChildren, useState } from "react";
import { Menu, MenuContext } from "./MenuContext";
import { MenuCategory } from "../../../models/application/menu/MenuCategory";
import { MenuBlurArea } from "../../../models/application/menu/MenuBlurArea";

export const MenuContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const [menuUpdated, setMenuUpdated] = useState<boolean>(false);

  const isNoMenuOpen = (menuCategory: MenuCategory) => {
    const menu = menus.find((x) => x.category === menuCategory);

    if (menu === undefined) {
      return true;
    }

    return menu.menuId === null;
  };

  const isMenuOpen = (menuCategory: MenuCategory, menuId: string | null) => {
    const menu = menus.find((x) => x.category === menuCategory);

    if (menu === undefined) {
      return false;
    }

    return menu.menuId === menuId;
  };

  const isBlurred = (blurArea: MenuBlurArea) => {
    const menu = menus.find((x) => x.blur === blurArea);

    if (menu === undefined) {
      return false;
    }

    return menu.blur === blurArea;
  };

  const setMenu = (menu: Menu) => {
    const foundMenu = menus.find((x) => x.category === menu.category);

    if (foundMenu === undefined) {
      menus.push(menu);
    } else {
      foundMenu.menuId = menu.menuId;
      foundMenu.blur = menu.blur;
    }

    setMenus(menus);
    setMenuUpdated(!menuUpdated);
  };

  const removeMenu = (menu: Menu) => {
    const foundIndex = menus.findIndex((x) => x.category === menu.category);

    if (foundIndex === -1) {
      return;
    }

    menus.splice(foundIndex, 1);

    setMenus(menus);
    setMenuUpdated(!menuUpdated);
  };

  return (
    <MenuContext.Provider
      value={{
        isNoMenuOpen,
        isMenuOpen,
        isBlurred,
        setMenu,
        removeMenu,
        menuUpdated,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  );
};