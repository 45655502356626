import { IAuthorizationProvider } from "./IAuthorizationProvider";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";

export class AuthorizationProvider extends BaseApiDataProvider implements IAuthorizationProvider {
  public async isApplicationAuthorized(): Promise<boolean> {
    return await this.get(`authorization/application`);
  }

  public async getRights(): Promise<number[]> {
    return await this.get(`authorization/rights`);
  }
}

export class AuthorizationProviderFactory {
  private static authorizationProvider: IAuthorizationProvider | null = null;

  public static getAuthorizationProvider(): IAuthorizationProvider {
    if (this.authorizationProvider === null) {
      this.authorizationProvider = new AuthorizationProvider(new BaseApiRequestHandler());
    }

    return this.authorizationProvider;
  }
}