import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import { IconType } from "../../../models/application/icon/IconType";
import { Icon } from "../../../parts/icons/Icon";
import { FavouritesProviderFactory } from "../../../providers/application data/favourites/FavouritesProvider";
import { IFavouritesProvider } from "../../../providers/application data/favourites/IFavouritesProvider";
import {
  TooltipDelayHide,
  TooltipDelayShow,
  TooltipStyle,
} from "../../../shared/styles/TooltipStyle";
import styles from "./FavouritesMarker.module.scss";

export const FavouritesMarker: React.FunctionComponent<any> = (props) => {
  const [favourite, setFavourite] = useState(false);
  const favouriteProvider: IFavouritesProvider = FavouritesProviderFactory.getFavouritesProvider();

  useEffect(() => {
    async function checkFavourite() {
      try {
        const result = await favouriteProvider.checkFavourite(props.reportId);
        setFavourite(result);
      } catch {
        setFavourite(false);
      }
    }

    checkFavourite();
  }, [props.reportId]);

  function changeFavouriteStatus() {
    if (favourite) {
      favouriteProvider.deleteFavourite(props.reportId);
    } else {
      const filter = window.location.search;
      favouriteProvider.addFavourite(props.reportId, filter);
    }

    setFavourite(!favourite);
  }

  const tooltipId = crypto.randomUUID();
  const tooltipText = favourite ? "Remove Favorite" : "Add Favorite";

  const icon: string = favourite ? "star-off" : "star-plus-outline";

  return (
    <div className={styles.favouritesMarker}>
      <Icon
        iconName={icon}
        iconType={IconType.MaterialUI}
        className={styles.icon}
        onClick={() => {
          changeFavouriteStatus();
        }}
        data-tooltip-id={tooltipId}
      />

      <Tooltip
        id={tooltipId}
        place={"bottom"}
        style={TooltipStyle}
        delayShow={TooltipDelayShow}
        delayHide={TooltipDelayHide}
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};