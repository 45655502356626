import React from "react";
import styles from "./FilterDropdownItem.module.scss";
import { Icon } from "../../../../parts/icons/Icon";
import { useSearchParams } from "react-router-dom";
import { FilterItemPacket } from "../../../../models/report data/filter/FilterItemPacket";
import { IconType } from "../../../../models/application/icon/IconType";

interface FilterDropdownItemProps {
  filter: FilterItemPacket;
  parent: FilterItemPacket;

  parameterName: string;

  isReturnItem: boolean;
  setActiveMenu: Function;

  closeMenu: Function;
}

export const FilterDropdownItem: React.FunctionComponent<FilterDropdownItemProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasChildren = (props.filter.children?.length ?? 0) > 0;

  const addParameter = () => {
    if (props.filter.filterLink === undefined) {
      return;
    }

    searchParams.set(props.parameterName, props.filter.filterLink.link);
    setSearchParams(searchParams);
  };

  const navigateToMenu = () => {
    props.setActiveMenu(
      props.parent.originId,
      props.isReturnItem ? props.filter.parent!.originId : props.filter.originId,
      props.isReturnItem,
    );
  };

  return (
    <>
      {props.isReturnItem && (
        <div className={styles.returnItem}>
          <div className={styles.item} onClick={() => navigateToMenu()}>
            <Icon
              iconName={"chevron-left"}
              iconType={IconType.MaterialUI}
              className={styles.arrow + " " + styles.backArrow}
            />
            <span className={styles.text}>{props.filter.name}</span>
          </div>
        </div>
      )}

      {!props.isReturnItem && (
        <div className={styles.wrapper}>
          <div
            className={styles.item}
            onClick={() => {
              if (props.filter.filterData !== undefined && props.filter.filterData.length > 0) {
                props.closeMenu();
                addParameter();
              } else {
                navigateToMenu();
              }
            }}
          >
            <span className={styles.text}>{props.filter.name}</span>
          </div>
          {hasChildren && (
            <div className={styles.navigationItem} onClick={() => navigateToMenu()}>
              <Icon
                iconName={"chevron-right"}
                iconType={IconType.MaterialUI}
                className={styles.arrow}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};