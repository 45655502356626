import React from "react";
import { useMsal } from "@azure/msal-react";

export const Logout: React.FunctionComponent = () => {
  const { instance: msalInstance } = useMsal();

  const logout = async () => {
    await msalInstance.logout();
  };

  logout();

  return <></>;
};