import { Abbreviation } from '../../../models/master data/abbreviation/abbreviation';
import { BaseApiDataProvider } from '../../../services/http communication/BaseApiDataProvider';
import { BaseApiRequestHandler } from '../../../services/http communication/BaseApiRequestHandler';
import { IAbbreviationProvider } from './IAbbreviationProvider';

export class AbbreviationProvider extends BaseApiDataProvider implements IAbbreviationProvider {

  public async getAbbreviations(): Promise<Abbreviation[]> {
    return await this.get(`/abbreviation`);
  }

  public async getAbbreviation(id: string): Promise<Abbreviation> {
    return await this.get(`/abbreviation/${id}`);
  }

  public async createAbbreviation(abbreviation: Abbreviation): Promise<void> {
    return await this.post(`/abbreviation/${abbreviation.id}`, abbreviation);
  }

  public async updateAbbreviation(abbreviation: Abbreviation): Promise<void> {
    return await this.put(`/abbreviation/${abbreviation.id}`, abbreviation);
  }

  public async deleteAbbreviation(id: string): Promise<void> {
    return await this.delete(`/abbreviation/${id}`);
  }
}

export class AbbreviationProviderFactory {
  private static abbreviationProvider: IAbbreviationProvider | null = null;

  public static getAbbreviationProvider(): IAbbreviationProvider {
    if (this.abbreviationProvider === null) {
      this.abbreviationProvider = new AbbreviationProvider(new BaseApiRequestHandler());
    }

    return this.abbreviationProvider;
  }
}