import * as React from "react";
const SvgAnouncement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    overflow="hidden"
    viewBox="0 0 128 104"
    {...props}
  >
    <defs>
      <clipPath id="anouncement_svg__a">
        <path d="M1091 1894h128v104h-128z" />
      </clipPath>
    </defs>
    <g
      fill="#FFF"
      fillRule="evenodd"
      clipPath="url(#anouncement_svg__a)"
      transform="translate(-1091 -1894)"
    >
      <path d="M1099.86 1973.45c-1.27-2.31-4.28-3.14-6.51-1.82-2.22 1.32-3.01 4.45-1.74 6.77l9.53 17.15c1.27 2.31 4.28 3.14 6.51 1.82 2.22-1.32 3.01-4.45 1.74-6.77l-9.53-17.15ZM1171.07 1983.69l-22.58 6.49-5.36-5.84 29.41-8.44-1.47 7.79Zm47.45-25.15-10.89-18.83-14.62-25.32-10.89-18.82c-1.13-1.79-3.57-2.11-5.03-.65l-74.09 71.41 14.62 25.32 18.52-5.2 8.29 8.93c1.3 1.3 3.09 1.95 4.87 1.46l24.54-6.98c.32-.16.81-.32 1.13-.49 1.14-.65 2.12-1.94 2.28-3.24l2.27-12.01 37.21-10.55c1.95-.81 2.92-3.25 1.79-5.03Z" />
    </g>
  </svg>
);
export default SvgAnouncement;
