import { useEffect, useState } from "react";
import { MenuProviderFactory } from "../../../providers/application data/menu/MenuProvider";
import { MenuGroupPacket } from "../../../models/application/menu/MenuGroupPacket";

export function useMenu() {
  const [menuGroups, setMenuGroups] = useState<MenuGroupPacket[]>([]);

  useEffect(() => {
    const menuProvider = MenuProviderFactory.getMenuProvider();

    async function getMenu() {
      try {
        const menuGroups = await menuProvider.getMenu();
        setMenuGroups(menuGroups);
      } catch {
        setMenuGroups([]);
      }
    }

    getMenu();
  }, []);

  return { menuGroups };
}