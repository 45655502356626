import React, { HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import styles from "./ThemeSwitchView.module.scss";
import { Icon } from "../../../parts/icons/Icon";
import {
  TooltipDelayHide,
  TooltipDelayShow,
  TooltipStyle,
} from "../../../shared/styles/TooltipStyle";
import { Tooltip } from "react-tooltip";
import { ThemeContext } from "../../../contexts/application/theme/ThemeContext";
import { Menu, MenuContext } from "../../../contexts/application/menu/MenuContext";
import { ThemeItem } from "../../../models/application/theme/ThemeItem";
import { MenuCategory } from "../../../models/application/menu/MenuCategory";
import { IconType } from "../../../models/application/icon/IconType";

export const ThemeSwitchView: React.FunctionComponent<HTMLAttributes<any>> = (props) => {
  const MENU_CATEGORY = MenuCategory.TopMenu;
  const MENU_ID = "themeSwitch";
  const MENU: Menu = {
    category: MENU_CATEGORY,
    menuId: MENU_ID,
    blur: null,
  };

  const themeContext = useContext(ThemeContext);
  const menuContext = useContext(MenuContext);
  const buttonRef = useRef<HTMLDivElement>(null);

  const [leftOffset, setLeftOffset] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    if (isOpen) {
      menuContext.removeMenu(MENU);
    } else {
      menuContext.setMenu(MENU);
    }
  };

  const processResize = () => {
    calculateWidths();
  };

  const updateTheme = (theme: ThemeItem) => {
    menuContext.removeMenu(MENU);
    themeContext.themeService.setTheme(theme);
  };

  const calculateWidths = () => {
    const baseOffset = -315;
    const startingPosition = (buttonRef.current?.offsetLeft ?? 0) + baseOffset;

    const remainder = startingPosition < 0 ? -startingPosition : 0;

    const leftOffset = remainder > 0 ? baseOffset + remainder : baseOffset;

    setLeftOffset(leftOffset);
  };

  useEffect(() => {
    themeContext.themeService.loadTheme();
  }, [themeContext]);

  useEffect(() => {
    processResize();
    window.addEventListener("resize", processResize);
    return () => window.removeEventListener("resize", processResize);
  });

  useEffect(() => {
    setIsOpen(menuContext.isMenuOpen(MENU_CATEGORY, MENU_ID));
  }, [menuContext.menuUpdated]);

  const tooltipId = crypto.randomUUID();

  return (
    <div className={styles.themeSwitcher + " " + props.className} ref={buttonRef}>
      <div className={styles.iconWrapper} onClick={() => toggleMenu()}>
        <Icon
          data-tooltip-id={tooltipId}
          data-tooltip-content={"Themes"}
          iconName="palette-outline"
          className={styles.icon}
          iconType={IconType.MaterialUI}
        />
      </div>

      {isOpen && (
        <div className={styles.themeMenu} style={{ left: leftOffset }}>
          <div className={styles.themeMenuHeader}>
            <label className={styles.themeMenuLabel}>Theming</label>
          </div>
          {themeContext.themeService.getThemes().map((theme) => {
            const color = {
              backgroundColor: theme.primaryColor,
            };

            return (
              <div key={theme.name} className={styles.themeItem}>
                <button
                  style={color}
                  className={styles.themeButton}
                  onClick={() => updateTheme(theme)}
                />
                <label>{theme.name}</label>
              </div>
            );
          })}
        </div>
      )}

      {!isOpen && (
        <Tooltip
          id={tooltipId}
          place={"bottom"}
          style={TooltipStyle}
          delayShow={TooltipDelayShow}
          delayHide={TooltipDelayHide}
        />
      )}
    </div>
  );
};