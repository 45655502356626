import { useESAProjects } from "../../../hooks/master data/esa project/useESAProjects";
import React from "react";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";
import { FunctionNotAuthorized } from "../../identity/authorization/FunctionNotAuthorized";
import { ESAProjectPacket } from "../../../models/master data/esa project/ESAProjectPacket";
import { ESAProjectProviderFactory } from "../../../providers/master data/esa project/ESAProjectProvider";
import { ColumnDef } from "@tanstack/react-table";
import { v4 as uuid } from "uuid";
import { EditorSectionPacket } from "../../../models/master data/generics/EditorSection";
import {
  GenericMasterDataSubinformationEditorView,
  SubTableProps,
} from "../generic master data/views/GenericMasterDataSubinformationEditorView";
import { EditorRecord } from "../../../models/master data/generics/EditorRecord";
import { ESAProjectWBSPacket } from "../../../models/master data/esa project/ESAProjectWBSPacket";
import { FieldType } from "../../../models/master data/generics/FieldType";
import { ESAProjectWBSProviderFactory } from "../../../providers/master data/esa project/ESAProjectWBSProvider";
import { ESAProjectPOMIProviderFactory } from "../../../providers/master data/esa project/ESAProjectPOMIProvider";
import { ESAProjectPOMIPacket } from "../../../models/master data/esa project/ESAProjectPOMIPacket";
import styles from "../generic master data/views/GenericMasterDataEditor.module.scss";
import { HttpConstants } from "../../../models/http communication/HttpConstants";
import { MsalService } from "../../../services/identity/MsalService";
import { useRights } from "../../../hooks/identity/authorization/useRights";
import { RightConstants } from "../../../models/identity/RightConstants";

export const ESAProjectRelationsView: React.FunctionComponent = () => {
  const msalInstance = MsalService.getAuthenticationInstance();
  const account = msalInstance.getActiveAccount();

  const { rights, isLoadingRights } = useRights(account?.username ?? "");

  const { esaProjects, esaProjectsIsLoading, esaProjectsState } = useESAProjects(true, true);

  const spaceProjectsProvider = ESAProjectProviderFactory.getESAProjectProvider();

  const columnDefinitions: ColumnDef<ESAProjectPacket>[] = [
    {
      accessorKey: "name",
      cell: (info) => info.getValue(),
      header: "Name",
      sortingFn: "text",
    },
    {
      accessorKey: "displayName",
      cell: (info) => info.getValue(),
      header: "Display Name",
    },
  ];

  const subTables: SubTableProps<EditorRecord, EditorRecord>[] = [];

  const wbsSubTable = createWBSSubTable({ rights });
  subTables.push(wbsSubTable);

  const pomiSubTable = createPOMISubTable({ rights });
  subTables.push(pomiSubTable);

  return (
    <div className={styles.container}>
      {esaProjectsIsLoading ? (
        <FullscreenPropagateLoader />
      ) : esaProjectsState === HttpConstants.Unauthorized ? (
        <FunctionNotAuthorized />
      ) : (
        <GenericMasterDataSubinformationEditorView
          name={"ESA Project Relations"}
          columnDefinitions={columnDefinitions}
          dataSource={esaProjects}
          getRecord={(id: string) => spaceProjectsProvider.getESAProject(id, true, true)}
          subTables={subTables}
        />
      )}
    </div>
  );
};

type CreateTableParams = {
  rights: number[];
};
const createWBSSubTable = (params: CreateTableParams) => {
  const esaProjectWBSProvider = ESAProjectWBSProviderFactory.getESAProjectWBSProvider();

  const columnDefinitions: ColumnDef<ESAProjectWBSPacket>[] = [
    {
      accessorKey: "wbsElement",
      cell: (info) => info.getValue(),
      header: "WBS Element",
    },
    {
      accessorKey: "type",
      cell: (info) => info.getValue(),
      header: "Type",
    },
  ];

  const sectionDefintions: EditorSectionPacket<ESAProjectWBSPacket>[] = [
    {
      id: 1,
      heading: null,
      properties: [
        {
          propertyName: "wbsElement",
          displayName: "WBS Element",
          type: FieldType.Text,
        },
        {
          propertyName: "type",
          displayName: "Type",
          type: FieldType.Text,
        },
      ],
    },
  ];

  const createWBS = (record: ESAProjectPacket) => {
    // TODO: Add project id
    const newWBS: ESAProjectWBSPacket = {
      id: uuid(),
      displayName: "",
      esaProjectId: record.id,
      type: "",
      wbsElement: "",
    };

    return newWBS;
  };

  const createRecord = params.rights.find((x) => x === RightConstants.EditMasterData)
    ? (record: ESAProjectPacket) => createWBS(record)
    : null;
  const saveRecord = params.rights.find((x) => x === RightConstants.EditMasterData)
    ? (wbs: ESAProjectWBSPacket) => esaProjectWBSProvider.createWBS(wbs)
    : null;
  const updateRecord = params.rights.find((x) => x === RightConstants.EditMasterData)
    ? (wbs: ESAProjectWBSPacket) => esaProjectWBSProvider.updateWBS(wbs)
    : null;
  const deleteRecord = params.rights.find((x) => x === RightConstants.DeleteMasterData)
    ? (id: string) => esaProjectWBSProvider.deleteWBS(id)
    : null;

  const wbsSubTable: SubTableProps<ESAProjectPacket, ESAProjectWBSPacket> = {
    name: "WBS Relations",
    columnDefinitions: columnDefinitions,
    sectionDefinitions: sectionDefintions,
    dataSourceName: "wbs_relations",
    getRecord: (id: string) => esaProjectWBSProvider.getWBS(id),
    createRecord: createRecord,
    saveRecord: saveRecord,
    updateRecord: updateRecord,
    deleteRecord: deleteRecord,
    getRecordDisplayName: (record) => record.wbsElement,
  };

  return wbsSubTable as unknown as SubTableProps<EditorRecord, EditorRecord>;
};

const createPOMISubTable = (params: CreateTableParams) => {
  const esaProjectPOMIProvider = ESAProjectPOMIProviderFactory.getESAProjectPOMIProvider();

  const columnDefinitions: ColumnDef<ESAProjectPOMIPacket>[] = [
    {
      accessorKey: "contractNumber",
      cell: (info) => info.getValue(),
      header: "Contract Number",
    },
    {
      accessorKey: "contractItemNumber",
      cell: (info) => info.getValue(),
      header: "Contract Item Number",
    },
    {
      accessorKey: "type",
      cell: (info) => info.getValue(),
      header: "Type",
    },
  ];

  const sectionDefintions: EditorSectionPacket<ESAProjectPOMIPacket>[] = [
    {
      id: 1,
      heading: null,
      properties: [
        {
          propertyName: "contractNumber",
          displayName: "Contract Number",
          type: FieldType.Text,
        },
        {
          propertyName: "contractItemNumber",
          displayName: "Contract Item Number",
          type: FieldType.Text,
        },
        {
          propertyName: "type",
          displayName: "Type",
          type: FieldType.Text,
        },
      ],
    },
  ];

  const createPOMI = (record: ESAProjectPacket) => {
    // TODO: Add project id
    const newPOMI: ESAProjectPOMIPacket = {
      id: uuid(),
      displayName: "",
      esaProjectId: record.id,
      contractNumber: "",
      contractItemNumber: "",
      type: "",
    };

    return newPOMI;
  };

  const createRecord = params.rights.find((x) => x === RightConstants.EditMasterData)
    ? (record: ESAProjectPacket) => createPOMI(record)
    : null;
  const saveRecord = params.rights.find((x) => x === RightConstants.EditMasterData)
    ? (pomi: ESAProjectPOMIPacket) => esaProjectPOMIProvider.createPOMI(pomi)
    : null;
  const updateRecord = params.rights.find((x) => x === RightConstants.EditMasterData)
    ? (pomi: ESAProjectPOMIPacket) => esaProjectPOMIProvider.updatePOMI(pomi)
    : null;
  const deleteRecord = params.rights.find((x) => x === RightConstants.DeleteMasterData)
    ? (id: string) => esaProjectPOMIProvider.deletePOMI(id)
    : null;

  const pomiSubTable: SubTableProps<ESAProjectPacket, ESAProjectPOMIPacket> = {
    name: "Contract Milestone Relations",
    columnDefinitions: columnDefinitions,
    sectionDefinitions: sectionDefintions,
    dataSourceName: "pomi_relations",
    getRecord: (id: string) => esaProjectPOMIProvider.getPOMI(id),
    createRecord: createRecord,
    saveRecord: saveRecord,
    updateRecord: updateRecord,
    deleteRecord: deleteRecord,
    getRecordDisplayName: (record) => record.contractNumber,
  };

  return pomiSubTable as unknown as SubTableProps<EditorRecord, EditorRecord>;
};