import "./PlanAndSimulate.scss";
import "./PlanAndSimulateAnimation.scss";

import { ColumnDef } from "@tanstack/react-table";
import React, { HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";
import { CSSTransition } from "react-transition-group";

import { IconType } from "../../models/application/icon/IconType";
import { Abbreviation } from "../../models/master data/abbreviation/abbreviation";
import { Icon } from "../../parts/icons/Icon";
import { GenericMasterDataTable } from "../master data management/generic master data/parts/GenericMasterDataTable";
import { RichTextEditor } from "./parts/RichTextEditor";
import styles from "./PlanAndSimulate.module.scss";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { AppConfigurationModel } from "../../models/configuration/AppConfigurationModel";
import { AppConfigManager } from "../../models/configuration/AppConfigManager";
import { LayoutType } from "powerbi-models";
import { ThemeContext } from "../../contexts/application/theme/ThemeContext";
import { ReportContext } from "../../contexts/report data/report/ReportContext";

const columnDefinitions: ColumnDef<Abbreviation>[] = [
  {
    accessorKey: "code",
    cell: (info) => info.getValue(),
    header: "Name",
    enableColumnFilter: false,
  },
  {
    accessorKey: "definition",
    cell: (info) => info.getValue(),
    header: "Date",
    enableColumnFilter: false,
  },
];

let selectedRecord: Abbreviation;
const dataSource: Abbreviation[] = [
  { id: "1", code: "PDR", definition: "Okt. 21, 2023", relatedReference: "", link: "" },
  { id: "1", code: "NAA CCRS", definition: "Okt. 21, 2023", relatedReference: "", link: "" },
  { id: "1", code: "CDR", definition: "Okt. 21, 2023", relatedReference: "", link: "" },
  { id: "1", code: "NASA CCRS", definition: "Okt. 21, 2023", relatedReference: "", link: "" },
];

const selectRecord = () => {};

const createRecord = () => {};

const confirmDeleteRecord = () => {};

const fakeProjects = [
  { value: "100420_ACEP", label: "100420_ACEP" },
  { value: "100430_ACEP", label: "100430_ACEP" },
  { value: "100440_ACEP", label: "100440_ACEP" },
];

const fakeQRs = [
  { value: "2023/Q2", label: "2023/Q2" },
  { value: "2023/Q3", label: "2023/Q3" },
];

const fakeVersions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

const fakePages = [
  { value: "1", label: "Project Overview" },
  { value: "2", label: "Notes and follow up" },
  { value: "3", label: "Status and Activities" },
  { value: "4", label: "Points of attention" },
  { value: "5", label: "Milestones and trends" },
  { value: "6", label: "Activity timeline" },
  { value: "7", label: "Schedule" },
  { value: "8", label: "CaC" },
  { value: "9", label: "CAC per cost category" },
  { value: "10", label: "Cost plan" },
  { value: "11", label: "Cost plan deviations" },
  { value: "12", label: "Cmtment plan" },
  { value: "13", label: "Cmtment deviations" },
  { value: "14", label: "Workforce" },
  { value: "15", label: "Top risks" },
  { value: "16", label: "Geo-return" },
];

export const PlanAndSimulate: React.FunctionComponent<HTMLAttributes<any>> = (props) => {
  const [selectedProject, setSelectedProject] = useState<SingleValue<any>>(null);
  const [selectedQR, setSelectedQR] = useState<SingleValue<any>>(null);
  const [selectedVersion, setSelectedVersion] = useState<SingleValue<any>>(null);
  const [selectedPage, setSelectedPage] = useState<SingleValue<any>>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const appConfiguration: AppConfigurationModel = AppConfigManager.getAppConfiguration();

  const reportContext = useContext(ReportContext);
  const themeContext = useContext(ThemeContext);

  const menuRef = useRef<HTMLDivElement>(null);

  const scrollBarStyle = {
    menuList: (base: any) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "10px",
      },
      "::-webkit-scrollbar-track": {
        background: "#003247",
        border: "1px solid #001923",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#001923",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#001923",
      },
    }),
  };

  const reportConfig: models.IReportEmbedConfiguration = {
    type: "report",
    id: reportContext.reportPacket?.embedParams?.embedReport.reportId,
    embedUrl: reportContext.reportPacket?.embedParams?.embedReport.embedUrl,
    accessToken: reportContext.reportPacket?.embedParams?.embedToken.token,
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          visible: appConfiguration.showFilters,
        },
        pageNavigation: {
          visible: appConfiguration.showPages,
        },
      },
      background: models.BackgroundType.Transparent,
      layoutType: LayoutType.Master,
    },
    theme: {
      themeJson: themeContext.themeService.getPowerBITheme(), // Load theme once on init
    },
    filters: [
      {
        $schema: "http://powerbi.com/product/schema#basic",
        filterType: 1,
        target: {
          table: "VW_QuarterlyReport",
          column: "QuarterlyReport Id",
        },
        operator: "In",
        values: [2],
        requireSingleSelection: true,
      },
    ],
  };

  useEffect(() => {
    reportContext.setReportLink("plan");

    return () => {
      reportContext.setReportLink(undefined);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.filterRow}>
          <div>
            <label>Project</label>
            <Select
              onChange={(e) => {
                setSelectedProject(e);
                setSelectedQR(null);
                setSelectedVersion(null);
                setSelectedPage(null);
              }}
              value={selectedProject}
              placeholder={"Select"}
              unstyled={true}
              className={"pns-select"}
              classNamePrefix={"pns-select"}
              options={fakeProjects}
              isClearable={true}
              isSearchable={true}
            />
          </div>

          <div>
            <label>QR</label>
            <Select
              onChange={(e) => {
                setSelectedQR(e);
                setSelectedVersion(null);
                setSelectedPage(null);
              }}
              value={selectedQR}
              isDisabled={!selectedProject}
              placeholder={"Select"}
              unstyled={true}
              className={"pns-select"}
              classNamePrefix={"pns-select"}
              options={fakeQRs}
              isClearable={true}
              isSearchable={true}
            />
          </div>

          <div>
            <label>Version</label>
            <Select
              onChange={(e) => {
                setSelectedVersion(e);
                setSelectedPage(null);
              }}
              value={selectedVersion}
              isDisabled={!selectedQR}
              placeholder={"Select"}
              unstyled={true}
              className={"pns-select"}
              classNamePrefix={"pns-select"}
              options={fakeVersions}
              isClearable={true}
              isSearchable={true}
            />
          </div>

          <div>
            <label>Page</label>
            <Select
              onChange={(e) => setSelectedPage(e)}
              value={selectedPage}
              isDisabled={!selectedVersion}
              placeholder={"Select"}
              unstyled={true}
              className={"pns-select"}
              classNamePrefix={"pns-select"}
              options={fakePages}
              isClearable={true}
              isSearchable={true}
              styles={scrollBarStyle}
            />
          </div>
        </div>

        {selectedProject && selectedQR && selectedVersion && selectedPage && (
          <>
            <div className={styles.headerRow}>
              <label>
                {selectedProject.label} | {selectedQR.label} | {selectedVersion.label} |{" "}
                {selectedPage.label}
              </label>
            </div>

            {selectedPage.value === "1" && (
              <div className={styles.planAndSimulateTableView}>
                <div className={styles.editorRow}>
                  <div className={styles.editor2}>
                    <h3>Upcoming milestones / events</h3>
                    <RichTextEditor value="" />
                  </div>
                  <div className={styles.editor3}>
                    <GenericMasterDataTable
                      name="Milestones"
                      columnDefinitions={columnDefinitions}
                      dataSource={structuredClone(dataSource)}
                      selectedRecord={structuredClone(selectedRecord)}
                      selectRecord={selectRecord}
                      createRecord={createRecord}
                      deleteRecord={confirmDeleteRecord}
                    />
                  </div>
                </div>
                <div className={styles.editorRow}>
                  <div className={styles.editor3}>
                    <h3>Progress & achievements</h3>
                    <RichTextEditor value="" />
                  </div>
                  <div className={styles.editor3}>
                    <h3>Points for attention</h3>
                    <RichTextEditor value="" />
                  </div>
                  <div className={styles.editor3}>
                    <h3>Top risks</h3>
                    <RichTextEditor value="" />
                  </div>
                </div>
              </div>
            )}

            {selectedPage.value === "2" && (
              <div className={styles.editorRow}>
                <div className={styles.editor4}>
                  <h3>Previous review</h3>
                  <RichTextEditor value="" />
                </div>
                <div className={styles.editor4}>
                  <h3>Follow-up</h3>
                  <RichTextEditor value="" />
                </div>
              </div>
            )}

            <div className={styles.infoBoxToggle} onClick={() => setMenuOpen(true)}>
              <Icon iconType={IconType.MaterialUI} iconName={"information-outline"} />
              <label>INFO</label>
            </div>
          </>
        )}
      </div>

      <CSSTransition
        nodeRef={menuRef}
        in={menuOpen}
        timeout={300}
        classNames={"pns-info-animation"}
      >
        <div ref={menuRef} className={styles.infoBoxContainer} onClick={() => setMenuOpen(false)}>
          <div className={styles.infoBox}>
            <div className={styles.headerRow}>
              <label>
                {selectedProject?.label} | {selectedQR?.label} | {selectedVersion?.label} |{" "}
                {selectedPage?.label}
              </label>
            </div>

            <div className={styles.content}>
              <PowerBIEmbed cssClassName={styles.powerBi} embedConfig={reportConfig} />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};