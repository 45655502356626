import styles from "./MobileTopBar.module.scss";
import React, { useContext, useEffect, useState } from "react";
import { LogoContainer } from "../../application/logo/LogoContainer";
import { FilterView } from "../../report/filter/main-filter/FilterView";
import { ReportContext } from "../../../contexts/report data/report/ReportContext";
import { Icon } from "../../../parts/icons/Icon";
import {
  TooltipDelayHide,
  TooltipDelayShow,
  TooltipStyle,
} from "../../../shared/styles/TooltipStyle";
import { Tooltip } from "react-tooltip";
import { ThemeSwitchView } from "../../application/theme/ThemeSwitchView";
import { DateFilter } from "../../report/filter/special-filter/DateFilter";
import { MobileMenuView } from "../../application/menu/mobile/MobileMenuView";
import { Menu, MenuContext } from "../../../contexts/application/menu/MenuContext";
import { MenuCategory } from "../../../models/application/menu/MenuCategory";
import { IconType } from "../../../models/application/icon/IconType";
import { FeedbackDialog } from "../../master data management/feedback/FeedbackDialog";
import { FavouritesMarker } from "../../application/favourites/FavouritesMarker";

export const MobileTopBar: React.FunctionComponent = () => {
  const MENU_CATEGORY = MenuCategory.TopMenu;
  const MENU_ID = "mobileMenu";
  const MENU: Menu = {
    category: MENU_CATEGORY,
    menuId: MENU_ID,
    blur: null,
  };

  const reportContext = useContext(ReportContext);
  const menuContext = useContext(MenuContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeMenu = () => {
    menuContext.removeMenu(MENU);
  };

  const toggleMenu = () => {
    if (isOpen) {
      menuContext.removeMenu(MENU);
    } else {
      menuContext.setMenu(MENU);
    }
  };

  useEffect(() => {
    setIsOpen(menuContext.isMenuOpen(MENU_CATEGORY, MENU_ID));
  }, [menuContext.menuUpdated]);

  const tooltipId = crypto.randomUUID();

  return (
    <div className={styles.topNav}>
      <div className={styles.row}>
        <LogoContainer />

        <div className={styles.end}>
          <FeedbackDialog />
          <ThemeSwitchView />
          {reportContext.reportLink && <FavouritesMarker reportId={reportContext.reportLink} />}
          <div className={styles.divider} />
          <Icon
            className={styles.item}
            data-tooltip-id={tooltipId}
            data-tooltip-content={"Menu"}
            iconName="menu"
            iconType={IconType.MaterialUI}
            onClick={() => toggleMenu()}
          />
        </div>
      </div>

      <div className={styles.row + " " + styles.paddingEnd}>
        {reportContext.reportLink && <FilterView />}
        {reportContext.reportLink && <DateFilter />}
      </div>

      <MobileMenuView isOpen={isOpen} closeMenu={closeMenu} />

      <Tooltip
        id={tooltipId}
        place={"bottom"}
        style={TooltipStyle}
        delayShow={TooltipDelayShow}
        delayHide={TooltipDelayHide}
      />
    </div>
  );
};