import { Report } from "report";
import { FiltersOperations, IBasicFilter, IColumnTarget, IFilter } from "powerbi-models";
import { FilterItemPacket } from "../../../models/report data/filter/FilterItemPacket";
import { models } from "powerbi-client";
import { FilterDataType } from "../../../models/report data/filter/FilterDataType";
import { FilterDataPacket } from "../../../models/report data/filter/FilterDataPacket";
import { FilterDataConditionType } from "../../../models/report data/filter/FilterDataConditionType";
import { FilterDataOperator } from "../../../models/report data/filter/FilterDataOperator";
import { FilterService } from "../filter/FilterService";
import { FilterDataConditionDataType } from "../../../models/report data/filter/FilterDataConditionDataType";
import { FilterDataConditionPacket } from "../../../models/report data/filter/FilterDataConditionPacket";

export class ReportService {
  private readonly report: Report | null;

  private hasTask: boolean = false;
  private taskQueue: Function[] = [];

  constructor(report: Report | null) {
    this.report = report;
  }

  public setFilter(filterItem: FilterItemPacket) {
    const filterDatas = FilterService.getTableSortedFilterData(filterItem);

    filterDatas.forEach((filterData) => {
      if (filterData.type === FilterDataType.basic) {
        this.setBasicFilter(filterData);
      } else if (filterData.type === FilterDataType.advanced) {
        this.setAdvancedFilter(filterData);
      }
    });
  }

  public removeFilters(filterItems: FilterItemPacket[]) {
    const removeFilters = (filters: IFilter[]) => {
      if (this.report === null) {
        this.handleError();
        return;
      }

      const filterData: FilterDataPacket[] = filterItems.reduce(
        (acc: FilterDataPacket[], filterItem: FilterItemPacket) => {
          if (filterItem.filterData === undefined) {
            return acc;
          }

          return acc.concat(filterItem.filterData);
        },
        [],
      );

      const newFilters = filters.filter((filter) => {
        const castFilter = filter as IFilter;

        if (!(castFilter.target as IColumnTarget).column) {
          return false;
        }

        const columnTarget = castFilter.target as IColumnTarget;

        return !filterData.some(
          (x) => x.table === columnTarget.table && x.column === columnTarget.column,
        );
      });

      if (newFilters.length === filters.length) {
        this.handleSuccess();
        return;
      }

      this.report
        .updateFilters(FiltersOperations.ReplaceAll, newFilters)
        .then(this.handleSuccess, this.handleError);
    };

    const action = () => {
      if (this.report === null) {
        this.handleError();
        return;
      }

      this.report.getFilters().then(removeFilters, this.handleError);
    };

    this.addTask(action);
  }

  public setYearFilter(year: number) {
    if (this.report === null) {
      return;
    }

    const filter: IBasicFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      filterType: 1,
      target: {
        table: "Calendar",
        column: "Year",
      },
      operator: "In",
      values: [year],
      requireSingleSelection: true,
    };

    const action = () => {
      if (this.report === null) {
        this.handleError();
        return;
      }

      this.report
        .updateFilters(FiltersOperations.Replace, [filter])
        .then(this.handleSuccess, this.handleError);
    };

    this.addTask(action);
  }

  public removeYearFilter() {
    const yearFilter: FilterItemPacket = {
      name: "Year",
      order: 0,
      originCode: "",
      originId: "",
      visible: true,
      filterData: [
        {
          table: "Calendar",
          column: "Year",
          type: FilterDataType.basic,
          operator: FilterDataOperator.And,
          conditions: [
            {
              type: FilterDataConditionType.in,
              dataType: FilterDataConditionDataType.text,
              value: "",
            },
          ],
        },
      ],
    };

    this.removeFilters([yearFilter]);
  }

  public setPage(name: string) {
    if (this.report === null) {
      this.handleError();
      return;
    }

    const processPages = (pages: models.IPage[]) => {
      const correctPage = pages.find((x) => x.name === name || x.displayName === name);

      if (correctPage === undefined) {
        this.handleError();
        return;
      }

      this.report?.setPage(correctPage.name).then(this.handleSuccess, this.handleError);
    };

    const action = () => {
      if (this.report === null) {
        this.handleError();
        return;
      }

      this.report.getPages().then(processPages, this.handleError);
    };

    this.addTask(action);
  }

  private setBasicFilter(filterData: FilterDataPacket) {
    if (this.report === null) {
      this.handleError();
      return;
    }

    if (filterData.conditions.length < 1) {
      this.handleError();
      return;
    }

    const values = filterData.conditions.map((x) => {
      return this.convertValue(x);
    });

    const filter: IBasicFilter = {
      $schema: "http://powerbi.com/product/schema#basic",
      filterType: models.FilterType.Basic,
      target: {
        table: filterData.table,
        column: filterData.column,
      },
      operator: this.getBasicFilterOperator(filterData.conditions[0].type),
      values: values,
      requireSingleSelection: true,
    };

    const action = () => {
      if (this.report === null) {
        this.handleError();
        return;
      }

      this.report
        .updateFilters(FiltersOperations.Replace, [filter])
        .then(this.handleSuccess, this.handleError);
    };

    this.addTask(action);
  }

  private setAdvancedFilter(filterData: FilterDataPacket) {
    if (this.report === null) {
      this.handleError();
      return;
    }

    const conditions: models.IAdvancedFilterCondition[] = [];

    filterData.conditions.forEach((condition) => {
      const value = this.convertValue(condition);

      conditions.push({
        value: value,
        operator: this.getAdvancedFilterConditionOperator(condition.type),
      });
    });

    const filter: models.IAdvancedFilter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      filterType: models.FilterType.Advanced,
      target: {
        table: filterData.table,
        column: filterData.column,
      },
      logicalOperator: this.getAdvancedFilterOperator(filterData.operator),
      conditions: conditions,
    };

    const action = () => {
      if (this.report === null) {
        this.handleError();
        return;
      }

      this.report
        .updateFilters(FiltersOperations.Replace, [filter])
        .then(this.handleSuccess, this.handleError);
    };

    this.addTask(action);
  }

  // Operator conversion
  private getBasicFilterOperator(type: FilterDataConditionType): models.BasicFilterOperators {
    switch (type) {
      case FilterDataConditionType.in:
        return "In";
      case FilterDataConditionType.notIn:
        return "NotIn";
      case FilterDataConditionType.all:
        return "All";

      default:
        return "In";
    }
  }

  private getAdvancedFilterOperator(
    operator: FilterDataOperator,
  ): models.AdvancedFilterLogicalOperators {
    switch (operator) {
      case FilterDataOperator.And:
        return "And";
      case FilterDataOperator.Or:
        return "Or";

      default:
        return "And";
    }
  }

  private getAdvancedFilterConditionOperator(
    operator: FilterDataConditionType,
  ): models.AdvancedFilterConditionOperators {
    switch (operator) {
      case FilterDataConditionType.none:
        return "None";
      case FilterDataConditionType.lessThan:
        return "LessThan";
      case FilterDataConditionType.lessThanOrEqual:
        return "LessThanOrEqual";
      case FilterDataConditionType.greaterThan:
        return "GreaterThan";
      case FilterDataConditionType.greaterThanOrEqual:
        return "GreaterThanOrEqual";
      case FilterDataConditionType.contains:
        return "Contains";
      case FilterDataConditionType.doesNotContain:
        return "DoesNotContain";
      case FilterDataConditionType.startsWith:
        return "StartsWith";
      case FilterDataConditionType.doesNotStartWith:
        return "DoesNotStartWith";
      case FilterDataConditionType.is:
        return "Is";
      case FilterDataConditionType.isNot:
        return "IsNot";
      case FilterDataConditionType.isBlank:
        return "IsBlank";
      case FilterDataConditionType.isNotBlank:
        return "IsNotBlank";

      default:
        return "None";
    }
  }

  // Queue Handling
  private handleSuccess = () => {
    this.hasTask = false;
    this.checkTaskQueue();
  };

  private handleError = () => {
    this.hasTask = false;
    this.checkTaskQueue();
  };

  private addTask(func: Function) {
    this.taskQueue.push(func);

    this.checkTaskQueue();
  }

  private checkTaskQueue() {
    if (this.hasTask) {
      return;
    }

    if (this.taskQueue.length === 0) {
      return;
    }

    const task = this.taskQueue.shift();

    if (task === undefined) {
      return;
    }

    this.hasTask = true;
    task();
  }

  private convertValue(condition: FilterDataConditionPacket) {
    if (condition.dataType === FilterDataConditionDataType.text) {
      return `${condition.value}`;
    } else if (condition.dataType === FilterDataConditionDataType.number) {
      return !isNaN(parseInt(condition.value)) ? parseInt(condition.value) : condition.value;
    } else {
      return condition.value;
    }
  }
}