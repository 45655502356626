import { CreateFeedback } from '../../../models/master data/feedback/createFeedback';
import { Feedback } from '../../../models/master data/feedback/feedback';
import { BaseApiDataProvider } from '../../../services/http communication/BaseApiDataProvider';
import { BaseApiRequestHandler } from '../../../services/http communication/BaseApiRequestHandler';
import { IFeedbackProvider } from './IFeedbackProvider';

export class FeedbackProvider extends BaseApiDataProvider implements IFeedbackProvider {

  public async getFeedbacks(): Promise<Feedback[]> {
    return await this.get(`/feedback`);
  }

  public async createFeedback(feedback: CreateFeedback): Promise<void> {
    return await this.post(`/feedback`, feedback);
  }

}

export class FeedbackProviderFactory {
  private static feedbackProvider: IFeedbackProvider | null = null;

  public static getFeedbackProvider(): IFeedbackProvider {
    if (this.feedbackProvider === null) {
      this.feedbackProvider = new FeedbackProvider(new BaseApiRequestHandler());
    }

    return this.feedbackProvider;
  }
}