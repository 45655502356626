import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useSearchResults } from "../../../hooks/application/search/useSearchResults";
import { IconType } from "../../../models/application/icon/IconType";
import { Icon } from "../../../parts/icons/Icon";
import styles from "./SearchView.module.scss";

export const SearchView: React.FunctionComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { searchResults } = useSearchResults(searchQuery);

  return (
    <div className={styles.container}>
      <div className={styles.searchField}>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Icon className={styles.searchIcon} iconName="magnify" iconType={IconType.MaterialUI} />
      </div>

      {searchResults.length > 0 ? (
        <div className={styles.searchQuery}>
          Search results for: <strong>{searchQuery}</strong>
        </div>
      ) : null}

      {searchResults.map((item, index) => (
        <div key={index} className={styles.searchResultItem}>
          <div className={styles.headLine}>{item.name}</div>

          <div className={styles.link}>
            <Icon iconName={item.icon} iconType={item.iconType} />
            <Link to={item.link}>Open Page "{item.name}"</Link>
          </div>
        </div>
      ))}
    </div>
  );
};