import React from "react";
import { useESAProjects } from "../../../hooks/master data/esa project/useESAProjects";
import { ESAProjectPacket } from "../../../models/master data/esa project/ESAProjectPacket";
import { ESAProjectProviderFactory } from "../../../providers/master data/esa project/ESAProjectProvider";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FullscreenPropagateLoader } from "../../../parts/loaders/FullscreenPropagateLoader";
import { FunctionNotAuthorized } from "../../identity/authorization/FunctionNotAuthorized";
import { ColumnDef } from "@tanstack/react-table";
import { FieldType } from "../../../models/master data/generics/FieldType";
import { EditorSectionPacket } from "../../../models/master data/generics/EditorSection";
import { GenericMasterDataEditorView } from "../generic master data/views/GenericMasterDataEditorView";
import { v4 as uuid } from "uuid";
import styles from "../generic master data/views/GenericMasterDataEditor.module.scss";
import { HttpConstants } from "../../../models/http communication/HttpConstants";
import { useRights } from "../../../hooks/identity/authorization/useRights";
import { MsalService } from "../../../services/identity/MsalService";
import { RightConstants } from "../../../models/identity/RightConstants";

export const ESAProjectsView: React.FunctionComponent = () => {
  const msalInstance = MsalService.getAuthenticationInstance();
  const account = msalInstance.getActiveAccount();

  const { rights, isLoadingRights } = useRights(account?.username ?? "");

  const { esaProjects, esaProjectsIsLoading, esaProjectsState } = useESAProjects();

  const esaProjectsProvider = ESAProjectProviderFactory.getESAProjectProvider();

  const columnDefinitions: ColumnDef<ESAProjectPacket>[] = [
    {
      accessorKey: "name",
      cell: (info) => info.getValue(),
      header: "Name",
      sortingFn: "text",
    },
    {
      accessorKey: "displayName",
      cell: (info) => info.getValue(),
      header: "Display Name",
    },
    {
      accessorKey: "responsibleOrganisationalUnitAddressCodeShort",
      cell: (info) => info.getValue(),
      header: "Resp. OrgUnit",
    },
    {
      accessorKey: "active",
      cell: (info) => (info.getValue() === true ? "Yes" : "No"),
      header: "Active",
      enableColumnFilter: false,
    },
    {
      accessorKey: "visible",
      cell: (info) => (info.getValue() === true ? "Yes" : "No"),
      header: "Visible",
      enableColumnFilter: false,
    },
  ];

  const sectionDefinitions: EditorSectionPacket<ESAProjectPacket>[] = [
    {
      id: 1,
      heading: null,
      properties: [
        {
          propertyName: "name",
          displayName: "Name",
          type: FieldType.Text,
        },
        {
          propertyName: "displayName",
          displayName: "Display Name",
          type: FieldType.Text,
        },
      ],
    },
    {
      id: 2,
      heading: "Management",
      properties: [
        {
          propertyName: "responsibleOrganisationalUnitAddressCodeShort",
          displayName: "Responsible Org-Unit",
          type: FieldType.Text,
        },
        {
          propertyName: "projectManagerEMID",
          displayName: "Project Manager EM-ID",
          type: FieldType.Text,
        },
      ],
    },
    {
      id: 3,
      heading: "Dates",
      properties: [
        {
          propertyName: "startDate",
          displayName: "Start Date",
          type: FieldType.Date,
        },
        {
          propertyName: "endDate",
          displayName: "End Date",
          type: FieldType.Date,
        },
        {
          propertyName: "proposalSelectionDate",
          displayName: "Proposal Selection Date",
          type: FieldType.Date,
        },
        {
          propertyName: "missionSelectionDate",
          displayName: "Mission Selection Date",
          type: FieldType.Date,
        },
        {
          propertyName: "missionAdoptionDate",
          displayName: "Mission Adoption Date",
          type: FieldType.Date,
        },
        {
          propertyName: "preliminaryDesignReviewDate",
          displayName: "Preliminary Design Review Date",
          type: FieldType.Date,
        },
        {
          propertyName: "criticalDesignReviewDate",
          displayName: "Critical Design Review Date",
          type: FieldType.Date,
        },
        {
          propertyName: "launchDate",
          displayName: "Launch Date",
          type: FieldType.Date,
        },
        {
          propertyName: "inOrbitComissioningDate",
          displayName: "In Orbit Commisioning Date",
          type: FieldType.Date,
        },
        {
          propertyName: "endOfLifeDate",
          displayName: "End of Life Date",
          type: FieldType.Date,
        },
        {
          propertyName: "missionClosureDate",
          displayName: "Mission Closure Date",
          type: FieldType.Date,
        },
      ],
    },
    {
      id: 4,
      heading: "Additional Information",
      properties: [
        {
          propertyName: "missionId",
          displayName: "Mission ID",
          type: FieldType.Text,
        },
        {
          propertyName: "ipcdbId",
          displayName: "IPCDB ID",
          type: FieldType.Number,
        },
        {
          propertyName: "parentId",
          displayName: "Parent ID",
          type: FieldType.Dropdown,
          additionalInformation: esaProjects,
        },
      ],
    },
    {
      id: 5,
      heading: "State",
      properties: [
        {
          propertyName: "active",
          displayName: "Active",
          type: FieldType.Checkbox,
        },
        {
          propertyName: "visible",
          displayName: "Visible",
          type: FieldType.Checkbox,
        },
      ],
    },
  ];

  const createSpaceProject = () => {
    const newSpaceProject: ESAProjectPacket = {
      id: uuid(),
      name: "",
      displayName: "",
      active: false,
      visible: false,
      responsibleOrganisationalUnitAddressCodeShort: "",
      startDate: new Date(1970, 0, 1),
      endDate: new Date(1970, 0, 1),
      projectManagerEMID: null,
      proposalSelectionDate: new Date(1970, 0, 1),
      missionSelectionDate: new Date(1970, 0, 1),
      missionAdoptionDate: new Date(1970, 0, 1),
      preliminaryDesignReviewDate: new Date(1970, 0, 1),
      criticalDesignReviewDate: new Date(1970, 0, 1),
      launchDate: new Date(1970, 0, 1),
      inOrbitComissioningDate: new Date(1970, 0, 1),
      endOfLifeDate: new Date(1970, 0, 1),
      missionClosureDate: new Date(1970, 0, 1),
      missionId: null,
      ipcdbId: null,
      parentId: null,
      wbs_relations: [],
      pomi_relations: [],
    };

    return newSpaceProject;
  };

  const createRecord = rights.find((x) => x === RightConstants.EditMasterData)
    ? () => createSpaceProject()
    : null;
  const saveRecord = rights.find((x) => x === RightConstants.EditMasterData)
    ? (spaceProject: ESAProjectPacket) => esaProjectsProvider.createESAProject(spaceProject)
    : null;
  const updateRecord = rights.find((x) => x === RightConstants.EditMasterData)
    ? (spaceProject: ESAProjectPacket) => esaProjectsProvider.updateESAProject(spaceProject)
    : null;
  const deleteRecord = rights.find((x) => x === RightConstants.DeleteMasterData)
    ? (id: string) => esaProjectsProvider.deleteESAProject(id)
    : null;

  return (
    <div className={styles.container}>
      {esaProjectsIsLoading ? (
        <FullscreenPropagateLoader />
      ) : esaProjectsState === HttpConstants.Unauthorized ? (
        <FunctionNotAuthorized />
      ) : (
        <GenericMasterDataEditorView
          name={"ESA Projects"}
          columnDefinitions={columnDefinitions}
          sectionDefinitions={sectionDefinitions}
          dataSource={structuredClone(esaProjects)}
          getRecord={(id: string) => esaProjectsProvider.getESAProject(id)}
          createRecord={createRecord}
          saveRecord={saveRecord}
          updateRecord={updateRecord}
          deleteRecord={deleteRecord}
          getRecordDisplayName={(record) => record.displayName}
        />
      )}
    </div>
  );
};