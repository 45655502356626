import { ISettingsProvider } from "./ISettingsProvider";
import { BaseApiDataProvider } from "../../services/http communication/BaseApiDataProvider";
import { SettingPacket } from "../../models/configuration/SettingPacket";
import { BaseApiRequestHandler } from "../../services/http communication/BaseApiRequestHandler";

export class SettingsProvider extends BaseApiDataProvider implements ISettingsProvider {
  public async getAllSettings(): Promise<SettingPacket[]> {
    return await this.get("settings");
  }
}

export class SettingsProviderFactory {
  private static settingsProvider: ISettingsProvider | null = null;

  public static getSettingsProvider(): ISettingsProvider {
    if (this.settingsProvider === null) {
      this.settingsProvider = new SettingsProvider(new BaseApiRequestHandler());
    }

    return this.settingsProvider;
  }
}