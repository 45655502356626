import React, { useEffect, useRef, useState } from "react";
import { FilterDropdownMenu } from "./FilterDropdownMenu";
import styles from "./FilterDropdown.module.scss";
import { FilterService } from "../../../../services/report data/filter/FilterService";
import { FilterItemPacket } from "../../../../models/report data/filter/FilterItemPacket";

interface FilterDropdownProps {
  filter: FilterItemPacket;
  children: FilterItemPacket[];

  parameterName: string;

  closeMenu: Function;
}

export interface MenuChangeProps {
  from: string;
  to: string;
}

export const FilterDropdown: React.FunctionComponent<FilterDropdownProps> = (props) => {
  const [menuHeight, setMenuHeight] = useState<number | null>(null);
  const [maxMenuHeight, setMaxMenuHeight] = useState<number | null>(null);

  const [menuWidth, setMenuWidth] = useState<number>(0);
  const [leftOffset, setLeftOffset] = useState<number>(0);

  const [activeMenu, setActiveMenu] = useState(props.filter.originId);
  const [menuChange, setMenuChange] = useState<MenuChangeProps | null>(null);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const setMenuHeightCapped = (menuHeight: number) => {
    const realMenuHeight = menuHeight > (maxMenuHeight || 0) ? maxMenuHeight : menuHeight;

    setMenuHeight(realMenuHeight);
  };

  const calculateWidths = (filters: FilterItemPacket[]) => {
    const windowWidth = window.innerWidth;
    const startingPosition = dropdownRef.current?.parentElement?.offsetLeft ?? 0;
    const internalWidth = FilterService.getWidestFilterWidth(filters) + 75; // 75 to accomodate paddings, scrollbar, icons etc
    const realWidth = internalWidth + 12; // plus 12 for padding border etc

    const remainder = realWidth + startingPosition - windowWidth;

    const width = realWidth > windowWidth ? windowWidth - 12 : internalWidth;
    const leftOffset = remainder > 0 ? (realWidth > windowWidth ? 0 : -remainder) : 0;

    setMenuWidth(width);
    setLeftOffset(leftOffset);
  };

  const calculateHeights = () => {
    if (
      dropdownRef.current &&
      dropdownRef.current instanceof HTMLElement &&
      dropdownRef.current.firstChild instanceof HTMLElement
    ) {
      const childHeight = 41; // TODO: Search for dynamic solution
      const maxChildHeight = childHeight * 12 + 8;
      const maxWindowHeight = window.innerHeight - 100;
      const maxHeight = Math.min(maxChildHeight, maxWindowHeight);

      setMenuHeightCapped(dropdownRef.current.firstChild.offsetHeight || 0);
      setMaxMenuHeight(maxHeight);
    }
  };

  useEffect(() => {
    calculateWidths([...props.children, props.filter]);
    calculateHeights();
  }, [dropdownRef.current]);

  useEffect(() => {
    if (dropdownRef.current && dropdownRef.current instanceof HTMLElement) {
      dropdownRef.current.scrollTop = 0;
    }
  }, [activeMenu]);

  return (
    <div
      className={styles.dropdown}
      style={{
        height: menuHeight ?? 0,
        width: menuWidth ?? 0,
        maxHeight: maxMenuHeight ?? 0,
        left: leftOffset ?? 0,
      }}
      ref={dropdownRef}
    >
      <FilterDropdownMenu
        parent={props.filter}
        children={props.children}
        primary={true}
        parameterName={props.parameterName}
        menuName={props.filter.originId}
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
        setMenuHeight={setMenuHeightCapped}
        calculateMenuWidth={calculateWidths}
        menuChange={menuChange}
        setMenuChange={setMenuChange}
        closeMenu={props.closeMenu}
      />
    </div>
  );
};