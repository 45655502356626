import { FilterItemPacket } from "../../../models/report data/filter/FilterItemPacket";
import { createContext } from "react";
import { FilterConfig } from "../../../models/report data/filter/FilterConfig";

export interface FilterContextType {
  filters: FilterItemPacket[];
  isLoadingFilters: boolean;

  getFilterConfig: (originId: string) => FilterConfig | undefined;
  setFilterConfig: (filterConfig: FilterConfig) => void;
  deleteFilterConfig: (originId: string) => void;

  filterConfigChanged: boolean;
}

export const FilterContext = createContext<FilterContextType>({
  filters: [],
  isLoadingFilters: false,

  getFilterConfig: () => undefined,
  setFilterConfig: () => {},
  deleteFilterConfig: () => {},

  filterConfigChanged: false,
});