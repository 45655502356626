import { AppConfigurationModel } from "./AppConfigurationModel";
import { EnvironmentVariablesModel } from "./EnvironmentVariablesModel";

export class AppConfigManager {
  private static instance: AppConfigManager;

  private environmentVariables: EnvironmentVariablesModel;
  private appConfig: AppConfigurationModel;

  private constructor() {
    this.environmentVariables = this.readEnvironmentVariables();
    this.appConfig = this.createAppConfig();
  }

  public static getEnvironmentVariables(): EnvironmentVariablesModel {
    if (!this.instance) {
      this.instance = new AppConfigManager();
    }

    return AppConfigManager.instance.environmentVariables;
  }

  public static getAppConfiguration(): AppConfigurationModel {
    if (!this.instance) {
      this.instance = new AppConfigManager();
    }

    return AppConfigManager.instance.appConfig;
  }

  private readEnvironmentVariables(): EnvironmentVariablesModel {
    let title: string | undefined = process.env.REACT_APP_TITLE;
    const apiUrl: string | undefined = process.env.REACT_APP_API_URL;
    const apiScope: string | undefined = process.env.REACT_APP_API_SCOPE;
    const clientId: string | undefined = process.env.REACT_APP_CLIENT_ID;
    const tenantId: string | undefined = process.env.REACT_APP_TENANT_ID;

    if (!title) {
      title = "ESA Dashboard";
    }

    if (!apiUrl) {
      throw new Error("Api url in environment variable not set");
    }

    if (!apiScope) {
      throw new Error("API Scope in the environment variable not set");
    }

    if (!clientId) {
      throw new Error("ClientId in the environment variable not set");
    }

    if (!tenantId) {
      throw new Error("TenantId in the environment variable not set");
    }

    return {
      title,
      apiUrl,
      apiScope,
      clientId,
      tenantId,
    };
  }

  private createAppConfig(): AppConfigurationModel {
    let appConfig: AppConfigurationModel;

    if (process.env.NODE_ENV === "production") {
      appConfig = {
        defaultLogLevel: 3,
        showFilters: false,
        showPages: false,
      };
    } else {
      appConfig = {
        defaultLogLevel: 3,
        showFilters: true,
        showPages: true,
      };
    }

    return appConfig;
  }
}