import { BaseApiDataProvider } from '../../../services/http communication/BaseApiDataProvider';
import { BaseApiRequestHandler } from '../../../services/http communication/BaseApiRequestHandler';
import { IFavouritesProvider } from './IFavouritesProvider';

export class FavouritesProvider extends BaseApiDataProvider implements IFavouritesProvider {
  public async getFavourites(): Promise<any[]> {
    return await this.get(`/favourites`);
  }

  public async checkFavourite(reportId: string): Promise<boolean> {
    return await this.get(`/favourites/check?reportId=${reportId}`);
  }

  public async addFavourite(reportId: string, filter: string): Promise<void> {
    return await this.post(`/favourites/${reportId}`, filter);
  }

  public async deleteFavourite(reportId: string): Promise<void> {
    return await this.delete(`/favourites/${reportId}`);
  }
}

export class FavouritesProviderFactory {
  private static favouritesProvider: IFavouritesProvider | null = null;

  public static getFavouritesProvider(): IFavouritesProvider {
    if (this.favouritesProvider === null) {
      this.favouritesProvider = new FavouritesProvider(new BaseApiRequestHandler());
    }

    return this.favouritesProvider;
  }
}