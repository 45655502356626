import { createContext } from "react";
import { MenuCategory } from "../../../models/application/menu/MenuCategory";
import { MenuBlurArea } from "../../../models/application/menu/MenuBlurArea";

export interface Menu {
  category: MenuCategory;
  blur?: MenuBlurArea | undefined | null;
  menuId: string | null;
}

type isNoMenuOpenType = (menuCategory: MenuCategory) => boolean;
type isMenuOpenType = (menuCategory: MenuCategory, menuId: string | null) => boolean;
type isBlurredType = (blurArea: MenuBlurArea) => boolean;
type setMenuType = (menu: Menu) => void;
type removeMenuType = (menu: Menu) => void;

export interface MenuContextType {
  isNoMenuOpen: isNoMenuOpenType;
  isMenuOpen: isMenuOpenType;
  isBlurred: isBlurredType;
  setMenu: setMenuType;
  removeMenu: removeMenuType;
  menuUpdated: boolean;
}

export const MenuContext = createContext<MenuContextType>({
  isNoMenuOpen: () => true,
  isMenuOpen: () => true,
  isBlurred: () => true,
  setMenu: () => {},
  removeMenu: () => {},
  menuUpdated: false,
});