import React, { useContext, useEffect, useState } from "react";
import styles from "./Filter.module.scss";
import { FilterDropdown } from "./FilterDropdown";
import { Link, useSearchParams } from "react-router-dom";
import { Icon } from "../../../../parts/icons/Icon";
import { FilterService } from "../../../../services/report data/filter/FilterService";
import { Menu, MenuContext } from "../../../../contexts/application/menu/MenuContext";
import { FilterItemPacket } from "../../../../models/report data/filter/FilterItemPacket";
import { ReportContext } from "../../../../contexts/report data/report/ReportContext";
import { SelectedFilter } from "../special-filter/SelectedFilter";
import { MenuCategory } from "../../../../models/application/menu/MenuCategory";
import { IconType } from "../../../../models/application/icon/IconType";
import { FilterConfig } from "../../../../models/report data/filter/FilterConfig";
import { FilterContext } from "../../../../contexts/report data/filter/FilterContext";

interface FilterProps {
  filter: FilterItemPacket;
}

export const Filter: React.FunctionComponent<FilterProps> = (props) => {
  const MENU_CATEGORY = MenuCategory.TopMenu;
  const MENU_ID = `${props.filter.originId}`;
  const MENU: Menu = {
    category: MENU_CATEGORY,
    menuId: MENU_ID,
    blur: null,
  };

  const parameterName = `filter${props.filter.originId}`;

  const menuContext = useContext(MenuContext);
  const reportContext = useContext(ReportContext);
  const filterContext = useContext(FilterContext);

  const [searchParams] = useSearchParams();

  const [filterConfig, setFilterConfig] = useState<FilterConfig | undefined>(undefined);

  const [filters, setFilters] = useState<FilterItemPacket[]>(props.filter?.children ?? []);
  const [sortedFilters, setSortedFilters] = useState<FilterItemPacket[]>(
    FilterService.sortFilters(filters),
  );
  const [reducedFilters, setReducedFilters] = useState<FilterItemPacket[]>(
    FilterService.reduceFilters(sortedFilters, filterConfig?.reduce),
  );
  const [flattedFilters, setFlattedFilters] = useState<FilterItemPacket[]>(
    FilterService.flattenFilters(reducedFilters),
  );

  const [selectedFilter, setSelectedFilter] = useState<FilterItemPacket | null>(null);
  const [removableFilters, setRemovableFilters] = useState<FilterItemPacket[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isReduced, setIsReduced] = useState<boolean>(false);

  function toggleMenu() {
    if (isOpen) {
      menuContext.removeMenu(MENU);
    } else {
      menuContext.setMenu(MENU);
    }
  }

  function closeMenu() {
    menuContext.removeMenu(MENU);
  }

  function getFilter() {
    const filterLink = searchParams.get(parameterName);

    if (!filterLink) {
      return null;
    }

    if (flattedFilters === undefined) {
      return null;
    }

    const filterItem = flattedFilters.find((x) => x.filterLink?.link === filterLink);

    if (!filterItem) {
      return null;
    }

    return filterItem;
  }

  const updateFilter = () => {
    const filter = getFilter();

    if (!filter) {
      removeFilter();
    } else {
      setFilter();
    }
  };

  const setFilter = () => {
    const filter = getFilter() ?? (isReduced ? reducedFilters[0] : null);

    if (!filter) {
      return;
    }

    reportContext.reportService.setFilter(filter);
  };

  const removeFilter = () => {
    if (isReduced) {
      setFilter();
    } else {
      reportContext.reportService.removeFilters(removableFilters);
    }
  };

  const displayFilter = () => {
    const filter = getFilter();
    setSelectedFilter(filter);
  };

  useEffect(() => {
    setIsOpen(menuContext.isMenuOpen(MENU_CATEGORY, props.filter.originId));
  }, [menuContext.menuUpdated]);

  useEffect(() => {
    const config = filterContext.getFilterConfig(props.filter.originId);

    setFilterConfig(config);
  }, [filterContext.filterConfigChanged]);

  // Filter loading events
  useEffect(() => {
    setFilters(props.filter.children ?? []);
  }, [props.filter]);

  useEffect(() => {
    setSortedFilters(FilterService.sortFilters(filters));
  }, [filters]);

  useEffect(() => {
    setReducedFilters(FilterService.reduceFilters(sortedFilters, filterConfig?.reduce));
  }, [sortedFilters, isReduced]);

  useEffect(() => {
    setFlattedFilters(FilterService.flattenFilters(reducedFilters));
  }, [reducedFilters]);

  useEffect(() => {
    setRemovableFilters(FilterService.getRemovableFilters(flattedFilters));
  }, [flattedFilters]);

  // Filter updating events
  useEffect(() => {
    updateFilter();
    displayFilter();
  }, [flattedFilters]);

  useEffect(() => {
    updateFilter();
    displayFilter();
  }, [searchParams]);

  useEffect(() => {
    updateFilter();
    displayFilter();
  }, [reportContext.reportLoaded]);

  useEffect(() => {
    setIsReduced(!!filterConfig && !!filterConfig.reduce);
  }, [filterConfig]);

  useEffect(() => {
    updateFilter();
    displayFilter();
  }, [isReduced]);

  useEffect(() => {
    displayFilter();
  }, []);

  const filter = filterConfig?.reduce ? reducedFilters[0] : props.filter;
  const children = filterConfig?.reduce ? reducedFilters[0].children ?? [] : reducedFilters;

  return (
    <>
      {selectedFilter ? (
        <SelectedFilter
          filter={selectedFilter}
          filterConfig={filterConfig}
          parameterName={parameterName}
        />
      ) : (
        <li className={styles.navItem}>
          {children.length > 0 ? (
            <div className={styles.textBox} onClick={toggleMenu}>
              <label className={styles.text}>{filter.name}</label>
              <Icon
                iconName={"chevron-down"}
                iconType={IconType.MaterialUI}
                className={styles.icon + " " + (isOpen ? styles.rotate : "")}
              />
            </div>
          ) : isReduced ? (
            <div className={styles.textBox + " " + styles.disabled}>
              <label className={styles.text + " " + styles.disabled}>{filter.name}</label>
            </div>
          ) : (
            <Link
              className={styles.textBox}
              to={`?${parameterName}=${filter.filterLink?.link}`}
              relative="path"
            >
              <label className={styles.text}>{filter.name}</label>
            </Link> // TODO: Maybe replace with same navigation style like in FilterDropdownItem
          )}

          {children.length > 0 && isOpen && (
            <FilterDropdown
              filter={filter}
              children={children}
              parameterName={parameterName}
              closeMenu={closeMenu}
            />
          )}
        </li>
      )}
    </>
  );
};