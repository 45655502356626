import React, { HTMLAttributes } from "react";
import {
  Anouncement,
  Building,
  Coins,
  Factory,
  Globe,
  Leaf,
  People,
  Rocket,
  Satellite,
  Server,
} from "../../shared/icons";

interface InternalIconProps extends HTMLAttributes<any> {
  iconName: string;
}

export const InternalIcon: React.FunctionComponent<InternalIconProps> = (props) => {
  let icon: React.JSX.Element;

  // Destructure props to avoid DOM errors
  const { iconName, ...restProps } = props;

  switch (iconName) {
    case "announcement":
      icon = <Anouncement {...restProps} />;
      break;

    case "building":
      icon = <Building {...restProps} />;
      break;

    case "coins":
      icon = <Coins {...restProps} />;
      break;

    case "factory":
      icon = <Factory {...restProps} />;
      break;

    case "globe":
      icon = <Globe {...restProps} />;
      break;

    case "leaf":
      icon = <Leaf {...restProps} />;
      break;

    case "people":
      icon = <People {...restProps} />;
      break;

    case "rocket":
      icon = <Rocket {...restProps} />;
      break;

    case "satellite":
      icon = <Satellite {...restProps} />;
      break;

    case "server":
      icon = <Server {...restProps} />;
      break;

    default:
      icon = <img alt="not found" {...restProps} />;
      break;
  }

  return <>{icon}</>;
};