import React, { PropsWithChildren, useContext } from "react";
import { ThemeService } from "../../../services/application data/theme/ThemeService";
import { ThemeContext } from "./ThemeContext";
import { useThemes } from "../../../hooks/application/theme/useThemes";
import { ReportContext } from "../../report data/report/ReportContext";

export const ThemeContextProvider: React.FunctionComponent<PropsWithChildren> = (props) => {
  const reportContext = useContext(ReportContext);
  const { themes } = useThemes();

  const themeService = new ThemeService(themes, reportContext);

  return <ThemeContext.Provider value={{ themeService }}>{props.children}</ThemeContext.Provider>;
};