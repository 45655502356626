import { IESAProjectWBSProvider } from "./IESAProjectWBSProvider";
import { ESAProjectWBSPacket } from "../../../models/master data/esa project/ESAProjectWBSPacket";
import { BaseApiDataProvider } from "../../../services/http communication/BaseApiDataProvider";
import { BaseApiRequestHandler } from "../../../services/http communication/BaseApiRequestHandler";

export class ESAProjectWBSProvider extends BaseApiDataProvider implements IESAProjectWBSProvider {
  public async getWBS(id: string): Promise<ESAProjectWBSPacket> {
    return await this.get(`/esaprojectwbs/${id}`);
  }

  public async createWBS(wbs: ESAProjectWBSPacket): Promise<void> {
    return await this.post(`/esaprojectwbs/${wbs.id}`, wbs);
  }

  public async updateWBS(wbs: ESAProjectWBSPacket): Promise<void> {
    return await this.put(`/esaprojectwbs/${wbs.id}`, wbs);
  }

  public async deleteWBS(id: string): Promise<void> {
    return await this.delete(`/esaprojectwbs/${id}`);
  }
}

export class ESAProjectWBSProviderFactory {
  private static esaProjectWBSProvider: IESAProjectWBSProvider | null = null;

  public static getESAProjectWBSProvider(): IESAProjectWBSProvider {
    if (this.esaProjectWBSProvider === null) {
      this.esaProjectWBSProvider = new ESAProjectWBSProvider(new BaseApiRequestHandler());
    }

    return this.esaProjectWBSProvider;
  }
}